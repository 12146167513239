import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../../utils/constants";
import DeleteModal from "../../components/custom-modals/DeleteModal";
import FormModal from "../../components/custom-modals/FormModal";
import MMAddButton from "../../components/MMButtons/MMAddButton";
import MMTable from "../../components/table/MMTable";
import Loader from "../Loader/Loader";
import PageTitle from "../../layouts/PageTitle";
import { Services } from "../../../services/service";
import { notify } from "../../../utils/funcs";
import moment from "moment";
import AlertModal from "../../components/custom-modals/AlertModal";
import BulkLoader from "../Loader/BulkLoader";

const getFormModalTitle = {
  add: "Add Info Item",
  edit: "Edit Info Item",
  delete: "Delete Info Item",
  addInBulk: "Add In Bulk",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addCourtOrderInfoItem,
  edit: CONSTANTS.FORMS.editCourtOrderInfoItem,
  addInBulk: CONSTANTS.FORMS.addCourtOrderInfoItemInBulk,
};

const CourtOrderInfoPage = () => {
  const params = useParams();
  const location = useLocation();

  const getCourtOrderInfoItems = useHttp();
  const addCourtOrderInfoItem = useHttp();
  const editCourtOrderInfoItem = useHttp();
  const deleteCourtOrderInfoItem = useHttp();

  const isLoading = () => {
    return (
      getCourtOrderInfoItems.isLoading ||
      addCourtOrderInfoItem.isLoading ||
      editCourtOrderInfoItem.isLoading ||
      deleteCourtOrderInfoItem.isLoading
    );
  };

  const [courtOrderInfoItems, setCourtOrderInfoItems] = useState([]);
  const [courtOrderInfoItemsData, setCourtOrderInfoItemsData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curCourtOrderInfoItem, setCurCourtOrderInfoItem] = useState(null);
  const [failedFiles, setFailedFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [bulkloader, setBulkLoader] = useState(null);
  const [fileName, setFileName] = useState("");

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerClickOnEditIcon = (courtOrderInfoItem) => {
    setCurCourtOrderInfoItem(courtOrderInfoItem);
    setCurModal("edit");
  };

  const courtOrderInfoItemsDataHandler = (courtOrderInfoItems) => {
    setCourtOrderInfoItemsData(
      courtOrderInfoItems.map((courtOrderInfoItem, index) => {
        return {
          // userData: userData,
          no: `${index + 1}`,
          date: courtOrderInfoItem?.date,
          filterpar: moment
            .utc(courtOrderInfoItem?.date)
            .local()
            .format("YYYY"),

          file: (
            <div className="d-flex justify-content-center">
              <Link
                // to={`/court-orders-and-judgments-info/${courtOrderInfoItem?.id}`}
                to={`/pdf-viewer?file=${
                  courtOrderInfoItem?.file
                }&fileName=${moment
                  .utc(courtOrderInfoItem?.date)
                  .local()
                  .format("DD-MM-YYYY")}`}
                className="btn btn-warning shadow btn-xs sharp mr-2"
                title="View"
              >
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          ),
          // index: courtOrderInfoItem?.index,
          action: (
            <div className="d-flex justify-content-center">
              {/* <Link
                to={`/court-orders-and-judgments/${courtOrderInfoItem?.id}`}
                className="btn btn-warning shadow btn-xs sharp mr-2"
              >
                <i className="fa fa-eye"></i>
              </Link> */}
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnEditIcon(courtOrderInfoItem)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnDeleteIcon(courtOrderInfoItem)}
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
      })
    );
  };

  const handlerGetAllCourtOrderInfoItems = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCourtOrderInfoItems(responseData?.data?.rows);
      courtOrderInfoItemsDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadCourtOrderInfoItems = () => {
    const editCourtOrderInfoItemURL = {
      type: "GET",
      endPoint: `/admin/courtordersinfo?courtOrderId=${params?.courtOrderId}`,
    };
    getCourtOrderInfoItems.sendRequest(
      editCourtOrderInfoItemURL,
      handlerGetAllCourtOrderInfoItems,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (courtOrderInfoItem) => {
    setCurCourtOrderInfoItem(courtOrderInfoItem);
    setCurModal("delete");
  };

  const handlerDeleteCourtOrderInfoItemRes = useCallback((responseData) => {
    setCurCourtOrderInfoItem(null);
    setCurModal(null);
    loadCourtOrderInfoItems();
  }, []);

  const handlerDeleteCourtOrder = (courtOrderId) => {
    const deleteCourtOrderURL = {
      type: "DELETE",
      endPoint: `/admin/courtordersinfo/${courtOrderId}`,
    };

    // make api call
    deleteCourtOrderInfoItem.sendRequest(
      deleteCourtOrderURL,
      handlerDeleteCourtOrderInfoItemRes,
      {},
      "Court Order deleted successfully."
    );
  };

  const handlerAddCourtOrderInfoItemRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurCourtOrderInfoItem(null);
      setCurModal(null);
      loadCourtOrderInfoItems();
    }
  }, []);

  const handlerSubmitAddCourtOrderInfoItem = (payload) => {
    payload.append("courtOrderId", params?.courtOrderId);

    addCourtOrderInfoItem.sendRequest(
      CONSTANTS.URLS.addCourtOrderInfoItem,
      handlerAddCourtOrderInfoItemRes,
      payload,
      "Court order info item added successfully."
    );
  };

  const handlerEditCourtOrderInfoItemRes = useCallback((responseData) => {
    setCurModal(null);
    setCurCourtOrderInfoItem(null);
    loadCourtOrderInfoItems();
  }, []);

  const handlerEditCourtOrderInfoItem = (courtOrderId, payload) => {
    const editCourtOrderInfoItemURL = {
      type: "PATCH",
      endPoint: `/admin/courtordersinfo/${courtOrderId}`,
    };

    payload.append("courtOrderId", params?.courtOrderId);

    // make api call
    editCourtOrderInfoItem.sendRequest(
      editCourtOrderInfoItemURL,
      handlerEditCourtOrderInfoItemRes,
      payload,
      "Court order info item updated successfully."
    );
  };

  const handlerAddCourtOrderInfoItemInBulkRes = useCallback((responseData) => {
    // if (responseData.status === "success") {
    setCurModal(null);
    loadCourtOrderInfoItems();
    // }
  }, []);

  const handlerSubmitAddCourtOrderInfoItemInBulk = (formData) => {
    // setLoader(true);
    // setBulkLoader(0);
    setBulkLoader(1);
    let isAllDataSubmited = true;
    const uploadFiles = async () => {
      const AllFiles = Array.from(formData.entries());

      setCurModal(null);
      for (let i = 0; i < AllFiles.length; i++) {
        {
          setBulkLoader(((i + 1) / AllFiles.length) * 100);
          setFileName(AllFiles[i][1].name);

          try {
            let date = "";

            console.log(date);
            if (
              moment(AllFiles[i][1].name.substring(0, 10), "DD.MM.YYYY", true)
                ._isValid
            ) {
              date = moment(
                AllFiles[i][1].name.substring(0, 10),
                "DD.MM.YYYY"
              ).format("MMM DD, YYYY");
            } else {
              date = AllFiles[i][1].name.replace(".pdf", "");
            }

            const payload = new FormData();
            payload.append("file", AllFiles[i][1]);
            payload.append("courtOrderId", params?.courtOrderId);
            payload.append("date", date /* .utc() */);

            const response = await Services.post(
              CONSTANTS.URLS.addCourtOrderInfoItem.endPoint,
              payload
            );

            if (!response.statusText === "OK") {
              throw new Error("Failed to upload file");
            }
          } catch (error) {
            console.log(error);
            setCurModal("alert");
            isAllDataSubmited = false;
            loadCourtOrderInfoItems();
            notify.error(error?.response?.data?.message);
            //  { file: AllFiles[i][1].name, error: error.message };
          }
        }
      }
      setCurModal(null);
      loadCourtOrderInfoItems();
      isAllDataSubmited && notify.success("All files uploaded successfully!");

      setBulkLoader(null);
      setLoader(false);
    };

    uploadFiles();
  };

  const listOfFailedFiles = (
    <ul>
      {failedFiles.map((file) => (
        <li>{file.file}</li>
      ))}
    </ul>
  );

  const handlerSubmitForm = (payload) => {
    if (curModal === "add") {
      // handlerSubmitAddCourtOrderInfoItem(payload);
      handlerSubmitAddCourtOrderInfoItemInBulk(payload);
    } else if (curModal === "edit") {
      handlerEditCourtOrderInfoItem(curCourtOrderInfoItem?.id, payload);
    } else {
      handlerSubmitAddCourtOrderInfoItemInBulk(payload);
    }
  };

  useEffect(() => {
    loadCourtOrderInfoItems();
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete court order info item with this date?"}
        desc={curCourtOrderInfoItem?.date}
        onDelete={() => handlerDeleteCourtOrder(curCourtOrderInfoItem?.id)}
      />
      <AlertModal
        isOpen={curModal === "alert"}
        onClose={handlerCloseModal}
        title={"Following files failed to upload:"}
        desc={listOfFailedFiles}
        onDelete={() => handlerDeleteCourtOrder(curCourtOrderInfoItem?.id)}
      />
      {bulkloader ? (
        <BulkLoader progress={bulkloader} fileNames={fileName} />
      ) : (
        <></>
      )}
      <FormModal
        isOpen={
          curModal === "add" || curModal === "edit" || curModal === "addInBulk"
        }
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                date: curCourtOrderInfoItem?.date,
                file: curCourtOrderInfoItem?.file,
              }
            : {}
        }
      />
      <PageTitle
        pageHeading="Chapters"
        withBackButton
        buttons={[
          <MMAddButton
            key="addInBulk"
            clickHandler={() => {
              setCurModal("addInBulk");
            }}
          >
            Bulk Upload
          </MMAddButton>,
        ]}
      />
      <MMTable
        title={location?.state?.title}
        tableData={{
          columns: getTableData("courtOrderInfoItems")["columns"],
          rows: courtOrderInfoItemsData,
        }}
        isShowFilter={true}
        filter="filterpar"
      />
    </Fragment>
  );
};

export default CourtOrderInfoPage;
