import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
//import Metismenu from "metismenujs";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from "react-bootstrap/Collapse";

/// Menu
import { Link, NavLink, useLocation } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { MenuList } from "./Menu";

///
import drump from "../../../images/card/drump.png";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = ({ toggleSidebar, onToggleNavbar }) => {
  let location = useLocation();

  const [state, setState] = useReducer(reducer, initialState);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  const handleCloseOnSelect = (status) => {
    if (window.innerWidth < 768) {
      onToggleNavbar();
    }
  };

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  /// Active menu
  let deshBoard = [
      "",
      "workout-statistic",
      "workout-plan",
      "distance-map",
      "diet-food-menu",
      "personal-record",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-registser",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];

  useEffect(() => {
    if (location.pathname === "/") {
      handleMenuActive("Dashboard");
      handleSubmenuActive("Analysis");
    }
  }, [location.pathname]);

  return (
    <Fragment>
      {toggleSidebar && (
        <div
          className={`mm-sidebar-overlay fade modal-backdrop  d-md-none show`}
          onClick={onToggleNavbar}
        ></div>
      )}
      <div className={`deznav ${toggleSidebar ? "sidebar-open" : ""}`}>
        <PerfectScrollbar className="deznav-scroll">
          <ul className="metismenu" id="menu">
            {MenuList.map((data, index) => {
              let menuClass = data.classsChange;
              if (menuClass === "menu-title") {
                return (
                  <li className={menuClass} key={index}>
                    {data.title}
                  </li>
                );
              } else {
                return (
                  <li
                    className={` ${
                      state.active === data.title ? "mm-active" : ""
                    }`}
                    key={index}
                  >
                    {data.content && data.content.length > 0 ? (
                      <Link
                        to="#"
                        className="has-arrow"
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                    ) : (
                      <NavLink
                        to={data.to}
                        onClick={() => {
                          handleMenuActive(data.title);
                          handleCloseOnSelect();
                        }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                      </NavLink>
                    )}
                    {data.content && data.content.length && (
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul
                          className={`${
                            menuClass === "mm-collapse" ? "mm-show" : ""
                          }`}
                        >
                          {data.content &&
                            data.content.map((data, index) => {
                              return (
                                <li
                                  key={index}
                                  className={`${
                                    state.activeSubmenu === data.title
                                      ? "mm-active"
                                      : ""
                                  }`}
                                >
                                  {data.content && data.content.length > 0 ? (
                                    <>
                                      <NavLink
                                        to={data.to}
                                        onClick={() => {
                                          handleSubmenuActive(data.title);
                                          handleCloseOnSelect();
                                        }}
                                        className={
                                          data.hasMenu ? "has-arrow" : ""
                                        }
                                      >
                                        <span>{data.title}</span>
                                      </NavLink>
                                      <Collapse
                                        in={
                                          state.activeSubmenu === data.title
                                            ? true
                                            : false
                                        }
                                      >
                                        <ul
                                          className={`${
                                            menuClass === "mm-collapse"
                                              ? "mm-show"
                                              : ""
                                          }`}
                                        >
                                          {data.content &&
                                            data.content.map((data, index) => {
                                              return (
                                                <>
                                                  <li key={index}>
                                                    <Link
                                                      className={`${
                                                        path === data.to
                                                          ? "mm-active"
                                                          : ""
                                                      }`}
                                                      to={data.to}
                                                      onClick={() => {
                                                        handleSubmenuActive(
                                                          data.title
                                                        );
                                                        handleCloseOnSelect();
                                                      }}
                                                    >
                                                      {data.title}
                                                    </Link>
                                                  </li>
                                                </>
                                              );
                                            })}
                                        </ul>
                                      </Collapse>
                                    </>
                                  ) : (
                                    <Link
                                      to={data.to}
                                      onClick={() => {
                                        handleSubmenuActive(data.title);
                                        handleCloseOnSelect();
                                      }}
                                    >
                                      {data.title}
                                    </Link>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </Collapse>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </PerfectScrollbar>
      </div>
    </Fragment>
  );
};

export default SideBar;
