import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../utils/constants";
import DeleteModal from "../components/custom-modals/DeleteModal";
import FormModal from "../components/custom-modals/FormModal";
import Magazines from "../components/magazines/Magazines";
import MMAddButton from "../components/MMButtons/MMAddButton";
import Loader from "./Loader/Loader";
import PageTitle from "../layouts/PageTitle";

const getFormModalTitle = {
  add: "Add Magazine",
  edit: "Edit Magazine",
  delete: "Delete Magazine",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addMagazine,
  edit: CONSTANTS.FORMS.editMagazine,
};

const MagazinePage = () => {
  const getAllMagazines = useHttp();
  const addMagazine = useHttp();
  const deleteMagazine = useHttp();
  const editMagazine = useHttp();

  const isLoading = () => {
    return (
      getAllMagazines.isLoading ||
      addMagazine.isLoading ||
      editMagazine.isLoading ||
      deleteMagazine.isLoading
    );
  };

  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'
  const [curMagazine, setCurMagazine] = useState(null);

  const [magazines, setMagazines] = useState([]);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerGetAllMagazines = useCallback((responseData) => {
    if (responseData.status === "success") {
      setMagazines(responseData?.data?.rows);
    }
  }, []);

  const handlerAddMagazineRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurMagazine(null);
      setCurModal(null);
      loadMagazines();
    }
  }, []);

  const handlerClickOnDeleteIcon = (magazine) => {
    setCurMagazine(magazine);
    setCurModal("delete");
  };

  const handlerClickOnEditIcon = (magazine) => {
    setCurMagazine(magazine);
    setCurModal("edit");
  };

  const handlerEditMagazineRes = useCallback((responseData) => {
    setCurModal(null);
    setCurMagazine(null);
    loadMagazines();
  }, []);

  const handlerDeleteMagazineRes = useCallback((responseData) => {
    setCurMagazine(null);
    setCurModal(null);
    loadMagazines();
  }, []);

  const handlerDeleteMagazine = (magazineId) => {
    const deleteMagazineURL = {
      type: "DELETE",
      endPoint: `/admin/magazine/${magazineId}`,
    };

    // make api call
    deleteMagazine.sendRequest(
      deleteMagazineURL,
      handlerDeleteMagazineRes,
      {},
      "Magazine deleted successfully."
    );
  };

  const handlerEditMagazine = (magazineId, payload) => {
    const editMagazineURL = {
      type: "PATCH",
      endPoint: `/admin/magazine/${magazineId}`,
    };

    // make api call
    editMagazine.sendRequest(
      editMagazineURL,
      handlerEditMagazineRes,
      payload,
      "Magazine updated successfully."
    );
  };

  const handlerSubmitAddMagazine = (payload) => {
    addMagazine.sendRequest(
      CONSTANTS.URLS.addMagazine,
      handlerAddMagazineRes,
      payload,
      "Magazine added successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    if (curModal === "add") {
      handlerSubmitAddMagazine(payload);
    } else {
      handlerEditMagazine(curMagazine?.id, payload);
    }
  };

  const loadMagazines = () => {
    getAllMagazines.sendRequest(
      CONSTANTS.URLS.getAllMagazines,
      handlerGetAllMagazines,
      {}
    );
  };

  useEffect(() => {
    loadMagazines();
  }, []);

  return (
    <>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this magazine?"}
        desc={<u>{curMagazine?.title}</u>}
        onDelete={() => handlerDeleteMagazine(curMagazine?.id)}
      />
      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                title: curMagazine?.title,
                coverPhoto: curMagazine?.coverPhoto,
                file: curMagazine?.file,
              }
            : {}
        }
      />
      <PageTitle
        pageHeading="Magazines"
        backNavPath="/"
        withBackButton
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />
      <Magazines
        magazines={magazines}
        isLoading={getAllMagazines?.isLoading}
        onLoad={loadMagazines}
        onClickOnDeleteIcon={handlerClickOnDeleteIcon}
        onClickOnEditIcon={handlerClickOnEditIcon}
      />
    </>
  );
};

export default MagazinePage;
