import React from "react";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CountCard from "./analysis/CountCard";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../utils/constants";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";

const Home = () => {
  const getAnalysis = useHttp();

  const [analysis, setAnalysis] = useState({});

  const handlerGetAnalysis = useCallback((responseData) => {
    if (responseData.status === "success") {
      setAnalysis(responseData?.data);
    }
  }, []);

  const loadAnalysis = () => {
    getAnalysis.sendRequest(CONSTANTS.URLS.getAnalysis, handlerGetAnalysis, {});
  };

  useEffect(() => {
    loadAnalysis();
  }, []);

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <CountCard
        bgColor="danger"
        icon="fa fa-users"
        title="Blocked Users"
        count={analysis?.blockedUsers}
        link={"/user-block"}
      />

      <CountCard
        bgColor="info"
        icon="fa fa-users"
        title="Total Users"
        count={analysis?.totalUsers}
        link={"/total-user"}
      />

      <CountCard
        bgColor="success"
        icon="fa fa-bell"
        title="Active Subscriptions"
        count={analysis?.activeSubscription}
        link={"/subscriptions"}
      />

      <CountCard
        bgColor="success"
        icon="fa fa-user"
        title="Our Promoters"
        count={analysis?.ourPromoters}
        link={"/promoter"}
      />
      <CountCard
        bgColor="info"
        icon="fa fa-dollar"
        title="Total Amount"
        amount={analysis?.totalAmount}
      />
    </div>
  );
};

export default Home;
