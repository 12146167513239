import React, { useState } from "react";
/// React router dom
import { Route, Routes } from "react-router-dom";
/// Css
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import Footer from "./layouts/Footer";
import Nav from "./layouts/nav";

/// Dashboard
import DietFoodMenu from "./components/Dashboard/DietFoodMenu";
import DistanceMap from "./components/Dashboard/DistanceMap";
import Home from "./components/Dashboard/Home";
import PersonalRecord from "./components/Dashboard/PersonalRecord";
import Task from "./components/Dashboard/Task";
import WorkoutPlan from "./components/Dashboard/WorkoutPlan";
import WorkoutStatistic from "./components/Dashboard/WorkoutStatistic";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";

/// Product List
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import EcomCustomers from "./components/AppsMenu/Shop/Customers/Customers";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";

/// Charts
import ChartJs from "./components/charts/Chartjs";
import RechartJs from "./components/charts/rechart";
//import Chartist from './components/charts/chartist'
import ApexChart from "./components/charts/apexcharts";
import SparklineChart from "./components/charts/Sparkline";

/// Bootstrap
import UiAccordion from "./components/bootstrap/Accordion";
import UiAlert from "./components/bootstrap/Alert";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiGrid from "./components/bootstrap/Grid";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiModal from "./components/bootstrap/Modal";
import UiPagination from "./components/bootstrap/Pagination";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
//import Nestable from './components/PluginsMenu/Nestable/Nestable'
//import MainNouiSlider from './components/PluginsMenu/Noui Slider/MainNouiSlider'
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";

/// Widget
import Widget from "./pages/Widget";

/// Table
import BootstrapTable from "./components/table/BootstrapTable";
import DataTable from "./components/table/DataTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import SortingTable from "./components/table/SortingTable/SortingTable";

/// Form
//import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
//import WizardForm from "./components/Forms/ReduxWizard/Index";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Element from "./components/Forms/Element/Element";
import FormValidation from "./components/Forms/FormValidation/FormValidation";
import Pickers from "./components/Forms/Pickers/Pickers";
import Wizard from "./components/Forms/Wizard/Wizard";

/// Pages
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import LockScreen from "./pages/LockScreen";
import Todo from "./pages/Todo";

//Scroll To Top
import { useCallback, useEffect } from "react";
import useHttp from "../hooks/use-http";
import CONSTANTS from "../utils/constants";
import UserProfile from "./components/AppsMenu/AppProfile/UserProfile";
import ScrollToTop from "./layouts/ScrollToTop";
import AppConfigPage from "./pages/AppConfigPage";
import ComingSoon from "./pages/CommigSoon";
import CourtOrderInfoPage from "./pages/CourtOrderAndJudgments/CourtOrdersInfoPage";
import CourtOrderInfoViewPage from "./pages/CourtOrderAndJudgments/CourtOrdersInfoViewPage";
import CourtOrdersJudgmentsPage from "./pages/CourtOrderAndJudgments/CourtOrdersJudgmentsPage";
import FAQPage from "./pages/FAQsPage";
import JobPortal from "./pages/JobPortal";
import LinksPage from "./pages/LinksPage";
import MagazinePage from "./pages/MagazinePage";
import MetalMarketPage from "./pages/MatalMarketPage";
import MinGuideCircularInfoItemsPage from "./pages/MinistryGuideline/MinGuideCircularInfoItemsPage";
import MinGuideCircularInfoItemViewPage from "./pages/MinistryGuideline/MinGuideCircularInfoItemViewPage";
import MinGuideCircularPage from "./pages/MinistryGuideline/MinGuideCircularPage";
import MinistryGuidelinePage from "./pages/MinistryGuideline/MinistryGuidelinePage";
import MMPDFViewer from "./pages/MMPDFViewer";
import PromotersPage from "./pages/PromotersPage";
import PurchaseBooks from "./pages/PurchaseBooks";
import SettingsPage from "./pages/SettingsPage";
import SubscriptionsPage from "./pages/SubscriptionsPage";
import Users from "./pages/UsersPage";
import VideoPage from "./pages/VideoPage";
import NotificationsPage from "./pages/NotificationPage";
import BannersPage from "./pages/BannersPage";
import UserBlock from "./pages/UserBlock";
import TotalUser from "./pages/TotalUser";
import TechWindowPage from "./pages/TechWindow/techWindowPage";
import TechWindowInfo from "./pages/TechWindow/techWindowInfo";
import TechWindowAnsInfo from "./pages/TechWindow/techWindowAnsInfo";
import TechUserList from "./pages/TechAnswer/techUserList";
import TechUserAnswerList from "./pages/TechAnswer/techUserAnswer";

const Markup = ({ toggleSidebar, onToggleNavbar }) => {
  const getMyProfile = useHttp();
  const [adminProfile, setAdminProfile] = useState({});

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },

    // mm-menus

    { url: "video", component: <VideoPage /> },
    { url: "user-block", component: <UserBlock /> },
    { url: "total-user", component: <TotalUser /> },

    { url: "magazine", component: <MagazinePage /> },
    { url: "links", component: <LinksPage /> },
    { url: "users", component: <Users /> },
    { url: "users-profile/:userId", component: <UserProfile /> },
    { url: "faqs", component: <FAQPage /> },
    { url: "ministry-guideline", component: <MinistryGuidelinePage /> },
    {
      url: "ministry-guideline/:guidelineId",
      component: <MinGuideCircularPage />,
    },
    {
      url: "userlist/:userId",
      component: <TechUserAnswerList />,
    },

    {
      url: "tech-window/:tecWindowId",
      component: <TechWindowInfo />,
    },
    {
      url: "tech-window/:tecWindowId/answer/:techQuestionId",
      component: <TechWindowAnsInfo />,
    },
    {
      url: "ministry-guideline/:guidelineId/circular/:circularId",
      component: <MinGuideCircularInfoItemsPage />,
    },
    {
      url: "ministry-guideline/:guidelineId/circular/:circularId/view",
      component: <MinGuideCircularInfoItemViewPage />,
    },
    {
      url: "pdf-viewer",
      component: <MMPDFViewer />,
    },
    {
      url: "court-orders-and-judgments",
      component: <CourtOrdersJudgmentsPage />,
    },
    {
      url: "court-orders-and-judgments/:courtOrderId",
      component: <CourtOrderInfoPage />,
    },
    {
      url: "court-orders-and-judgments-info/:courtOrderInfoItemId",
      component: <CourtOrderInfoViewPage />,
    },
    {
      url: "app-config",
      component: <AppConfigPage />,
    },
    { url: "purchase-books", component: <PurchaseBooks /> },
    { url: "promoter", component: <PromotersPage /> },
    { url: "m-market", component: <MetalMarketPage /> },
    { url: "tech-window", component: <TechWindowPage /> },
    { url: "userlist", component: <TechUserList /> },

    { url: "subscriptions", component: <SubscriptionsPage /> },
    { url: "job-portal", component: <JobPortal /> },
    { url: "notifications", component: <NotificationsPage /> },
    { url: "banners", component: <BannersPage /> },

    { url: "workout-statistic", component: <WorkoutStatistic /> },
    { url: "workout-plan", component: <WorkoutPlan /> },
    { url: "distance-map", component: <DistanceMap /> },
    { url: "diet-food-menu", component: <DietFoodMenu /> },
    { url: "personal-record", component: <PersonalRecord /> },
    { url: "task", component: <Task /> },

    /// Apps
    { url: "app-profile", component: <AppProfile /> },
    { url: "post-details", component: <PostDetails /> },
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "app-calender", component: <Calendar /> },

    /// Chart
    { url: "chart-sparkline", component: <SparklineChart /> },
    { url: "chart-chartjs", component: <ChartJs /> },
    //{ url: 'chart-chartist', component: Chartist },
    { url: "chart-apexchart", component: <ApexChart /> },
    { url: "chart-rechart", component: <RechartJs /> },

    /// Bootstrap
    { url: "ui-alert", component: <UiAlert /> },
    { url: "ui-badge", component: <UiBadge /> },
    { url: "ui-button", component: <UiButton /> },
    { url: "ui-modal", component: <UiModal /> },
    { url: "ui-button-group", component: <UiButtonGroup /> },
    { url: "ui-accordion", component: <UiAccordion /> },
    { url: "ui-list-group", component: <UiListGroup /> },
    { url: "ui-media-object", component: <UiMediaObject /> },
    { url: "ui-card", component: <UiCards /> },
    { url: "ui-carousel", component: <UiCarousel /> },
    { url: "ui-dropdown", component: <UiDropDown /> },
    { url: "ui-popover", component: <UiPopOver /> },
    { url: "ui-progressbar", component: <UiProgressBar /> },
    { url: "ui-tab", component: <UiTab /> },
    { url: "ui-pagination", component: <UiPagination /> },
    { url: "ui-typography", component: <UiTypography /> },
    { url: "ui-grid", component: <UiGrid /> },

    /// Plugin
    { url: "uc-select2", component: <Select2 /> },
    //{ url: 'uc-nestable', component: Nestable },
    //{ url: 'uc-noui-slider', component: MainNouiSlider },
    { url: "uc-sweetalert", component: <MainSweetAlert /> },
    { url: "uc-toastr", component: <Toastr /> },
    { url: "map-jqvmap", component: <JqvMap /> },
    { url: "uc-lightgallery", component: <Lightgallery /> },

    /// Widget
    { url: "widget-basic", component: <Widget /> },

    /// Shop
    { url: "ecom-product-grid", component: <ProductGrid /> },
    { url: "ecom-product-list", component: <ProductList /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-product-order", component: <ProductOrder /> },
    { url: "ecom-checkout", component: <Checkout /> },
    { url: "ecom-invoice", component: <Invoice /> },
    { url: "ecom-product-detail", component: <ProductDetail /> },
    { url: "ecom-customers", component: <EcomCustomers /> },

    /// Form

    //{ url: 'form-redux', component: ReduxForm },
    //{ url: 'form-redux-wizard', component: WizardForm },
    { url: "form-element", component: <Element /> },
    { url: "form-wizard", component: <Wizard /> },
    { url: "form-ckeditor", component: <CkEditor /> },
    { url: "form-pickers", component: <Pickers /> },
    { url: "form-validation", component: <FormValidation /> },

    /// table
    { url: "table-filtering", component: <FilteringTable /> },
    { url: "table-sorting", component: <SortingTable /> },
    { url: "table-datatable-basic", component: <DataTable /> },
    { url: "table-bootstrap-basic", component: <BootstrapTable /> },
    //{ url: 'table-filtering', component: FilteringTable },
    //{ url: 'table-sorting', component: SortingTable },

    /// pages
    //{ url: 'page-register', component: <Registration/> },
    { url: "page-lock-screen", component: <LockScreen /> },
    //{ url: 'page-login', component: <Login/> },
    { url: "page-error-400", component: <Error400 /> },
    { url: "page-error-403", component: <Error403 /> },
    { url: "page-error-404", component: <Error404 /> },
    { url: "page-error-coming-soon", component: <ComingSoon /> },
    { url: "page-error-500", component: <Error500 /> },
    { url: "page-error-503", component: <Error503 /> },
    { url: "todo", component: <Todo /> },
  ];

  const handlerGetMyProfile = useCallback((responseData) => {
    setAdminProfile(responseData?.data?.admin);
  }, []);

  const loadMyProfile = () => {
    getMyProfile.sendRequest(
      CONSTANTS.URLS.getMyProfile,
      handlerGetMyProfile,
      {}
    );
  };

  useEffect(() => {
    loadMyProfile();
  }, []);

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            adminProfile={adminProfile}
            toggleSidebar={toggleSidebar}
            onToggleNavbar={onToggleNavbar}
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          } ${toggleSidebar ? "sidebar-open" : ""}
              }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            // style={{ minHeight: window.screen.height - 60 }}
          >
            <Routes>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  element={data.component}
                />
              ))}
            </Routes>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
