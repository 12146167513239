import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import PageTitle from "../layouts/PageTitle";
import MMAddButton from "../components/MMButtons/MMAddButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const MMPDFViewer = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const file = params.get("file");
  const fileName = params.get("fileName");

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Fragment>
      <PageTitle
        pageHeading={fileName}
        withBackButton
        buttons={[
          <Button variant="primary btn-rounded">
            <a href={file} download className="text-white d-flex">
              <span className="btn-icon-left text-primary">
                <i className="fa fa-download color-primary" />
              </span>
              Download
            </a>
          </Button>,
        ]}
      />

      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        className="pdf-document"
      >
        {/* <Page pageNumber={pageNumber} /> */}
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => (
            <Page pageNumber={page} />
          ))}
        {/* {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            className="pdf-page"
            pageNumber={index + 1}
            width="200"
          />
        ))} */}
      </Document>
    </Fragment>
  );
};

export default MMPDFViewer;
