import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../../utils/constants";
import DeleteModal from "../../components/custom-modals/DeleteModal";
import FormModal from "../../components/custom-modals/FormModal";
import MMAddButton from "../../components/MMButtons/MMAddButton";
import MMTable from "../../components/table/MMTable";
import Loader from "../Loader/Loader";
import PageTitle from "../../layouts/PageTitle";

const getFormModalTitle = {
  add: "Add Guideline",
  edit: "Edit Guideline",
  delete: "Delete Guideline",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addMinistryGuideline,
  edit: CONSTANTS.FORMS.editMinistryGuideline,
};

const MinistryGuidelinePage = () => {
  const getAllMinistryGuidelines = useHttp();
  const addMinistryGuideline = useHttp();
  const editMinistryGuideline = useHttp();
  const deleteMinistryGuidelines = useHttp();

  const isLoading = () => {
    return (
      getAllMinistryGuidelines.isLoading ||
      addMinistryGuideline.isLoading ||
      editMinistryGuideline.isLoading ||
      deleteMinistryGuidelines.isLoading
    );
  };

  const [ministryGuidelines, setMinistryGuidelines] = useState([]);
  const [ministryGuidelinesData, setMinistryGuidelinesData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curMinistryGuideline, setCurMinistryGuideline] = useState(null);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerClickOnEditIcon = (ministryGuideline) => {
    setCurMinistryGuideline(ministryGuideline);
    setCurModal("edit");
  };

  const ministryGuidelinesDataHandler = (ministryGuidelines) => {
    setMinistryGuidelinesData(
      ministryGuidelines.map((ministryGuideline, index) => {
        return {
          // userData: userData,
          no: `${index + 1}`,
          profile: (
            <div className="w-100 text-center">
              <img
                src={ministryGuideline?.icon}
                style={{
                  width: "3rem",
                  aspectRatio: 1,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                className="rounded-circle"
              />
            </div>
          ),
          name: ministryGuideline?.name,
          action: (
            <div className="d-flex justify-content-center">
              <Link
                to={`/ministry-guideline/${ministryGuideline?.id}`}
                state={{
                  title: ministryGuideline?.name,
                  type: ministryGuideline?.type,
                }}
                className="btn btn-warning shadow btn-xs sharp mr-2"
                title="View"
              >
                <i className="fa fa-eye"></i>
              </Link>
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnEditIcon(ministryGuideline)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnDeleteIcon(ministryGuideline)}
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
      })
    );
  };

  const handlerGetAllMinistryGuidelines = useCallback((responseData) => {
    if (responseData.status === "success") {
      setMinistryGuidelines(responseData?.data?.rows);
      ministryGuidelinesDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadMinistryGuidelines = () => {
    getAllMinistryGuidelines.sendRequest(
      CONSTANTS.URLS.getAllMinistryGuidelines,
      handlerGetAllMinistryGuidelines,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (ministryGuideline) => {
    setCurMinistryGuideline(ministryGuideline);
    setCurModal("delete");
  };

  const handlerDeleteMinistryGuidelinesRes = useCallback((responseData) => {
    setCurMinistryGuideline(null);
    setCurModal(null);
    loadMinistryGuidelines();
  }, []);

  const handlerDeleteMinistryGuideline = (ministryGuidelineId) => {
    const deleteMinistryGuidelineURL = {
      type: "DELETE",
      endPoint: `/admin/ministryguide/${ministryGuidelineId}`,
    };

    // make api call
    deleteMinistryGuidelines.sendRequest(
      deleteMinistryGuidelineURL,
      handlerDeleteMinistryGuidelinesRes,
      {},
      "Ministry guideline deleted successfully."
    );
  };

  const handlerAddMinistryGuidelineRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurMinistryGuideline(null);
      setCurModal(null);
      loadMinistryGuidelines();
    }
  }, []);

  const handlerSubmitAddMinistryGuideline = (payload) => {
    addMinistryGuideline.sendRequest(
      CONSTANTS.URLS.addMinistryGuideline,
      handlerAddMinistryGuidelineRes,
      payload,
      "Court order added successfully."
    );
  };

  const handlerEditMinistryGuidelineRes = useCallback((responseData) => {
    setCurModal(null);
    setCurMinistryGuideline(null);
    loadMinistryGuidelines();
  }, []);

  const handlerEditMinistryGuideline = (ministryGuidelineId, payload) => {
    const editMinistryGuidelineURL = {
      type: "PATCH",
      endPoint: `/admin/ministryguide/${ministryGuidelineId}`,
    };

    // make api call
    editMinistryGuideline.sendRequest(
      editMinistryGuidelineURL,
      handlerEditMinistryGuidelineRes,
      payload,
      "Court order updated successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    console.log(payload);
    if (curModal === "add") {
      handlerSubmitAddMinistryGuideline(payload);
    } else {
      handlerEditMinistryGuideline(curMinistryGuideline?.id, payload);
    }
  };

  useEffect(() => {
    loadMinistryGuidelines();
  }, []);

  useEffect(() => {
    if (curModal !== "delete") {
      const selectElements = document.getElementById("type");

      if (selectElements) {
        selectElements.classList.add("form-control");
      }
    }
  }, [curModal]);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this court order?"}
        desc={curMinistryGuideline?.title}
        onDelete={() =>
          handlerDeleteMinistryGuideline(curMinistryGuideline?.id)
        }
      />
      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                name: curMinistryGuideline?.name,
                icon: curMinistryGuideline?.icon,
                type: curMinistryGuideline?.type,
              }
            : {}
        }
      />
      <PageTitle
        pageHeading="Ministry Guidelines"
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />
      <MMTable
        title="Ministry Guidelines"
        tableData={{
          columns: getTableData("ministryGuidelines")["columns"],
          rows: ministryGuidelinesData,
        }}
        isShowFilter={true}
        filter="name"
      />
    </Fragment>
  );
};

export default MinistryGuidelinePage;
