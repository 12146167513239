import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import styles
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lightgallery.css";

//** Import Image */
//** Import Image */
import moment from "moment";
import useHttp from "../../../../hooks/use-http";
import { getTableData } from "../../../../utils/constants";
import PageTitle from "../../../layouts/PageTitle";
import MMTable from "../../table/MMTable";

const UserProfile = () => {
  const params = useParams();
  const getAllLinks = useHttp();

  const [user, setUser] = useState({});
  const [subscriptionData, setSubscriptionData] = useState([]);

  const subscriptionDataHandler = (subscriptions) => {
    setSubscriptionData(
      subscriptions.map((subscriptionData, index) => {
        return {
          // subscriptionData: subscriptionData,
          no: `${index + 1}`,
          planId: (
            <p className="w-100 text-center m-0 p-0">
              {subscriptionData?.plan?.name}
            </p>
          ),
          payId: (
            <p className="w-100 text-center m-0 p-0">
              {subscriptionData?.payId}
            </p>
          ),
          createdAt: (
            <p className="w-100 text-center m-0 p-0">
              {moment(subscriptionData?.createdAt)
                .local()
                .format("MMM DD, yyyy")}
            </p>
          ),
          expireDate: (
            <p className="w-100 text-center m-0 p-0">
              {moment(subscriptionData?.expireDate)
                .local()
                .format("MMM DD, yyyy")}
            </p>
          ),
        };
      })
    );
  };

  const handlerGetUser = useCallback((responseData) => {
    if (responseData.status === "success") {
      setUser(responseData?.data?.user);
      subscriptionDataHandler(responseData?.data?.user?.subscriptions);
    }
  }, []);

  const loadUser = () => {
    const getUserURL = {
      type: "GET",
      endPoint: `/admin/users/${params?.userId}`,
    };
    getAllLinks.sendRequest(getUserURL, handlerGetUser, {});
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <Fragment>
      <PageTitle pageHeading="Profile" backNavPath="/users" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body" style={{ height: "unset" }}>
            <div className="profile-head">
              <div className="profile-info">
                <div className="profile-photo ">
                  <img
                    src={user?.profile}
                    className="img-fluid rounded border border-2 border-primary"
                    alt="profile"
                    style={{ aspectRatio: 1 }}
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h3 className="text-primary mb-0">{user?.name}</h3>
                    <p>Id: {user?.id}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{user?.email}</h4>
                    <p>Email</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{user?.mobile}</h4>
                    <p>Phone</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MMTable
        title="Subscriptions"
        tableData={{
          columns: getTableData("subscriptions")["columns"]?.filter(
            (col) => col.field !== "user?.name"
          ),
          rows: subscriptionData,
        }}
      />
    </Fragment>
  );
};

export default UserProfile;
