import React, { Fragment, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../utils/constants";
import PageTitle from "../layouts/PageTitle";
import Loader from "./Loader/Loader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button } from "react-bootstrap";

const appConfigSchema = Yup.object().shape({
  androidVersion: Yup.string()
    .matches(
      /^\d+\.\d+\.\d+$/,
      "Version number must be in the format of major.minor.patch"
    )
    .required("Required"),
  iosVersion: Yup.string()
    .matches(
      /^\d+\.\d+\.\d+$/,
      "Version number must be in the format of major.minor.patch"
    )
    .required("Required"),
});

const AppConfigPage = () => {
  const getAppConfig = useHttp();
  const editAppConfig = useHttp();

  const isLoading = () => {
    return getAppConfig.isLoading || editAppConfig.isLoading;
  };

  const [appConfig, setAppConfig] = useState([]);

  const handlerClickOnEditIcon = (appConfig) => {};

  const handlerGetAppConfig = useCallback((responseData) => {
    if (responseData.status === "success") {
      setAppConfig(responseData?.data?.rows[0]);
    }
  }, []);

  const loadAppConfig = () => {
    getAppConfig.sendRequest(
      CONSTANTS.URLS.getAllAppConfigs,
      handlerGetAppConfig,
      {}
    );
  };

  const handlerEditAppConfigRes = useCallback((responseData) => {
    loadAppConfig();
  }, []);

  const handlerEditAppConfig = (appConfigId, payload) => {
    const editAppConfigURL = {
      type: "PATCH",
      endPoint: `/admin/appconfig/${appConfigId}`,
    };

    // make api call
    editAppConfig.sendRequest(
      editAppConfigURL,
      handlerEditAppConfigRes,
      payload,
      "App config updated successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    handlerEditAppConfig(appConfig?.id, payload);
  };

  useEffect(() => {
    loadAppConfig();

    const selectElements = document.querySelectorAll(".app-config-form select");

    selectElements.forEach((selectElement) => {
      selectElement.classList.add("form-control");
    });
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <PageTitle pageHeading="App Config" />
      <div className="w-100 d-flex justify-content-end mb-4"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update App Config</h4>
            </div>
            <div className="card-body app-config-form">
              <Formik
                initialValues={{
                  androidVersion: appConfig?.androidVersion,
                  iosVersion: appConfig?.iosVersion,
                  appUnderMaintenance: appConfig?.appUnderMaintenance,
                  forceUpdate: appConfig?.forceUpdate,
                  softUpdate: appConfig?.softUpdate,
                }}
                enableReinitialize
                validationSchema={appConfigSchema}
                onSubmit={handlerSubmitForm}
              >
                <Form className="app-config-form">
                  <div className="form-group mb-3">
                    <label className="text-label">Android Version</label>
                    <Field name="androidVersion">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <input
                          type="text"
                          {...field}
                          className="form-control"
                          required
                        />
                      )}
                    </Field>
                    <ErrorMessage name="androidVersion">
                      {(msg) => <p className="text-danger">{msg}</p>}
                    </ErrorMessage>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-label">ios Version</label>
                    <Field name="iosVersion">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <input
                          type="text"
                          {...field}
                          className="form-control"
                          required
                        />
                      )}
                    </Field>
                    <ErrorMessage name="iosVersion">
                      {(msg) => <p className="text-danger">{msg}</p>}
                    </ErrorMessage>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-label">App Under Maintenance</label>
                    <Field name="appUnderMaintenance">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <select {...field} className="form-control" required>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      )}
                    </Field>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-label">Force Update</label>
                    <Field name="forceUpdate">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <select {...field} className="form-control" required>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      )}
                    </Field>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-label">Soft Update</label>
                    <Field name="softUpdate">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <select {...field} className="form-control" required>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      )}
                    </Field>
                  </div>
                  <Button type="submit" title="Submit">
                    Submit
                  </Button>
                </Form>
              </Formik>
              <div className="py-3"></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppConfigPage;
