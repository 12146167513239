import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../../utils/constants";
import DeleteModal from "../../components/custom-modals/DeleteModal";
import FormModal from "../../components/custom-modals/FormModal";
// import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import MMAddButton from "../../components/MMButtons/MMAddButton";
import MMTable from "../../components/table/MMTable";
import Loader from "../Loader/Loader";
import PageTitle from "../../layouts/PageTitle";
import { Form, Input, Label, Row } from "reactstrap";
import { Modal, Button } from "react-bootstrap";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { Services } from "../../../services/service";
import { notify } from "../../../utils/funcs";
import moment from "moment";
import BulkLoader from "../Loader/BulkLoader";

const getFormModalTitle = {
  add: "Add Circuler Item",
  edit: "Edit Circuler Item",
  delete: "Delete Circuler Item",
  addInBulk: "Add In Bulk",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addMinGuideCircularInfoItem,
  edit: CONSTANTS.FORMS.editMinGuideCircularInfoItem,
  addInBulk: CONSTANTS.FORMS.addCourtOrderInfoItemInBulk,
};

const MinGuideCircularInfoItemsPage = () => {
  const params = useParams();
  const location = useLocation();

  const getMinGuideCircularInfoItems = useHttp();
  const addMinGuideCircularInfoItem = useHttp();
  const editMinGuideCircularInfoItem = useHttp();
  const deleteMinGuideCircularInfoItem = useHttp();

  const isLoading = () => {
    return (
      getMinGuideCircularInfoItems.isLoading ||
      addMinGuideCircularInfoItem.isLoading ||
      editMinGuideCircularInfoItem.isLoading ||
      deleteMinGuideCircularInfoItem.isLoading
    );
  };

  const [filterList, setFilterList] = useState([]);
  const [modalOpen, setmodalOpen] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState();
  const [filterString, setFilterString] = useState("");
  const [minGuideCircularInfoItems, setMinGuideCircularInfoItems] = useState(
    []
  );
  const [minGuideCircularInfoItemsData, setMinGuideCircularInfoItemsData] =
    useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curMinGuideCircularInfoItem, setCurMinGuideCircularInfoItem] =
    useState(null);
  const [loader, setLoader] = useState(false);
  const [bulkloader, setBulkLoader] = useState(null);
  const [fileName, setFileName] = useState("");

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerClickOnEditIcon = (minGuideCircularInfoItem) => {
    setCurMinGuideCircularInfoItem(minGuideCircularInfoItem);
    setCurModal("edit");
  };

  const minGuideCircularInfoItemsDataHandler = (minGuideCircularInfoItems) => {
    const alldata = minGuideCircularInfoItems.map((el) => el?.filter);
    setFilterList([...new Set(alldata)]);

    minGuideCircularInfoItems.sort((a, b) =>
      a.filter.localeCompare(b.filter, "en", { numeric: true })
    );
    setMinGuideCircularInfoItemsData(
      minGuideCircularInfoItems.map((minGuideCircularInfoItem, index) => {
        return {
          no: `${index + 1}`,
          name: minGuideCircularInfoItem?.fileName,
          filter: minGuideCircularInfoItem?.filter,
          index: minGuideCircularInfoItem?.index,
          file: (
            <div className="d-flex justify-content-center">
              <Link
                to={`/pdf-viewer?file=${minGuideCircularInfoItem?.file}&fileName=${minGuideCircularInfoItem?.fileName}`}
                className="btn btn-warning shadow btn-xs sharp mr-2"
                title="View"
              >
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          ),

          action: (
            <div className="d-flex justify-content-center">
              {/* <Link
                to={`/court-orders-and-judgments/${minGuideCircularInfoItem?.id}`}
                className="btn btn-warning shadow btn-xs sharp mr-2"
              >
                <i className="fa fa-eye"></i>
              </Link> */}
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnEditIcon(minGuideCircularInfoItem)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() =>
                  handlerClickOnDeleteIcon(minGuideCircularInfoItem)
                }
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
      })
    );
  };

  const handlerGetAllMinGuideCircularInfoItems = useCallback((responseData) => {
    if (responseData.status === "success") {
      setMinGuideCircularInfoItems(responseData?.data?.rows);
      minGuideCircularInfoItemsDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadMinGuideCircularInfoItems = () => {
    const editMinGuideCircularInfoItemURL = {
      type: "GET",
      endPoint: `/admin/ministryguidecircularinfo?ministryGuideCircularId=${params?.circularId}`,
    };
    getMinGuideCircularInfoItems.sendRequest(
      editMinGuideCircularInfoItemURL,
      handlerGetAllMinGuideCircularInfoItems,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (minGuideCircularInfoItem) => {
    setCurMinGuideCircularInfoItem(minGuideCircularInfoItem);
    setCurModal("delete");
  };

  const handlerDeleteMinGuideCircularInfoItemRes = useCallback(
    (responseData) => {
      setCurMinGuideCircularInfoItem(null);
      setCurModal(null);
      loadMinGuideCircularInfoItems();
    },
    []
  );

  const handlerDeleteMinGuideCircular = (minGuideCircularInfoItemId) => {
    const deleteMinGuideCircularURL = {
      type: "DELETE",
      endPoint: `/admin/ministryguidecircularinfo/${minGuideCircularInfoItemId}`,
    };

    // make api call
    deleteMinGuideCircularInfoItem.sendRequest(
      deleteMinGuideCircularURL,
      handlerDeleteMinGuideCircularInfoItemRes,
      {},
      "Circular info item deleted successfully."
    );
  };

  const handlerAddMinGuideCircularInfoItemRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurMinGuideCircularInfoItem(null);
      setCurModal(null);
      loadMinGuideCircularInfoItems();
    }
  }, []);

  const handlerSubmitAddMinGuideCircularInfoItem = (payload) => {
    payload.append("ministryGuideCircularId", params?.circularId);
    payload.append("ministryGuideId", params?.guidelineId);

    addMinGuideCircularInfoItem.sendRequest(
      CONSTANTS.URLS.addMinGuideCircularInfoItem,
      handlerAddMinGuideCircularInfoItemRes,
      payload,
      "Court order info item added successfully."
    );
  };

  const handlerEditMinGuideCircularInfoItemRes = useCallback((responseData) => {
    setCurModal(null);
    setCurMinGuideCircularInfoItem(null);
    loadMinGuideCircularInfoItems();
  }, []);

  const handlerEditMinGuideCircularInfoItem = (
    minGuideCircularInfoItemId,
    payload
  ) => {
    const editMinGuideCircularInfoItemURL = {
      type: "PATCH",
      endPoint: `/admin/ministryguidecircularinfo/${minGuideCircularInfoItemId}`,
    };

    payload.append("ministryGuideCircularId", params?.circularId);
    payload.append("ministryGuideId", params?.guidelineId);

    // make api call
    editMinGuideCircularInfoItem.sendRequest(
      editMinGuideCircularInfoItemURL,
      handlerEditMinGuideCircularInfoItemRes,
      payload,
      "Court order info item updated successfully."
    );
  };

  const handlerSubmitAddCourtOrderInfoItemInBulk = (formData) => {
    setLoader(true);

    setBulkLoader(1);
    const uploadFiles = async () => {
      let startIndex = 0;

      const sameArray = minGuideCircularInfoItemsData?.filter(
        (el) => el.filter == formData.filter.value
      );
      console.log(sameArray);

      if (sameArray.length) {
        sameArray?.sort((a, b) => {
          return a.index - b.index;
        });
        startIndex = +sameArray[sameArray.length - 1].index;
      }

      let buclFile = Object.values(formData.file.files);

      if (
        moment(buclFile[0].name.substring(0, 10), "DD.MM.YYYY", true)._isValid
      ) {
        buclFile.sort(
          (a, b) =>
            new moment(a.name.substring(0, 10), "DD.MM.YYYY").format(
              "YYYYMMDD"
            ) -
            new moment(b.name.substring(0, 10), "DD.MM.YYYY").format("YYYYMMDD")
        );
      }
      let isAllDataSubmited = true;
      const AllFiles = Array.from(buclFile);
      setCurModal(null);
      for (let i = 0; i < AllFiles.length; i++) {
        {
          setBulkLoader(((i + 1) / AllFiles.length) * 100);
          setFileName(AllFiles[i].name);

          try {
            if (AllFiles[i]?.name) {
              let fName = AllFiles[i].name;
              if (
                moment(AllFiles[i].name.substring(0, 10), "DD.MM.YYYY", true)
                  ._isValid
              ) {
                fName = moment(
                  AllFiles[i].name.substring(0, 10),
                  "DD.MM.YYYY"
                ).format("MMM DD, YYYY");
              } else {
                fName = AllFiles[i].name.replace(".pdf", "");
              }

              const payload = new FormData();
              payload.append("file", AllFiles[i]);
              payload.append("ministryGuideCircularId", params?.circularId);
              payload.append("ministryGuideId", params?.guidelineId);
              payload.append("fileName", fName);
              if (formData.filter.value) {
                payload.append("filter", formData.filter.value);
              }

              payload.append("index", startIndex + 1 + i);
              const response = await Services.post(
                CONSTANTS.URLS.addMinGuideCircularInfoItem.endPoint,
                payload
              );

              if (!response.statusText === "OK") {
                throw new Error("Failed to upload file");
              }
            }
          } catch (error) {
            setCurModal("alert");
            loadMinGuideCircularInfoItems();
            notify.error(error?.response?.data?.message);
            //  { file: AllFiles[i][1].name, error: error.message };
          }
        }
      }

      loadMinGuideCircularInfoItems();
      setCurModal(null);
      isAllDataSubmited && notify.success("All files uploaded successfully!");

      setBulkLoader(null);
      setLoader(false);
    };
    uploadFiles();
  };

  const handleSelectedFilter = (value) => {
    setSelectedFilter(value);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    (async () => {
      const payload = new FormData();

      try {
        if (curModal === "edit") {
          if (e.target.file.files[0])
            payload.append("file", e.target.file.files[0]);
          payload.append("fileName", e.target.name.value);
          payload.append("index", e.target.index.value);
          payload.append("filter", e.target.filter.value);
          handlerEditMinGuideCircularInfoItem(
            curMinGuideCircularInfoItem?.id,
            payload
          );
        } else if (curModal === "add") {
          if (e.target.file.files[0])
            payload.append("file", e.target.file.files[0]);
          payload.append("fileName", e.target.name.value);

          payload.append("filter", e.target.filter.value);
          handlerSubmitAddMinGuideCircularInfoItem(payload);
        } else {
          handlerSubmitAddCourtOrderInfoItemInBulk(e.target);
        }

        setmodalOpen(false);
      } catch (error) {
        console.log(error);
      }
    })();
  };
  useEffect(() => {
    loadMinGuideCircularInfoItems();
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete court order info item with this date?"}
        desc={curMinGuideCircularInfoItem?.date}
        onDelete={() =>
          handlerDeleteMinGuideCircular(curMinGuideCircularInfoItem?.id)
        }
      />
      {bulkloader ? (
        <BulkLoader progress={bulkloader} fileNames={fileName} />
      ) : (
        <></>
      )}
      <Modal
        show={
          curModal === "add" || curModal === "edit" || curModal === "addInBulk"
        }
        onExit={() => {
          setmodalOpen(false);
          handlerCloseModal();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Form
          onSubmit={formSubmitHandler}
          defaultValue={
            curModal === "edit"
              ? {
                  name: curMinGuideCircularInfoItem?.fileName,
                  filter: curMinGuideCircularInfoItem?.filter,
                  file: curMinGuideCircularInfoItem?.file,
                  index: curMinGuideCircularInfoItem?.index,
                }
              : {}
          }
        >
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">
              {getFormModalTitle[curModal]}
            </Modal.Title>
            <Button
              variant=""
              onClick={() => {
                setmodalOpen(false);
                handlerCloseModal();
              }}
              type="button"
              className="close"
            >
              <span>×</span>
            </Button>
          </Modal.Header>

          <Modal.Body>
            <Row className="mt-4">
              {curModal === "add" || curModal === "edit" ? (
                <Label>File</Label>
              ) : (
                <Label>Files</Label>
              )}

              <Input
                type="file"
                id="file"
                accept="application/pdf"
                required={curModal === "edit" ? false : true}
                multiple={curModal === "addInBulk" ? true : false}
              />
            </Row>

            {!(curModal === "addInBulk") && (
              <>
                <Row className="mt-4">
                  <Label>File Name</Label>
                  <Input
                    required
                    style={{ padding: "18px 10px", fontSize: "17px" }}
                    placeholder="Name"
                    type="text"
                    id="name"
                    defaultValue={
                      curModal === "edit"
                        ? curMinGuideCircularInfoItem?.fileName
                        : ""
                    }
                  />
                </Row>
              </>
            )}
            {!(curModal === "addInBulk") && (
              <>
                <Row className="mt-4">
                  <Label>Index</Label>
                  <Input
                    required
                    style={{ padding: "18px 10px", fontSize: "17px" }}
                    placeholder="Index"
                    type="number"
                    id="index"
                    defaultValue={
                      curModal === "edit"
                        ? curMinGuideCircularInfoItem?.index
                        : ""
                    }
                  />
                </Row>
              </>
            )}
            <Row className="mt-4">
              <Label>Filter</Label>
              <Autocomplete
                id="filter"
                freeSolo
                className="w-100"
                options={filterList}
                renderInput={(params) => (
                  <TextField {...params} label="Search Filters" />
                )}
                defaultValue={
                  curModal === "edit" ? curMinGuideCircularInfoItem?.filter : ""
                }
                onInputChange={(event, newInputValue) => {
                  setFilterString(newInputValue);
                }}
                onChange={(event, newValue) => {
                  handleSelectedFilter(newValue);
                }}
                getOptionLabel={(option) => option?.filter ?? option}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={loader} outline>
              {loader && <Loader />}Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <PageTitle
        pageHeading={`${location?.state?.lastPath?.title} / ${location?.state?.title}`}
        withBackButton
        buttons={[
          <MMAddButton
            key="addInBulk"
            clickHandler={() => setCurModal("addInBulk")}
          >
            Bulk Upload
          </MMAddButton>,
        ]}
      />

      <MMTable
        title={location?.state?.title}
        filter="filter"
        isShowFilter={true}
        tableData={{
          columns: getTableData("minGuideCircularInfoItem")["columns"],
          rows: minGuideCircularInfoItemsData,
        }}
      />
    </Fragment>
  );
};

export default MinGuideCircularInfoItemsPage;
