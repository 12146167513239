import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../utils/constants";
import DeleteModal from "../components/custom-modals/DeleteModal";
import FormModal from "../components/custom-modals/FormModal";
import MMAddButton from "../components/MMButtons/MMAddButton";
import { Modal, Button } from "react-bootstrap";
import { Form, Input, Label, Row } from "reactstrap";

import MMTable from "../components/table/MMTable";
import Loader from "./Loader/Loader";
import PageTitle from "../layouts/PageTitle";
import { convertToChemicalFormula } from "../../utils/funcs";
import moment from "moment";

const getFormModalTitle = {
  add: "Add Mineral",
  edit: "Edit Mineral",
  delete: "Delete Mineral",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addMetal,
  edit: CONSTANTS.FORMS.editMetal,
};

const MetalMarketPage = () => {
  const getAllMetals = useHttp();
  const addMetal = useHttp();
  const editMetal = useHttp();
  const deleteMetals = useHttp();

  const isLoading = () => {
    return (
      getAllMetals.isLoading ||
      addMetal.isLoading ||
      editMetal.isLoading ||
      deleteMetals.isLoading
    );
  };

  const [metals, setMetals] = useState([]);
  const [metalsData, setMetalsData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curMetal, setCurMetal] = useState(null);
  const [loader, setLoader] = useState(false);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerClickOnEditIcon = (metal) => {
    setCurMetal(metal);
    setCurModal("edit");
  };

  const metalsDataHandler = (metals) => {
    setMetalsData(
      metals.map((metal, index) => {
        return {
          // userData: userData,
          no: `${index + 1}`,
          name: metal?.name,
          symbol: metal?.symbol,
          unit: (
            <p
              className="w-100 text-center m-0 p-0"
              dangerouslySetInnerHTML={{
                __html: convertToChemicalFormula(metal?.unit),
              }}
            />
          ),
          state: metal?.state,
          ltt: moment(metal?.ltt, "h:mm a").format("hh:mm a"),
          ltp: metal?.ltp,
          price: metal?.price.toString(),
          number: (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="w-100 text-center m-0 p-0">
                  {metal?.variance.toFixed(2)}
                </p>
                {metal?.variance < 0 ? (
                  <i
                    style={{ marginLeft: "20px", color: "red" }}
                    class="fa fa-arrow-down"
                    aria-hidden="true"
                  />
                ) : (
                  <i
                    style={{ marginLeft: "20px", color: "green" }}
                    class="fa fa-arrow-up"
                    aria-hidden="true"
                  />
                )}
              </div>
            </>
          ),
          color: (
            <div
              id="boxOne"
              style={{
                textAlign: "center",
                flexBasis: "33%",
                width: "30px",
                height: "25px",
                backgroundColor: metal?.color,
              }}
            ></div>
          ),
          action: (
            <div className="d-flex justify-content-center">
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnEditIcon(metal)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnDeleteIcon(metal)}
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
      })
    );
  };

  const handlerGetAllmetals = useCallback((responseData) => {
    if (responseData.status === "success") {
      setMetals(responseData?.data?.rows);
      metalsDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadMetals = () => {
    getAllMetals.sendRequest(
      CONSTANTS.URLS.getAllMetals,
      handlerGetAllmetals,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (metal) => {
    setCurMetal(metal);
    setCurModal("delete");
  };

  const handlerDeleteMetalsRes = useCallback((responseData) => {
    setCurMetal(null);
    setCurModal(null);
    loadMetals();
  }, []);

  const handlerDeleteMetal = (metalId) => {
    const deleteMetalURL = {
      type: "DELETE",
      endPoint: `/admin/market/${metalId}`,
    };

    // make api call
    deleteMetals.sendRequest(
      deleteMetalURL,
      handlerDeleteMetalsRes,
      {},
      "Mineral deleted successfully."
    );
  };

  const handlerAddMetalRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurMetal(null);
      setCurModal(null);
      loadMetals();
    }
  }, []);

  const handlerSubmitAddMetal = (payload) => {
    setLoader(true);
    addMetal.sendRequest(
      CONSTANTS.URLS.addMetal,
      handlerAddMetalRes,
      payload,
      "Mineral added successfully."
    );

    setLoader(false);
  };

  const handlerEditMetalRes = useCallback((responseData) => {
    setCurModal(null);
    setCurMetal(null);
    loadMetals();
  }, []);

  const handlerEditMetal = (metalId, payload) => {
    payload.variance =
      ((+payload?.price - +curMetal?.price) * 100) / +curMetal?.price;

    const editMetalURL = {
      type: "PATCH",
      endPoint: `/admin/market/${metalId}`,
    };

    // make api call
    editMetal.sendRequest(
      editMetalURL,
      handlerEditMetalRes,
      payload,
      "Mineral updated successfully."
    );
  };
  function getCurrentIST() {
    // Get current UTC time
    const currentUtcTime = moment.utc();
    // Define IST offset (UTC+5:30)
    const istOffset = 5.5;
    // Calculate IST time
    const istTime = currentUtcTime.utcOffset(istOffset);
    // Return formatted IST time
    return istTime.format("hh:mm A");
  }
  const handlerSubmitForm = (payload) => {
    payload.preventDefault();

    // const myFormData = new FormData(payload.target);
    const demo = {};
    demo.name = payload?.target?.name?.value;
    demo.symbol = payload?.target?.symbol?.value;
    demo.unit = payload?.target?.unit?.value;
    demo.state = payload?.target?.state?.value;
    demo.ltt = payload?.target?.ltt?.value;
    demo.ltp = payload?.target?.price?.value;
    demo.price = payload?.target?.price?.value;
    demo.color = payload?.target?.color?.value;

    // const formDataObj = {};
    // payload.target.forEach((value, key) => (formDataObj[key] = value));
    if (curModal === "add") {
      handlerSubmitAddMetal(demo);
    } else {
      if (curMetal?.price != demo.price) {
        demo.ltt = getCurrentIST();
        demo.ltp = curMetal?.price;
      }
      handlerEditMetal(curMetal?.id, demo);
    }
  };

  useEffect(() => {
    loadMetals();
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this Mineral?"}
        desc={curMetal?.name}
        onDelete={() => handlerDeleteMetal(curMetal?.id)}
      />
      {/* <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                name: curMetal?.name,
                symbol: curMetal?.symbol,
                unit: curMetal?.unit,
                state: curMetal?.state,
                ltt: moment(curMetal?.llt),
                price: curMetal?.price,
                // variance: curMetal?.number,
              }
            : {}
        }
      /> */}

      <Modal
        show={curModal === "add" || curModal === "edit"}
        onExit={() => {
          // setmodalOpen(false);
          handlerCloseModal();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Form
          onSubmit={handlerSubmitForm}
          defaultValue={{
            name: curMetal?.name,
            symbol: curMetal?.symbol,
            unit: curMetal?.unit,
            state: curMetal?.state,
            ltt: moment(curMetal?.llt),
            price: curMetal?.price,

            ...curMetal,
          }}
        >
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">
              {getFormModalTitle[curModal]}
            </Modal.Title>
            <Button
              variant=""
              onClick={() => {
                // setmodalOpen(false);
                handlerCloseModal();
              }}
              type="button"
              className="close"
            >
              <span>×</span>
            </Button>
          </Modal.Header>

          <Modal.Body>
            <Row className="mt-4">
              <Label>Name</Label>

              <Input
                id="name"
                type="text"
                required
                defaultValue={curModal === "edit" ? curMetal?.name : ""}
              />
            </Row>
            <Row className="mt-4">
              <Label>Symbol</Label>

              <Input
                id="symbol"
                type="text"
                required
                defaultValue={curModal === "edit" ? curMetal?.symbol : ""}
              />
            </Row>
            <Row className="mt-4">
              <Label>Unit</Label>

              <Input
                id="unit"
                type="text"
                required
                defaultValue={curModal === "edit" ? curMetal?.unit : ""}
              />
            </Row>

            <Row className="mt-4">
              <Label>Destination Port</Label>

              <Input
                id="state"
                type="text"
                required
                defaultValue={curModal === "edit" ? curMetal?.state : ""}
              />
            </Row>
            {/* <Row className="mt-4">
              <Label>Last trading time</Label>

              <Input
                id="ltt"
                name="ltt"
                type="time"
                defaultValue={curModal === "edit" ? curMetal?.ltt : ""}
              />
            </Row> */}
            {/* {curModal !== "edit" ? (
              <Row className="mt-4">
                <Label>Last trading Price</Label>

                <Input
                  id="ltp"
                  name="ltp"
                  type="number"
                  defaultValue={curModal === "edit" ? curMetal?.ltp : ""}
                />
              </Row>
            ) : (
              <></>
            )} */}
            <Row className="mt-4">
              <Label>Price (In USD)</Label>

              <Input
                id="price"
                type="number"
                step="0.01"
                required
                defaultValue={curModal === "edit" ? curMetal?.price : ""}
              />
            </Row>
            <Row className="mt-4">
              <Label>Color</Label>

              <Input
                id="color"
                type="color"
                defaultValue={curModal === "edit" ? curMetal?.color : ""}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={loader} outline>
              {loader && <Loader />}Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <PageTitle
        pageHeading="M-Market"
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />
      <MMTable
        title="Minerals"
        noItemMsg="No Minerals found."
        tableData={{
          columns: getTableData("metals")["columns"],
          rows: metalsData,
        }}
        isShowFilter={true}
        filter={"name"}
      />
    </Fragment>
  );
};

export default MetalMarketPage;
