import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import comingSoonSVG from "./../../images/coming-soon.svg";

const NoItemsFound = (props) => {
  const { message } = props;

  return (
    <Card className="w-100 " style={{ padding: "8rem" }}>
      <Card.Body>
        <h4 className="text-center display-4 ">
          {message || "No items found."}
        </h4>
      </Card.Body>
    </Card>
  );
};

export default NoItemsFound;
