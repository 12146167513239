import { Button, Card, Col } from "react-bootstrap";
import { handlerCopyToClipBoard } from "../../../utils/funcs";
import CONSTANTS from "../../../utils/constants";
import { NavLink } from "react-router-dom";

const Link = (props) => {
  const { link, index, onClickOnDeleteIcon, onClickOnEditIcon } = props;

  return (
    <>
      <Col lg="4" xs="12" sm="6">
        <Card>
          <Card.Header className=" border-0 pb-0">
            <div className="d-flex justify-content-between align-items-center w-100">
              <Card.Title
                className="text-nowrap"
                style={{ wordWrap: "break-word", overflow: "hidden" }}
              >
                {link?.title}
              </Card.Title>
            </div>
          </Card.Header>
          <NavLink to={link?.link} target="_blank" title="Link">
            <Card.Body className=" border-0 pb-0">
              <img
                className="img-fluid"
                style={{
                  aspectRatio: 16 / 9,
                  // backgroundSize: "objectFit",
                  objectFit: "cover",
                  maxHeight: "200px",
                  Width: "100%",
                  backgroundPosition: "center center",
                  position: "relative",
                }}
                src={link?.photo || CONSTANTS.imgPlaceHolder}
                alt="Webpage preview"
              />
            </Card.Body>
          </NavLink>
          <Card.Footer
            className="border-0 pt-2 pb-2 text-nowrap"
            title="Link"
            style={{ wordWrap: "break-word", overflow: "hidden" }}
          >
            <Card.Link>
              <u>{link?.link}</u>
            </Card.Link>
            <div className="btn-wrapper d-flex">
              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center px-3"
                onClick={() => handlerCopyToClipBoard(link?.link)}
                title="Copy"
              >
                <i className="fa fa-copy" style={{ fontSize: "2rem" }} />
              </Button>
              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center px-3"
                onClick={() => {
                  onClickOnEditIcon(link);
                }}
                title="Edit"
              >
                <i className="fa fa-edit" style={{ fontSize: "2rem" }} />
              </Button>
              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center px-3"
                onClick={() => onClickOnDeleteIcon(link)}
                title="Delete"
              >
                <i className="fa fa-trash" style={{ fontSize: "2rem" }} />
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Col>
    </>
  );
};

export default Link;
