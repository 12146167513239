import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import comingSoonSVG from "./../../images/coming-soon.svg";
import logoGIF from "./../../images/magma-gif.gif";

const ItemLoader = (props) => {
  const { inside, message } = props;

  return (
    <Card className="w-100 " style={{ padding: "8rem" }}>
      <Card.Body className="d-flex justify-content-center">
        <img src={logoGIF} alt="" style={{ width: "12rem" }} />
      </Card.Body>
    </Card>
  );
};

export default ItemLoader;
