import { Fragment } from "react";
import { Accordion, Button, Card } from "react-bootstrap";

const FAQ = (props) => {
  const {
    faq,
    activeBordered,
    setActiveBordered,
    onClickOnDeleteIcon,
    onClickOnEditIcon,
  } = props;

  return (
    <>
      <div className="accordion__item">
        <Accordion.Toggle
          className={`accordion__header ${
            activeBordered !== faq?.id ? "collapsed" : ""
          }`}
          as={Card.Text}
          eventKey={`${faq?.id}`}
          onClick={() =>
            setActiveBordered(activeBordered === faq?.id ? -1 : faq?.id)
          }
        >
          <span className="accordion__header--text">{faq?.title}</span>
          <span className="accordion__header--indicator"></span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={`${faq?.id}`} className="accordion__body">
          <Fragment>
            <div className="accordion__body--text">{faq?.body}</div>
            <div className="d-flex justify-content-end">
              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center"
                onClick={() => {
                  onClickOnEditIcon(faq);
                }}
                title="Edit"
              >
                <i className="fa fa-edit" style={{ fontSize: "2rem" }} />
              </Button>
              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center"
                onClick={() => onClickOnDeleteIcon(faq)}
                title="Delete"
              >
                <i className="fa fa-trash" style={{ fontSize: "2rem" }} />
              </Button>
            </div>
          </Fragment>
        </Accordion.Collapse>
      </div>
    </>
  );
};

export default FAQ;
