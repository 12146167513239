import React, { Fragment, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../utils/constants";
import PageTitle from "../layouts/PageTitle";
import Loader from "./Loader/Loader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button } from "react-bootstrap";

const notificationsSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  body: Yup.string().required("Required"),
  userId: Yup.string("Required").required("Required"),
});

const notificationsSchemaAll = Yup.object().shape({
  title: Yup.string().required("Required"),
  body: Yup.string().required("Required"),
});

const NotificationsPage = () => {
  const getAllUsers = useHttp();
  const addNotification = useHttp();

  const isLoading = () => {
    return getAllUsers.isLoading;
  };

  const [users, setUsers] = useState([]);

  const handlerSubmitForm = (payload, { resetForm }) => {
    payload.click_action = "PUSH";

    addNotification.sendRequest(
      CONSTANTS.URLS.addNotification,
      () => {},
      payload,
      "Notification added successfully."
    );

    resetForm();
  };

  const handlerallSubmitForm = (payload, { resetForm }) => {
    payload.click_action = "PUSH";

    users.forEach((el, i) => {
      payload.userId = el.id;

      if (i === users.length - 1) {
        addNotification.sendRequest(
          CONSTANTS.URLS.addNotification,
          () => {},
          payload,

          "Notification added successfully."
        );
      } else {
        addNotification.sendRequest(
          CONSTANTS.URLS.addNotification,
          () => {},
          payload
        );
      }
    });

    resetForm();
  };

  const handlerGetAllUsers = useCallback((responseData) => {
    if (responseData.status === "success") {
      setUsers(responseData?.data?.users);
    }
  }, []);

  const loadUsers = () => {
    getAllUsers.sendRequest(CONSTANTS.URLS.getAllUsers, handlerGetAllUsers, {});
  };

  useEffect(() => {
    loadUsers();

    const selectElements = document.querySelectorAll(
      ".notification-form select"
    );

    selectElements.forEach((selectElement) => {
      selectElement.classList.add("form-control");
    });
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <PageTitle pageHeading="Notification" />
      <div className="w-100 d-flex justify-content-end mb-4"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Notification</h4>
            </div>
            <div className="card-body notification-form">
              <Formik
                initialValues={{
                  title: "",
                  body: "",
                  userId: "Select User",
                }}
                validationSchema={notificationsSchema}
                onSubmit={handlerSubmitForm}
              >
                <Form className="notification-form notification-form">
                  <div className="form-group mb-3">
                    <label className="text-label">Title</label>
                    <Field name="title">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <input
                          type="text"
                          {...field}
                          className="form-control"
                          required
                        />
                      )}
                    </Field>
                    <ErrorMessage name="title">
                      {(msg) => <p className="text-danger">{msg}</p>}
                    </ErrorMessage>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-label">Body</label>
                    <Field name="body">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <input
                          type="text"
                          {...field}
                          className="form-control"
                          required
                        />
                      )}
                    </Field>
                    <ErrorMessage name="body">
                      {(msg) => <p className="text-danger">{msg}</p>}
                    </ErrorMessage>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-label">User</label>
                    <Field name="userId">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <select
                          {...field}
                          className="form-control"
                          defaultValue="Select User"
                          required
                        >
                          <option value="Select User" disabled>
                            Select User
                          </option>
                          {users?.map((user) => (
                            <option key={user?.id} value={user?.id}>
                              {user?.name} - {user?.email}
                            </option>
                          ))}
                        </select>
                      )}
                    </Field>
                    <ErrorMessage name="userId">
                      {(msg) => <p className="text-danger">{msg}</p>}
                    </ErrorMessage>
                  </div>
                  <Button type="submit" title="Submit">
                    Submit
                  </Button>
                </Form>
              </Formik>
              <div className="py-3"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 d-flex justify-content-end mb-4"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">All Users Notification</h4>
            </div>
            <div className="card-body notification-form">
              <Formik
                initialValues={{
                  title: "",
                  body: "",
                  userId: "",
                }}
                validationSchema={notificationsSchemaAll}
                onSubmit={handlerallSubmitForm}
              >
                <Form className="notification-form notification-form">
                  <div className="form-group mb-3">
                    <label className="text-label">Title</label>
                    <Field name="title">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <input
                          type="text"
                          {...field}
                          className="form-control"
                          required
                        />
                      )}
                    </Field>
                    <ErrorMessage name="title">
                      {(msg) => <p className="text-danger">{msg}</p>}
                    </ErrorMessage>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-label">Body</label>
                    <Field name="body">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <input
                          type="text"
                          {...field}
                          className="form-control"
                          required
                        />
                      )}
                    </Field>
                    <ErrorMessage name="body">
                      {(msg) => <p className="text-danger">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <Button type="submit" title="Submit">
                    Submit
                  </Button>
                </Form>
              </Formik>
              <div className="py-3"></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotificationsPage;
