import axios from "axios";
import CONSTANT from "./../utils/constants";

// const tempAuthToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTQ0NDU3LCJlbWFpbEwiOiJob3NwaXRhbEBtZWRlbGxhLmNvbSIsInJvbGUiOiJob3NwaXRhbCIsImlhdCI6MTY2NDI5MDU5OH0.0zE5R8kMyXGw5RDSTd7EC4J9tBFb35LK7ERJH7eR7GU';

export const Services = axios.create({
  baseURL: CONSTANT.baseURL,

  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("authToken"),
    // Authorization: "Bearer " + tempAuthToken,
  },
});
