import { Button, Card, Col } from "react-bootstrap";
import { handlerCopyToClipBoard } from "../../../utils/funcs";

const VideoCard = (props) => {
  const { video, index, onClickOnDeleteIcon, onClickOnEditIcon } = props;
  // player = {};

  // onPlayerReady(player){
  //       this.player = player;
  //   }
  return (
    <>
      <Col lg="4" xs="12" sm="6">
        <Card>
          <Card.Header className=" border-0 pb-2">
            <div className="d-flex justify-content-between align-items-center w-100">
              <Card.Title
                className="text-nowrap"
                style={{
                  wordWrap: "break-word",
                  overflow: "hidden",
                  fontSize: "18px",
                }}
              >
                {video?.title}
              </Card.Title>
            </div>
          </Card.Header>
          <Card.Body className=" border-0 pb-0">
            <video
              controls
              className="img-fluid"
              style={{ aspectRatio: 16 / 9 }}
              poster={video?.thumbnail}
            >
              <source
                src={video?.video}
                // type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Card.Body>
          <Card.Footer className="border-0 pb-0 pt-0">
            <div className="btn-wrapper d-flex">
              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center px-3"
                onClick={() => {
                  onClickOnEditIcon(video);
                }}
                title="Edit"
              >
                <i className="fa fa-edit" style={{ fontSize: "2rem" }} />
              </Button>
              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center px-3"
                onClick={() => onClickOnDeleteIcon(video)}
                title="Delete"
              >
                <i className="fa fa-trash" style={{ fontSize: "2rem" }} />
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Col>
    </>
  );
};

export default VideoCard;
