import React, { useRef, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NoItemsFound from "../../pages/NoItemsFound";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";

const sort = 20;
const sorting = {};
const isNotShort = {
  action: true,
  isBlocked: true,
  profile: true,
  file: true,
  unit: true,
  color: true,
  variance: true,
  coverpage: true,
  image1: true,
  image2: true,
  image3: true,
  image4: true,
  image5: true,
  logo: true,
  introParagraphImage: true,
};

const MMTable = (props) => {
  const navigate = useNavigate();

  const { tableData, filter = "", noItemMsg, isShowFilter = false } = props;
  const [filterResult, setFilterResult] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [activePag, setActivePag] = useState(0);
  const [onClick, setOnClick] = useState(null);
  const [refreash, setRefreash] = useState(false);

  useEffect(() => {
    const alldata = tableData?.rows?.map((el) => el[filter]);
    setFilterResult([...new Set(alldata)]);
  }, [tableData]);

  useEffect(() => {
    setActivePag(0);
  }, [searchText]);

  const FilterData = tableData?.rows?.filter((el) => {
    if (searchText.trim() === "" || searchText === null) {
      return true;
    } else {
      const results = Object.values(el).map(
        (el) =>
          typeof el === "string" &&
          el?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())
      );

      return results.includes(true);
    }
  });

  if (onClick != null) {
    if (sorting[onClick]) {
      sorting[onClick] = false;
      FilterData.sort((a, b) =>
        a[onClick].localeCompare(b[onClick], "en", { numeric: true })
      );
    } else {
      sorting[onClick] = true;
      FilterData.sort((a, b) =>
        b[onClick].localeCompare(a[onClick], "en", { numeric: true })
      );
    }
  }

  let jobPagination = Array(Math.ceil(FilterData?.length / sort))
    .fill()
    ?.map((_, i) => i + 1);

  const jobData = useRef(
    FilterData?.slice(activePag * sort, (activePag + 1) * sort)
  );

  const handlerClickOnPagination = (i) => {
    setActivePag(i);

    FilterData?.slice(activePag * sort, (activePag + 1) * sort);
  };

  jobData.current = FilterData?.slice(activePag * sort, (activePag + 1) * sort);

  return (
    <div className="col-12 px-0">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title" style={{ width: "400px" }}>
            {isShowFilter && (
              <Autocomplete
                id="filter"
                freeSolo
                disableCloseOnSelect={false}
                className="w-100"
                options={filterResult}
                renderInput={(params) => (
                  <TextField {...params} label="Search Filter" />
                )}
                onChange={(e, value) => {
                  // setSearchText(value);
                }}
                onInputChange={(event, value) => {
                  setSearchText(value);
                }}
                getOptionLabel={(option) => option?.filterResult ?? option}
              />
            )}
          </h4>
        </div>
        <div className="card-body">
          {jobData?.current?.length ? (
            <Table responsive className="w-100 mb-0 mm-table">
              <div id="example_wrapper" className="dataTables_wrapper">
                <table id="example" className="display w-100 dataTable">
                  <thead className="border border-1">
                    <tr role="row">
                      {tableData?.columns?.map((column, i) => (
                        <th
                          key={i}
                          className="border border-1"
                          onClick={() => {
                            if (isNotShort[column.field]) {
                            } else {
                              setOnClick(() => `${column.field}`);
                              setRefreash((prev) => !prev);
                            }
                          }}
                        >
                          <div className="d-flex">
                            {column?.label}

                            {!sorting[onClick] && onClick == column.field && (
                              <i
                                className="fa fa-arrow-down  ms-2 fs-14"
                                style={{ opacity: "0.7" }}
                              />
                            )}

                            {sorting[onClick] && onClick == column.field && (
                              <i
                                className="fa fa-arrow-up ms-2 fs-14"
                                style={{ opacity: "0.7" }}
                              />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="border border-1">
                    {/* {jobData?.current?.map((row, i) => ( */}
                    {jobData?.current?.map((row, i) => (
                      <tr key={i} className="border border-1">
                        {Object.values(row)?.map((d, i) => (
                          <td key={i} className="border border-1">
                            {d}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="d-flex justify-content-between align-items-center border border-1 px-3 pt-2">
                  <div className="dataTables_info">
                    {`Showing ${activePag * sort + 1} to
                  ${
                    tableData?.rows?.length < (activePag + 1) * sort
                      ? tableData?.rows?.length
                      : (activePag + 1) * sort
                  }
                  of ${tableData?.rows?.length} entries`}
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers my-0">
                    <Pagination
                      className="pagination-primary pagination-circle pagination-md"
                      size="lg"
                    >
                      <li
                        className="page-item page-indicator "
                        onClick={() =>
                          activePag > 0 &&
                          handlerClickOnPagination(activePag - 1)
                        }
                      >
                        <Link className="page-link" to="#">
                          <i className="la la-angle-left" />
                        </Link>
                      </li>
                      {jobPagination?.map((number, i) => (
                        <Pagination.Item
                          key={number}
                          className={activePag == i ? "active" : ""}
                          onClick={() => handlerClickOnPagination(i)}
                        >
                          {number}
                        </Pagination.Item>
                      ))}
                      <li
                        className="page-item page-indicator"
                        onClick={() =>
                          activePag + 1 < jobPagination.length &&
                          handlerClickOnPagination(activePag + 1)
                        }
                      >
                        <Link className="page-link" to="#">
                          <i className="la la-angle-right" />
                        </Link>
                      </li>
                    </Pagination>
                  </div>
                </div>
              </div>
            </Table>
          ) : (
            <NoItemsFound message={noItemMsg} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MMTable;
