import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../../utils/constants";
import DeleteModal from "../../components/custom-modals/DeleteModal";
import FormModal from "../../components/custom-modals/FormModal";
import MMAddButton from "../../components/MMButtons/MMAddButton";
import MMTable from "../../components/table/MMTable";
import Loader from "../Loader/Loader";
import PageTitle from "../../layouts/PageTitle";

const getFormModalTitle = {
  add: "Add TechWindow",
  edit: "Edit TechWindow",
  delete: "Delete TechWindow",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addTechWindows,
  edit: CONSTANTS.FORMS.editTechWindows,
};

const TechWindowPage = () => {
  const getAllTechWindows = useHttp();
  const addTechWindows = useHttp();
  const editTechWindows = useHttp();
  const deleteTechWindows = useHttp();

  const isLoading = () => {
    return (
      getAllTechWindows.isLoading ||
      addTechWindows.isLoading ||
      editTechWindows.isLoading ||
      deleteTechWindows.isLoading
    );
  };

  const [techWindows, setTechWindows] = useState([]);
  const [techWindowData, setTechWindowData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curtechWindow, setCurtechWindow] = useState(null);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerClickOnEditIcon = (techWindow) => {
    setCurtechWindow(techWindow);
    setCurModal("edit");
  };

  const TechWindowsDataHandler = (techWindow) => {
    setTechWindowData(
      techWindow.map((techWindow, index) => {
        return {
          // userData: userData,
          no: `${index + 1}`,
          profile: (
            <div className="w-100 text-center">
              <img
                src={techWindow?.icon}
                style={{
                  width: "3rem",
                  aspectRatio: 1,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                className="rounded-circle"
              />
            </div>
          ),
          name: techWindow?.name,
          action: (
            <div className="d-flex justify-content-center">
              <Link
                to={`/tech-window/${techWindow?.id}`}
                state={{
                  title: techWindow?.name,
                }}
                className="btn btn-warning shadow btn-xs sharp mr-2"
                title="View"
              >
                <i className="fa fa-eye"></i>
              </Link>
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnEditIcon(techWindow)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnDeleteIcon(techWindow)}
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
      })
    );
  };

  const handlerGetAllTechWindows = useCallback((responseData) => {
    if (responseData.status === "success") {
      setTechWindows(responseData?.data?.rows);
      TechWindowsDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadTechWindows = () => {
    getAllTechWindows.sendRequest(
      CONSTANTS.URLS.getAllTechWindows,
      handlerGetAllTechWindows,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (techWindow) => {
    setCurtechWindow(techWindow);
    setCurModal("delete");
  };

  const handlerDeleteTechWindowsRes = useCallback((responseData) => {
    setCurtechWindow(null);
    setCurModal(null);
    loadTechWindows();
  }, []);

  const handlerDeleteTechWindow = (techWindowId) => {
    const deleteTechWindowURL = {
      type: "DELETE",
      endPoint: `/admin/tech/${techWindowId}`,
    };

    // make api call
    deleteTechWindows.sendRequest(
      deleteTechWindowURL,
      handlerDeleteTechWindowsRes,
      {},
      "Tech-Window deleted successfully."
    );
  };

  const handlerAddTechWindowRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurtechWindow(null);
      setCurModal(null);
      loadTechWindows();
    }
  }, []);

  const handlerSubmitAddTechWindow = (payload) => {
    addTechWindows.sendRequest(
      CONSTANTS.URLS.addTechWindows,
      handlerAddTechWindowRes,
      payload,
      "Tech-Window added successfully."
    );
  };

  const handlerEditTechWindowRes = useCallback((responseData) => {
    setCurModal(null);
    setCurtechWindow(null);
    loadTechWindows();
  }, []);

  const handlerEditTechWindow = (techWindowId, payload) => {
    const editTechWindowURL = {
      type: "PATCH",
      endPoint: `/admin/tech/${techWindowId}`,
    };

    // make api call
    editTechWindows.sendRequest(
      editTechWindowURL,
      handlerEditTechWindowRes,
      payload,
      "Tech-Window updated successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    console.log(payload);
    if (curModal === "add") {
      handlerSubmitAddTechWindow(payload);
    } else {
      handlerEditTechWindow(curtechWindow?.id, payload);
    }
  };

  useEffect(() => {
    loadTechWindows();
  }, []);

  useEffect(() => {
    if (curModal !== "delete") {
      const selectElements = document.getElementById("type");

      if (selectElements) {
        selectElements.classList.add("form-control");
      }
    }
  }, [curModal]);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this Tech-Window?"}
        desc={curtechWindow?.title}
        onDelete={() => handlerDeleteTechWindow(curtechWindow?.id)}
      />
      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                name: curtechWindow?.name,
                icon: curtechWindow?.icon,
                type: curtechWindow?.type,
              }
            : {}
        }
      />
      <PageTitle
        pageHeading="Tech Window"
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />
      <MMTable
        title="Tech Window"
        tableData={{
          columns: getTableData("techWindow")["columns"],
          rows: techWindowData,
        }}
        isShowFilter={true}
        filter="name"
      />
    </Fragment>
  );
};

export default TechWindowPage;
