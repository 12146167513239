import { Button, Card, Col } from "react-bootstrap";
import { handlerCopyToClipBoard } from "../../../utils/funcs";
import CONSTANTS from "../../../utils/constants";
import { Link } from "@mui/material";

const Magazine = (props) => {
  const { magazine, index, onClickOnDeleteIcon, onClickOnEditIcon } = props;

  return (
    <>
      <Col lg="4" xs="12" sm="6">
        <Card>
          <Card.Header className=" border-0 pb-0">
            <div className="d-flex justify-content-between align-items-center w-100">
              <Card.Title
                style={{ wordWrap: "break-word", overflow: "hidden" }}
                className="text-nowrap"
              >
                {magazine?.title}
              </Card.Title>
            </div>
          </Card.Header>
          <Card.Body className=" border-0 pb-0">
            <img
              // className="img-fluid"
              style={{ aspectRatio: 16 / 9 }}
              src={magazine?.coverPhoto || CONSTANTS.imgPlaceHolder}
              alt="Webpage preview"
              className="w-100"
            />
          </Card.Body>
          <Card.Footer className="border-0 pt-4">
            <div className="btn-wrapper d-flex">
              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center px-3"
                title="Download"
              >
                <a href={magazine?.file}>
                  <i className="fa fa-download" style={{ fontSize: "2rem" }} />
                </a>
              </Button>

              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center px-3"
                onClick={() => {
                  onClickOnEditIcon(magazine);
                }}
                title="Edit"
              >
                <i className="fa fa-edit" style={{ fontSize: "2rem" }} />
              </Button>
              <Button
                variant="btn text-primary outline-primary d-flex justify-content-center align-items-center px-3"
                onClick={() => onClickOnDeleteIcon(magazine)}
                title="Delete"
              >
                <i className="fa fa-trash" style={{ fontSize: "2rem" }} />
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Col>
    </>
  );
};

export default Magazine;
