import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../utils/constants";
import DeleteModal from "../components/custom-modals/DeleteModal";
import MMTable from "../components/table/MMTable";
import Loader from "./Loader/Loader";
import PageTitle from "../layouts/PageTitle";
import { Button, Card, Modal } from "react-bootstrap";
import moment from "moment";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";

const UsersPage = () => {
  const getAllUsers = useHttp();
  const deleteUser = useHttp();
  const isloginUser = useHttp();
  const toggleUserBlockage = useHttp();

  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curUser, setCurUser] = useState(null);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerToggleUserBlockageRes = useCallback((responseData) => {
    loadUsers();
  }, []);

  const handlerToggleUserBlockage = (userId, status) => {
    const toggleUserblockageURL = {
      type: "POST",
      endPoint: `/admin/users/block/${userId}`,
    };

    let payload = {
      isBlocked: status,
    };

    // make api call
    toggleUserBlockage.sendRequest(
      toggleUserblockageURL,
      handlerToggleUserBlockageRes,
      payload,
      `User ${status ? "blocked" : "Unblocked"} updated successfully.`
    );
  };
  console.log(moment().format("YYYY-MM-DD"));
  const statusCheck = (userdata) => {
    if (
      !userdata?.subscriptions.length ||
      moment(userdata?.subscriptions?.expireDate).isBefore(moment(), "day")
    ) {
      return "Unpaid";
    } else {
      console.log(
        userdata?.subscriptions.length,
        moment(userdata?.subscriptions?.expireDate).isBefore(moment(), "day")
      );
      return "Paid";
    }
  };

  const userDataHandler = (users) => {
    setUserData(
      users.map((userData, index) => {
        return {
          // userData: userData,
          no: `${index + 1}`,
          profile: (
            <div className="w-100 text-center">
              <img
                src={userData?.profile}
                style={{
                  width: "3rem",
                  aspectRatio: 1,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                className="rounded-circle"
              />
            </div>
          ),
          name: userData?.name,
          email: userData?.email,
          phone: userData?.mobile,
          status: statusCheck(userData),
          // Allocated: <AllocateAndNotAllocate value={vehicleData?.allocate} />,
          // transporterName: vehicleData?.transporter?.transporterName,
          isBlocked: (
            <div className="d-flex justify-content-center mm-switch">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={userData?.isBlocked}
                  onChange={(e) =>
                    handlerToggleUserBlockage(userData?.id, e.target.checked)
                  }
                />
                <span className="slider round"></span>
              </label>
            </div>
          ),
          action: (
            <div className="d-flex justify-content-center">
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnLogInIcon(userData)}
                title="Lock"
              >
                <i className="fa fa-lock"></i>
              </Link>
              <Link
                to={`/users-profile/${userData?.id}`}
                className="btn btn-info shadow btn-xs sharp mr-2"
                title="View"
              >
                <i className="fa fa-eye"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnDeleteIcon(userData)}
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
      })
    );
  };

  const handlerGetAllUsers = useCallback((responseData) => {
    if (responseData.status === "success") {
      console.log(responseData?.data);
      setUsers(responseData?.data?.users);
      userDataHandler(responseData?.data?.users);
    }
  }, []);

  const loadUsers = () => {
    getAllUsers.sendRequest(CONSTANTS.URLS.getAllUsers, handlerGetAllUsers, {});
  };

  const handlerClickOnDeleteIcon = (user) => {
    setCurUser(user);
    setCurModal("delete");
  };

  const handlerClickOnLogInIcon = (user) => {
    setCurUser(user);
    setCurModal("logIn");
  };

  const handlerDeleteUserRes = useCallback((responseData) => {
    setCurUser(null);
    setCurModal(null);
    loadUsers();
  }, []);

  const handlerDeleteUser = (userId) => {
    const deleteUserURL = {
      type: "DELETE",
      endPoint: `/admin/users/${userId}`,
    };

    // make api call
    deleteUser.sendRequest(
      deleteUserURL,
      handlerDeleteUserRes,
      {},
      "User deleted successfully."
    );
  };
  const handlerLoginUser = (userId, islogin) => {
    const loginUserURL = {
      type: "POST",
      endPoint: `/admin/users/islogin/${userId}`,
    };

    let payload = {
      isLoggedIn: "false",
    };

    // make api call
    isloginUser.sendRequest(
      loginUserURL,
      handlerToggleUserBlockageRes,
      payload,
      `User ${islogin ? "login" : "logout"} updated successfully.`
    );
    setCurModal(null);
  };
  useEffect(() => {
    loadUsers();
  }, []);

  const isLoading = () => {
    return (
      getAllUsers.isLoading ||
      deleteUser.isLoading ||
      toggleUserBlockage.isLoading ||
      isloginUser.isLoading
    );
  };

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <PageTitle pageHeading="Users" />
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this user?"}
        desc={curUser?.name}
        onDelete={() => handlerDeleteUser(curUser?.id)}
      />

      <Modal className="fade" show={curModal === "logIn"}>
        <Modal.Header>
          <Modal.Title>Confirm Logout</Modal.Title>
          <Button variant="" className="close" onClick={handlerCloseModal}>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Card.Text className="">
            {"Are you sure to logout this user?"}
          </Card.Text>
          <br />
          <Card.Link className="mt-2">{curUser?.name}</Card.Link>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handlerCloseModal} variant="danger light">
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => handlerLoginUser(curUser?.id, curUser?.isLoggedIn)}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <MMTable
        title="Users"
        noItemMsg="No users found."
        tableData={{
          columns: getTableData("users")["columns"],
          rows: userData,
        }}
        isShowFilter={true}
      />
    </Fragment>
  );
};

export default UsersPage;
