import { lazy, Suspense, useState } from "react";
/// Components
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Index from "./jsx/index";
// action
/// Style
import "./scss/main.scss";
import authStorage from "./utils/API/authStroge";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "react-toastify/dist/ReactToastify.css";

// const SignUp = lazy(() => import("./jsx/pages/Registration"));
// const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App(props) {
  const [toggleSidebar, setToggleSidebar] = useState(window.innerWidth >= 768);

  const handlerToggleNavbar = () => {
    setToggleSidebar((prev) => !prev);
  };

  let routes = (
    <Routes>
      <Route path="/login" element={<Login />} />
      {/* <Route path="/page-register" element={<SignUp />} /> */}
      {/* <Route path="/page-forgot-password" element={<ForgotPassword />} /> */}
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );

  const isLogin =
    authStorage.getAuthToken() !== undefined &&
    authStorage.getAuthToken() !== null;

  if (isLogin) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index
            toggleSidebar={toggleSidebar}
            onToggleNavbar={handlerToggleNavbar}
          />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <img src="./images/logo.svg" alt="" />
              {/* <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div> */}
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

export default App;
