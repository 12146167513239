import React from "react";
import { Link } from "react-router-dom";
import MMAddButton from "../components/MMButtons/MMAddButton";

const PageTitle = (props) => {
  const { pageHeading, buttons, backNavPath, withBackButton } = props;

  return (
    <div className="page-titles bg-white m-0 mb-3 rounded d-flex align-items-center  p-2 pl-3">
      {withBackButton && (
        <Link to={backNavPath || -1}>
          <i
            className="fa fa-arrow-left text-primary mr-3"
            style={{ fontSize: "1.4rem" }}
            aria-hidden="true"
          ></i>
        </Link>
      )}
      <h4 className="mb-0">{pageHeading}</h4>

      <div className="ml-auto d-flex" style={{ gap: "0.5rem" }}>
        {buttons}
      </div>
    </div>
  );
};

export default PageTitle;
