import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../../utils/constants";
import DeleteModal from "../../components/custom-modals/DeleteModal";
import FormModal from "../../components/custom-modals/FormModal";
import MMAddButton from "../../components/MMButtons/MMAddButton";
import MMTable from "../../components/table/MMTable";
import Loader from "../Loader/Loader";
import PageTitle from "../../layouts/PageTitle";
import { Services } from "../../../services/service";
import { Input, Label, Row, Form } from "reactstrap";
import { Autocomplete, TextField } from "@mui/material";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { notify } from "../../../utils/funcs";
import BulkLoader from "../Loader/BulkLoader";

const getFormInputList = {
  add: CONSTANTS.FORMS.addTechAnswer,
  // add1: CONSTANTS.FORMS.addMinGuideCircular1,
  edit: CONSTANTS.FORMS.addTechAnswer,
  // edit1: CONSTANTS.FORMS.editMinGuideCircular1,
};

const TechUserAnswerList = () => {
  const params = useParams();
  const location = useLocation();
  // console.log(params?.tecWindowId);
  const getTechAnswers = useHttp();
  const addTechAnswer = useHttp();
  const editTechAnswer = useHttp();
  const deleteTechAnswer = useHttp();
  //   const getAllTechWindows = useHttp();

  const isLoading = () => {
    return (
      getTechAnswers.isLoading ||
      addTechAnswer.isLoading ||
      editTechAnswer.isLoading ||
      deleteTechAnswer.isLoading
      //   getAllTechWindows.isLoading
      // getTechQuestionType.isLoading
    );
  };

  const [TechAnswers, setTechAnswers] = useState([]);
  const [TechAnswersData, setTechAnswersData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curTechAnswer, setCurTechAnswer] = useState(null);
  const [state, setstate] = useState(location?.state);
  const [loader, setLoader] = useState(false);
  //   const [optionTech, setOptionTech] = useState([]);
  console.log(state, "STATE");
  //   console.log(params, "jfdgjd");
  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const getFormModalTitle = {
    add: "Add Tech Answer",
    edit: "Edit Tech Answer",
    delete: "Delete Tech Answer",
  };

  //   useEffect(() => {
  //     getAllTechWindows.sendRequest(
  //       CONSTANTS.URLS.getAllTechWindows,
  //       (res) => {
  //         // console.log(res);
  //         res?.data?.rows?.map((item) => {
  //           setOptionTech([
  //             ...optionTech,
  //             { label: item?.name, value: item?.id },
  //           ]);
  //         });
  //       },
  //       {}
  //     );
  //   }, []);

  const handlerClickOnEditIcon = (techAnswer) => {
    setCurTechAnswer(techAnswer);
    setCurModal("edit");
  };

  const TechAnswersDataHandler = (techAnswers) => {
    const alldata = techAnswers?.map((el) => el?.date);

    setTechAnswersData(
      techAnswers.map((techAnswer, index) => {
        // console.log(techAnswer?.date);
        return {
          // userData: userData,
          no: `${index + 1}`,
          //   name: techAnswer?.user?.name,
          answer: techAnswer?.answer,

          //   action: (
          //     <div className="d-flex justify-content-center">
          //       {/* <Link
          //         to={`/smp-osh/${techAnswer?.id}`}
          //         state={{
          //           title: techAnswer?.name,
          //         }}
          //         className="btn btn-warning shadow btn-xs sharp mr-2"
          //         title="View"
          //       >
          //         <i className="fa fa-eye"></i>
          //       </Link> */}
          //       <Link
          //         className="btn btn-info shadow btn-xs sharp mr-2"
          //         title="Edit"
          //         onClick={() => handlerClickOnEditIcon(techAnswer)}
          //       >
          //         <i className="fa fa-pencil"></i>
          //       </Link>
          //       <Link
          //         className="btn btn-danger shadow btn-xs sharp mr-2"
          //         title="Delete"
          //         onClick={() => handlerClickOnDeleteIcon(techAnswer)}
          //       >
          //         <i className="fa fa-trash"></i>
          //       </Link>
          //     </div>
          //   ),
        };
      })
    );
  };

  const handlerGetAllTechAnswers = useCallback((responseData) => {
    if (responseData.status === "success") {
      setTechAnswers(responseData?.data?.rows);
      TechAnswersDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadTechAnswers = () => {
    const getTechAnswerURL = {
      type: CONSTANTS.URLS.getAllTechAnswers.type,
      endPoint: `/admin/techanswers?userId=${state?.id}`,
    };
    getTechAnswers.sendRequest(getTechAnswerURL, handlerGetAllTechAnswers, {});
  };

  const handlerClickOnDeleteIcon = (techAnswer) => {
    setCurTechAnswer(techAnswer);
    setCurModal("delete");
  };

  const handlerDeleteTechAnswerRes = useCallback((responseData) => {
    setCurTechAnswer(null);
    setCurModal(null);
    loadTechAnswers();
  }, []);

  const handlerDeleteTechAnswer = (techWindowId) => {
    const deleteTechQuestionURL = {
      type: "DELETE",
      endPoint: `/admin/techanswers/${techWindowId}`,
    };

    // make api call
    deleteTechAnswer.sendRequest(
      deleteTechQuestionURL,
      handlerDeleteTechAnswerRes,
      {},
      "TechAnswer deleted successfully."
    );
  };

  const handlerAddTechAnswerRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurTechAnswer(null);
      setCurModal(null);
      loadTechAnswers();
    }
  }, []);

  const handlerSubmitAddTechAnswer = (payload) => {
    // payload.append("ministryGuideId", params?.guidelineId);
    // payload.append("type", state?.type);
    // console.log(payload);
    // debugger;
    addTechAnswer.sendRequest(
      CONSTANTS.URLS.addTechAnswer,
      handlerAddTechAnswerRes,
      payload,
      "TechAnswer added successfully."
    );
  };

  const handlerEditTechAnswerRes = useCallback((responseData) => {
    setCurModal(null);
    setCurTechAnswer(null);
    loadTechAnswers();
  }, []);

  const handlerEditTechAnswer = (techWindowId, payload) => {
    const editTechQuestionURL = {
      type: "PATCH",
      endPoint: `/admin/techanswers/`,
    };

    // make api call
    editTechAnswer.sendRequest(
      editTechQuestionURL,
      handlerEditTechAnswerRes,
      payload,
      "TechAnswer updated successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    if (curModal === "add") {
      handlerSubmitAddTechAnswer(payload);
    } else {
      handlerEditTechAnswer(curTechAnswer?.id, payload);
    }
  };

  useEffect(() => {
    loadTechAnswers();
  }, []);

  useEffect(() => {
    if (curModal !== "delete") {
      const selectElements = document.getElementById("type");

      if (selectElements) {
        selectElements.classList.add("form-control");
      }
    }
  }, [curModal]);
  //   console.log(curTechAnswer);
  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this Tech-Answer?"}
        desc={curTechAnswer?.answer}
        onDelete={() => handlerDeleteTechAnswer(curTechAnswer?.id)}
      />

      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                answer: curTechAnswer?.answer,
              }
            : {}
        }
      />

      <PageTitle
        pageHeading={`${state?.title} AnswerList`}

        // buttons={[
        //   <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        // ]}
      />

      <MMTable
        title="AnswerList"
        toggle={() => {
          setCurModal(null);
        }}
        filter={"answer"}
        isShowFilter={true}
        tableData={{
          columns: getTableData("userAnswerList")["columns"],
          rows: TechAnswersData,
        }}
      />
    </Fragment>
  );
};

export default TechUserAnswerList;
