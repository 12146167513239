import { Button, Card, Modal } from "react-bootstrap";

const DeleteModal = (props) => {
  const { isOpen, onClose, title, desc, onDelete } = props;

  return (
    <Modal className="fade" show={isOpen}>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
        <Button variant="" className="close" onClick={onClose}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Card.Text className="">{title}</Card.Text>
        <br />
        <Card.Link className="mt-2">{desc}</Card.Link>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant="danger light">
          No
        </Button>
        <Button variant="primary" onClick={onDelete}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
