import React from "react";
import { Link } from "react-router-dom";
import comingSoonSVG from "./../../images/coming-soon.svg";

const ComingSoon = ({ inside }) => {
  return (
    <div
      className={`authincation h-100 ${inside ? "" : "p-meddle"}`}
      style={inside ? { minHeight: "unset" } : {}}
    >
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-8">
            <div className="form-input-content text-center error-page">
              {/* <h1 className="error-text font-weight-bold">Coming Soon</h1> */}
              <img src={comingSoonSVG} alt="" className="mb-5 w-50" />
              <h4>
                <i className="fa fa-exclamation-triangle text-warning" /> The
                page you were looking for is Under development!
              </h4>
              <div>
                <Link className="btn btn-primary" to="/dashboard">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
