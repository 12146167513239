const CONSTANTS = {
  // baseURL: "http://43.204.56.177/api/v1", // stagging
  // baseURL: "http://15.206.61.205/api/v1", // production
  baseURL: "https://magmaapi.minemagma.com/api/v1", // production
  URLS: {
    signIn: {
      type: "POST",
      endPoint: "/admins/login",
    },
    getMyProfile: {
      type: "GET",
      endPoint: "/admins/me",
    },
    getAllLinks: {
      type: "GET",
      endPoint: "/admin/links",
    },
    addLink: {
      type: "POST",
      endPoint: "/admin/links",
    },
    getAllFAQs: {
      type: "GET",
      endPoint: "/admin/faq",
    },
    addFaq: {
      type: "POST",
      endPoint: "/admin/faq",
    },
    getAllUsers: {
      type: "GET",
      endPoint: "/admin/users",
    },
    getAllMagazines: {
      type: "GET",
      endPoint: "/admin/magazine",
    },
    addMagazine: {
      type: "POST",
      endPoint: "/admin/magazine",
    },
    getAllVideos: {
      type: "GET",
      endPoint: "/admin/video",
    },
    addVideo: {
      type: "POST",
      endPoint: "/admin/video",
    },
    getAllCourtOrders: {
      type: "GET",
      endPoint: "/admin/courtorders",
    },
    addCourtOrder: {
      type: "POST",
      endPoint: "/admin/courtorders",
    },
    addCourtOrderInfoItem: {
      type: "POST",
      endPoint: "/admin/courtordersinfo",
    },
    getAllTechWindows: {
      type: "GET",
      endPoint: "/admin/tech",
    },
    addTechWindows: {
      type: "POST",
      endPoint: "/admin/tech",
    },
    getAllTechQuestion: {
      type: "GET",
      endPoint: "/admin/techquestions",
    },
    addTechQuestion: {
      type: "POST",
      endPoint: "/admin/techquestions",
    },
    getAllTechAnswers: {
      type: "GET",
      endPoint: "/admin/techanswers",
    },
    // addTechAnswers: {
    //   type: "POST",
    //   endPoint: "/admin/techquestions",
    // },

    getAllMinistryGuidelines: {
      type: "GET",
      endPoint: "/admin/ministryguide",
    },
    addMinistryGuideline: {
      type: "POST",
      endPoint: "/admin/ministryguide",
    },
    addMinGuideCircular: {
      type: "POST",
      endPoint: "/admin/ministryguidecircular",
    },
    addMinGuideCircularInfoItem: {
      type: "POST",
      endPoint: "/admin/ministryguidecircularinfo",
    },
    getAllSubscriptions: {
      type: "GET",
      endPoint: "/admin/subscriptions",
    },
    getAllAppConfigs: {
      type: "GET",
      endPoint: "/admin/appconfig",
    },
    addAppConfig: {
      type: "POST",
      endPoint: "/admin/appconfig",
    },
    getAllPromoters: {
      type: "GET",
      endPoint: "/admin/promoters",
    },
    addPromoter: {
      type: "POST",
      endPoint: "/admin/promoters",
    },
    getAllMetals: {
      type: "GET",
      endPoint: "/admin/market",
    },
    addMetal: {
      type: "POST",
      endPoint: "/admin/market",
    },
    addNotification: {
      type: "POST",
      endPoint: "/admin/notification",
    },
    getAnalysis: {
      type: "GET",
      endPoint: "/admin/analytics/users",
    },
    getAllBanners: {
      type: "GET",
      endPoint: "/admin/banner",
    },
    addBanner: {
      type: "POST",
      endPoint: "/admin/banner",
    },
  },
  FORMS: {
    addTechQuestion: [
      {
        Label: "Select Type",
        input: {
          id: "type",
          type: "select",
          required: true,
          className: "form-control",
        },
        options: [
          {
            id: 0,
            value: "1",
            label: "One",
          },
          {
            id: 1,
            value: "2",
            label: "two",
          },
        ],
        validation: "notEmpty",
        errorMessage: "Please select type",
        containerClassName: "form-group",
      },
      {
        Label: "Question",
        input: {
          id: "question",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Question",
      },
    ],
    addTechAnswer: [
      {
        Label: "Answer",
        input: {
          id: "answer",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Answer",
      },
    ],
    addLink: [
      {
        Label: "Title",
        input: {
          id: "title",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Title",
      },
      {
        Label: "Link",
        input: {
          id: "link",
          type: "url",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Link",
      },
      {
        Label: "Image",
        input: {
          id: "photo",
          type: "file",
          required: true,
        },
      },
    ],
    editLink: [
      {
        Label: "Title",
        input: {
          id: "title",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Title",
      },
      {
        Label: "Link",
        input: {
          id: "link",
          type: "url",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Link",
      },
      {
        Label: "Image",
        input: {
          id: "photo",
          type: "file",
        },
      },
    ],
    addFaq: [
      {
        Label: "Question",
        input: {
          id: "title",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Question",
      },
      {
        Label: "Answer",
        input: {
          id: "body",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Answer",
      },
    ],
    editFaq: [
      {
        Label: "Question",
        input: {
          id: "title",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Question",
      },
      {
        Label: "Answer",
        input: {
          id: "body",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Answer",
      },
    ],
    addMagazine: [
      {
        Label: "Title",
        input: {
          id: "title",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Title",
      },
      {
        Label: "Cover Image",
        input: {
          id: "coverPhoto",
          type: "file",
          accept: "image/*",
          required: true,
        },
      },
      {
        Label: "File",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
          required: true,
        },
      },
    ],
    editMagazine: [
      {
        Label: "Title",
        input: {
          id: "title",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Title",
      },
      {
        Label: "Cover Image",
        input: {
          id: "coverPhoto",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "File",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
        },
      },
    ],
    addVideo: [
      {
        Label: "Title",
        input: {
          id: "title",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Title",
      },
      {
        Label: "Video",
        input: {
          id: "video",
          type: "file",
          accept: "video/*",
          required: true,
        },
      },
      {
        Label: "Thumbnail",
        input: {
          id: "thumbnail",
          type: "file",
          accept: "image/*",
          required: false,
        },
      },
      // {
      //   Label: "Duration",
      //   input: {
      //     id: "duration",
      //     type: "text",
      //     required: true,
      //   },
      //   validation: "notEmpty",
      //   errorMessage: "Please Enter Duration",
      // },
    ],
    addBulkVideo: [
      {
        Label: "Video",
        input: {
          id: "video",
          type: "file",
          accept: "video/*",
          required: true,
          multiple: true,
        },
      },
    ],
    editVideo: [
      {
        Label: "Title",
        input: {
          id: "title",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Title",
      },
      {
        Label: "Video",
        input: {
          id: "video",
          type: "file",
          accept: "video/*",
        },
      },
      {
        Label: "Thumbnail",
        input: {
          id: "thumbnail",
          type: "file",
          accept: "image/*",
          required: true,
        },
      },
      // {
      //   Label: "Duration",
      //   input: {
      //     id: "duration",
      //     type: "text",
      //   },
      //   validation: "notEmpty",
      // },
    ],
    addCourtOrder: [
      {
        Label: "Title",
        input: {
          id: "title",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Title",
      },
      {
        Label: "Description",
        input: {
          id: "desc",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Description",
      },
    ],
    editCourtOrder: [
      {
        Label: "Title",
        input: {
          id: "title",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Title",
      },
      {
        Label: "Description",
        input: {
          id: "desc",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Description",
      },
    ],
    addCourtOrderInfoItem: [
      {
        Label: "Date",
        input: {
          id: "date",
          type: "date",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Date",
      },
      {
        Label: "File",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
          required: true,
        },
      },
    ],
    editCourtOrderInfoItem: [
      {
        Label: "Date",
        input: {
          id: "date",
          type: "date",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Date",
      },
      {
        Label: "File",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
        },
      },
    ],
    addCourtOrderInfoItemInBulk: [
      {
        Label: "Files",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
          required: true,
          multiple: true,
        },
      },
    ],
    addTechWindows: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Tech Name",
      },
      {
        Label: "Icon",
        input: {
          id: "icon",
          type: "file",
          accept: "image/*",
          required: true,
        },
      },
    ],
    editTechWindows: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Guideline Name",
      },
      {
        Label: "Icon",
        input: {
          id: "icon",
          type: "file",
          accept: "image/*",
        },
      },
    ],
    addMinistryGuideline: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Guideline Name",
      },
      {
        Label: "Icon",
        input: {
          id: "icon",
          type: "file",
          accept: "image/*",
          required: true,
        },
      },
      {
        Label: "Select Type",
        input: {
          id: "type",
          type: "select",
          required: true,
          className: "form-control",
        },
        options: [
          {
            id: 0,
            value: "1",
            label: "One",
          },
          {
            id: 1,
            value: "2",
            label: "two",
          },
        ],
        validation: "notEmpty",
        errorMessage: "Please select type",
        containerClassName: "form-group",
      },
    ],
    editMinistryGuideline: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Guideline Name",
      },
      {
        Label: "Icon",
        input: {
          id: "icon",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Select Type",
        input: {
          id: "type",
          type: "select",
          required: true,
          className: "form-control",
        },
        options: [
          {
            id: 0,
            value: "1",
            label: "One",
          },
          {
            id: 1,
            value: "2",
            label: "two",
          },
        ],
        validation: "notEmpty",
        errorMessage: "Please select type",
        containerClassName: "form-group",
      },
    ],
    addMinGuideCircular: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Guideline Name",
      },
      {
        Label: "Index",
        input: {
          id: "index",
          type: "number",
          min: 0,
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Index",
      },
      {
        Label: "File",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
          required: true,
        },
      },
    ],
    addMinGuideCircular1: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Guideline Name",
      },
    ],
    addMinGuideCircularnBulk: [
      {
        Label: "Files",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
          required: true,
          multiple: true,
        },
      },
    ],
    editMinGuideCircular1: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Guideline Name",
      },
    ],
    editMinGuideCircular: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Guideline Name",
      },
      {
        Label: "Index",
        input: {
          id: "index",
          type: "number",
          min: 0,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Index",
      },
      {
        Label: "File",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
        },
      },
    ],
    addMinGuideCircularInfoItem: [
      {
        Label: "File",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
          required: true,
        },
      },
      {
        Label: "File Name",
        input: {
          id: "fileName",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter File Name",
      },
      {
        Label: "Index",
        input: {
          id: "index",
          type: "number",
          required: true,
          min: 1,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Index",
      },
      {
        Label: "Filter",
        input: {
          id: "filter",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter filter",
      },
    ],
    editMinGuideCircularInfoItem: [
      {
        Label: "File",
        input: {
          id: "file",
          type: "file",
          accept: "application/pdf",
        },
      },
      {
        Label: "File Name",
        input: {
          id: "fileName",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter File Name",
      },
      {
        Label: "Index",
        input: {
          id: "index",
          type: "number",
          min: 1,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Index",
      },
      {
        Label: "Filter",
        input: {
          id: "filter",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter filter",
      },
    ],
    addAppConfig: [
      {
        Label: "Android Version",
        input: {
          id: "androidVersion",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Android Version",
      },
      {
        Label: "ios Version",
        input: {
          id: "iosVersion",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter ios Version",
      },
      {
        Label: "Is app under maintenance?",
        input: {
          id: "appUnderMaintenance",
          type: "select",
          required: true,
          className: "form-control",
        },
        options: [
          {
            id: 0,
            value: true,
            label: "Yes",
          },
          {
            id: 1,
            value: false,
            label: "No",
          },
        ],
        validation: "notEmpty",
        errorMessage: "Please select maintenance status",
        containerClassName: "form-group",
      },
      {
        Label: "Is force update enable?",
        input: {
          id: "forceUpdate",
          type: "select",
          required: true,
          className: "form-control",
        },
        options: [
          {
            id: 0,
            value: true,
            label: "Yes",
          },
          {
            id: 1,
            value: false,
            label: "No",
          },
        ],
        validation: "notEmpty",
        errorMessage: "Please select maintenance status",
        containerClassName: "form-group",
      },
      {
        Label: "Is soft update enable?",
        input: {
          id: "softUpdate",
          type: "select",
          required: true,
          className: "form-control",
        },
        options: [
          {
            id: 0,
            value: true,
            label: "Yes",
          },
          {
            id: 1,
            value: false,
            label: "No",
          },
        ],
        validation: "notEmpty",
        errorMessage: "Please select maintenance status",
        containerClassName: "form-group",
      },
    ],
    editAppConfig: [
      {
        Label: "Android Version",
        input: {
          id: "androidVersion",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Android Version",
      },
      {
        Label: "ios Version",
        input: {
          id: "iosVersion",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter ios Version",
      },
      {
        Label: "Is app under maintenance?",
        input: {
          id: "appUnderMaintenance",
          type: "select",

          className: "form-control",
        },
        options: [
          {
            id: 0,
            value: true,
            label: "Yes",
          },
          {
            id: 1,
            value: false,
            label: "No",
          },
        ],
        validation: "notEmpty",
        errorMessage: "Please select maintenance status",
        containerClassName: "form-group",
      },
      {
        Label: "Is force update enable?",
        input: {
          id: "forceUpdate",
          type: "select",
          className: "form-control",
        },
        options: [
          {
            id: 0,
            value: true,
            label: "Yes",
          },
          {
            id: 1,
            value: false,
            label: "No",
          },
        ],
        validation: "notEmpty",
        errorMessage: "Please select maintenance status",
        containerClassName: "form-group",
      },
      {
        Label: "Is soft update enable?",
        input: {
          id: "softUpdate",
          type: "select",
          className: "form-control",
        },
        options: [
          {
            id: 0,
            value: true,
            label: "Yes",
          },
          {
            id: 1,
            value: false,
            label: "No",
          },
        ],
        validation: "notEmpty",
        errorMessage: "Please select maintenance status",
        containerClassName: "form-group",
      },
    ],
    addPromoter: [
      {
        Label: "Logo",
        input: {
          id: "logo",
          type: "file",
          accept: "image/*",
          required: true,
        },
      },
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Name",
      },
      {
        Label: "Tagline",
        input: {
          id: "tagLine",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Tagline",
      },
      {
        Label: "Coverpage",
        input: {
          id: "cover",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Service 1",
        input: {
          id: "service1",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 1",
      },
      {
        Label: "Service 2",
        input: {
          id: "service2",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 2",
      },
      {
        Label: "Service 3",
        input: {
          id: "service3",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 3",
      },

      {
        Label: "Service 4",
        input: {
          id: "service4",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 4",
      },
      {
        Label: "Service 5",
        input: {
          id: "service5",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 5",
      },
      {
        Label: "Intro Paragraph 1",
        input: {
          id: "intro1",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Intro-Paragraph",
      },
      {
        Label: "Intro Paragraph 2",
        input: {
          id: "intro2",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Intro-Paragraph",
      },
      {
        Label: "Intro Paragraph 3",
        input: {
          id: "intro3",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Intro-Paragraph",
      },
      {
        Label: "Intro paragraph image (138px X 135px)",
        input: {
          id: "introImg",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Quote 1",
        input: {
          id: "quote1",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Quote 1",
      },
      {
        Label: "Quote 2",
        input: {
          id: "quote2",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Quote 2",
      },
      {
        Label: "Quote 3",
        input: {
          id: "quote3",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Quote 3",
      },
      {
        Label: "Quote Message",
        input: {
          id: "quoteDesc",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Quote Message",
      },
      {
        Label: "Image 1",
        input: {
          id: "image1",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Image 2",
        input: {
          id: "image2",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Image 3",
        input: {
          id: "image3",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Image 4",
        input: {
          id: "image4",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Image 5",
        input: {
          id: "image5",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Ranking",
        input: {
          id: "index",
          type: "number",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Ranking Number",
      },
    ],
    editPromoter: [
      {
        Label: "Logo",
        input: {
          id: "logo",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Name",
      },
      {
        Label: "Tagline",
        input: {
          id: "tagLine",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Tagline",
      },
      {
        Label: "Coverpage",
        input: {
          id: "cover",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Service 1",
        input: {
          id: "service1",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 1",
      },
      {
        Label: "Service 2",
        input: {
          id: "service2",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 2",
      },
      {
        Label: "Service 3",
        input: {
          id: "service3",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 3",
      },
      {
        Label: "Service 4",
        input: {
          id: "service4",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 4",
      },

      {
        Label: "Service 5",
        input: {
          id: "service5",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Service 5",
      },
      {
        Label: "Intro Paragraph 1",
        input: {
          id: "intro1",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Intro-Paragraph 1",
      },
      {
        Label: "Intro Paragraph 2",
        input: {
          id: "intro2",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Intro-Paragraph 2",
      },
      {
        Label: "Intro Paragraph 3",
        input: {
          id: "intro3",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Intro-Paragraph 3",
      },
      {
        Label: "Intro paragraph image (138px X 135px)",
        input: {
          id: "introImg",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Quote 1",
        input: {
          id: "quote1",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Quote 1",
      },
      {
        Label: "Quote 2",
        input: {
          id: "quote2",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Quote 2",
      },
      {
        Label: "Quote 3",
        input: {
          id: "quote3",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Quote 3",
      },
      {
        Label: "Quote Message",
        input: {
          id: "quoteDesc",
          type: "textarea",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Quote Message",
      },
      {
        Label: "Image 1",
        input: {
          id: "image1",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Image 2",
        input: {
          id: "image2",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Image 3",
        input: {
          id: "image3",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Image 4",
        input: {
          id: "image4",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Image 5",
        input: {
          id: "image5",
          type: "file",
          accept: "image/*",
        },
      },
      {
        Label: "Ranking",
        input: {
          id: "index",
          type: "number",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Ranking Number",
      },
    ],
    addMetal: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Metal Name",
      },
      {
        Label: "Symbol",
        input: {
          id: "symbol",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Symbol",
      },
      {
        Label: "Unit",
        input: {
          id: "unit",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Unit",
      },
      {
        Label: "Destination Port",
        input: {
          id: "state",
          type: "text",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Destination Port",
      },
      {
        // Label: "Date",
        // input: {
        //   id: "date",
        //   type: "date",
        //   required: true,
        // },
        // validation: "notEmpty",
        // errorMessage: "Please Enter Date",

        Label: "Last trading time",
        input: {
          id: "ltt",
          name: "ltt",
          type: "time",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter time",
      },
      {
        Label: "Last trading price",
        input: {
          id: "ltp",
          name: "ltp",
          type: "number",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Price",
      },
      {
        Label: "Price (In USD)",
        input: {
          id: "price",
          type: "float",

          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Price",
      },
      // {
      //   Label: "Variance",
      //   input: {
      //     id: "variance",
      //     type: "number",
      //     step: 0.01,
      //     min: 0.01,
      //     required: true,
      //   },
      //   validation: "notEmpty",
      //   errorMessage: "Please Enter Number",
      // },
      {
        Label: "Color",
        input: {
          id: "color",
          type: "color",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Color",
      },
    ],
    editMetal: [
      {
        Label: "Name",
        input: {
          id: "name",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Metal Name",
      },
      {
        Label: "Symbol",
        input: {
          id: "symbol",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Symbol",
      },
      {
        Label: "Unit",
        input: {
          id: "unit",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Unit",
      },
      {
        Label: "Destination Port",
        input: {
          id: "state",
          type: "text",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Destination Port",
      },
      {
        // Label: "Date",
        // input: {
        //   id: "date",
        //   type: "date",
        //   required: true,
        // },
        // validation: "notEmpty",
        // errorMessage: "Please Enter Date",

        Label: "Last trading time",
        input: {
          id: "ltt",
          name: "ltt",
          type: "time",
        },
        // validation: "notEmpty",
        // errorMessage: "Please Enter Weight",
      },
      // {
      //   Label: "Last trading price",
      //   input: {
      //     id: "ltp",
      //     name: "ltp",
      //     type: "number",
      //   },
      //   validation: "notEmpty",
      //   errorMessage: "Please Enter Price",
      // },
      {
        Label: "Price (In USD)",
        input: {
          id: "price",
          type: "number",
          step: 0.01,
          min: 0.01,
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Price",
      },
      {
        Label: "Color",
        input: {
          id: "color",
          type: "color",
        },
        validation: "notEmpty",
        errorMessage: "Please Enter Color",
      },
    ],
    addPromoBanner: [
      {
        Label: "Image (327px X 232px)",
        input: {
          id: "profile",
          type: "file",
          accept: "image/*",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Upload Banner Image",
      },
    ],
    editPromoBanner: [
      {
        Label: "Image (327px X 232px)",
        input: {
          id: "profile",
          type: "file",
          accept: "image/*",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Upload Banner Image",
      },
    ],
    addHomeBanner: [
      {
        Label: "Image (335px X 129px)",
        input: {
          id: "profile",
          type: "file",
          accept: "image/*",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Upload Banner Image",
      },
    ],
    editHomeBanner: [
      {
        Label: "Image (335px X 129px)",
        input: {
          id: "profile",
          type: "file",
          accept: "image/*",
          required: true,
        },
        validation: "notEmpty",
        errorMessage: "Please Upload Banner Image",
      },
    ],
  },

  DATA_TABLE_COLUMNS: {
    users: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100  text-center d-block">Profile</span>,
        field: "profile",
      },
      {
        label: <span className="w-100  text-center d-block">Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100  text-center d-block">Email</span>,
        field: "email",
      },
      {
        label: <span className="w-100  text-center d-block">Phone</span>,
        field: "phone",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Account Status</span>
        ),
        field: "status",
      },
      {
        label: <span className="w-100  text-center d-block">Is Blocked</span>,
        field: "isBlocked",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    courtOrders: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100   d-block">Title</span>,
        field: "title",
      },
      {
        label: <span className="w-100   d-block">Description</span>,
        field: "desc",
      },
      {
        label: <span className="w-100   d-block">Action</span>,
        field: "action",
      },
    ],
    courtOrderInfoItems: [
      {
        label: <span className="w-100   d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100   d-block">Date</span>,
        field: "date",
      },
      {
        label: <span className="w-100   d-block">Filter</span>,
        field: "filterpar",
      },

      {
        label: <span className="w-100   d-block">File</span>,
        field: "file",
      },

      {
        label: <span className="w-100   d-block">Action</span>,
        field: "action",
      },
    ],
    techWindow: [
      {
        label: <span className="w-100   d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100  text-center d-block">Icon</span>,
        field: "profile",
      },
      {
        label: <span className="w-100  d-block">Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    ministryGuidelines: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100  text-center d-block">Icon</span>,
        field: "profile",
      },
      {
        label: <span className="w-100  d-block">Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    techQuestions: [
      {
        label: <span className="w-100   d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100   d-block">Question</span>,
        field: "question",
      },
      // {
      //   label: <span className="w-100  text-center d-block">View</span>,
      //   field: "file",
      // },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    techAnswers: [
      {
        label: <span className="w-100   d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100   d-block">User Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100   d-block">Answer</span>,
        field: "answer",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    userList: [
      {
        label: <span className="w-100   d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100   d-block">User Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    userAnswerList: [
      {
        label: <span className="w-100   d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100   d-block">Answer</span>,
        field: "answer",
      },
    ],
    minGuideCirculars: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100   d-block">Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100  text-center d-block">View</span>,
        field: "file",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    minGuideCirculars1: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100  d-block">Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100   d-block">Filter Parameter</span>,
        field: "date",
      },
      {
        label: <span className="w-100  text-center d-block">Index</span>,
        field: "index",
      },
      {
        label: <span className="w-100  text-center d-block">View</span>,
        field: "file",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    minGuideCircularInfoItem: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100  d-block">File Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100  d-block">Filter Parameter</span>,
        field: "filter",
      },
      {
        label: <span className="w-100  text-center d-block">Index</span>,
        field: "index",
      },
      {
        label: <span className="w-100  text-center d-block">View</span>,
        field: "file",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],

    subscriptions: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100  text-center d-block">Plan</span>,
        field: "planname",
      },
      {
        label: <span className="w-100  text-center d-block">User</span>,
        field: "username",
      },
      {
        label: <span className="w-100  text-center d-block">Pay Id</span>,
        field: "payId",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Subscribed At</span>
        ),
        field: "createdAt",
      },
      {
        label: <span className="w-100  text-center d-block">Expires At</span>,
        field: "expireDate",
      },
    ],
    appConfigs: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Under Maintenance</span>
        ),
        field: "appUnderMaintenance",
      },
      {
        label: <span className="w-100  text-center d-block">Force Update</span>,
        field: "forceUpdate",
      },
      {
        label: <span className="w-100  text-center d-block">Soft Update</span>,
        field: "softUpdate",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Android Version</span>
        ),
        field: "androidVersion",
      },
      {
        label: <span className="w-100  text-center d-block">ios Version</span>,
        field: "iosVersion",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    promoters: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },

      {
        label: <span className="w-100  text-center d-block">Logo</span>,
        field: "logo",
      },
      {
        label: <span className="w-100  text-center d-block">Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100  text-center d-block">TagLine</span>,
        field: "tagline",
      },

      {
        label: <span className="w-100  text-center d-block">Coverpage</span>,
        field: "coverpage",
      },
      {
        label: <span className="w-100  text-center d-block">Service 1</span>,
        field: "service1",
      },
      {
        label: <span className="w-100  text-center d-block">Service 2</span>,
        field: "service2",
      },
      {
        label: <span className="w-100  text-center d-block">Service 3</span>,
        field: "service3",
      },
      {
        label: <span className="w-100  text-center d-block">Service 4</span>,
        field: "service4",
      },
      {
        label: <span className="w-100  text-center d-block">Service 5</span>,
        field: "service5",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Intro paragraph 1</span>
        ),
        field: "introParagraph1",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Intro paragraph 2</span>
        ),
        field: "introParagraph2",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Intro paragraph 3</span>
        ),
        field: "introParagraph3",
      },
      {
        label: (
          <span className="w-100  text-center d-block">
            Intro paragraph image
          </span>
        ),
        field: "introParagraphImage",
      },
      {
        label: <span className="w-100  text-center d-block">Quote 1</span>,
        field: "quote1",
      },
      {
        label: <span className="w-100  text-center d-block">Quote 2</span>,
        field: "quote2",
      },
      {
        label: <span className="w-100  text-center d-block">Quote 3</span>,
        field: "quote3",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Quote message</span>
        ),
        field: "quoteMessage",
      },
      {
        label: <span className="w-100  text-center d-block">Image 1</span>,
        field: "image1",
      },
      {
        label: <span className="w-100  text-center d-block">Image 2</span>,
        field: "image2",
      },
      {
        label: <span className="w-100  text-center d-block">Image 3</span>,
        field: "image3",
      },
      {
        label: <span className="w-100  text-center d-block">Image 4</span>,
        field: "image4",
      },
      {
        label: <span className="w-100  text-center d-block">Image 5</span>,
        field: "image5",
      },
      {
        label: <span className="w-100  text-center d-block">Ranking</span>,
        field: "ranking",
      },

      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    metals: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100  text-center d-block">Name</span>,
        field: "name",
      },
      {
        label: <span className="w-100  text-center d-block">Symbol</span>,
        field: "symbol",
      },
      {
        label: <span className="w-100  text-center d-block">Unit</span>,
        field: "unit",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Destination Port</span>
        ),
        field: "state",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Last Trading Time</span>
        ),
        field: "ltt",
      },
      {
        label: (
          <span className="w-100  text-center d-block">Last Trading Price</span>
        ),
        field: "ltp",
      },
      {
        label: <span className="w-100  text-center d-block">Price</span>,
        field: "price",
      },
      {
        label: <span className="w-100  text-center d-block">Variance</span>,
        field: "variance",
      },
      {
        label: <span className="w-100  text-center d-block">Color</span>,
        field: "color",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
    banners: [
      {
        label: <span className="w-100  text-center d-block">No</span>,
        field: "no",
      },
      {
        label: <span className="w-100  text-center d-block">Photo</span>,
        field: "profile",
      },
      {
        label: <span className="w-100  text-center d-block">Action</span>,
        field: "action",
      },
    ],
  },
  imgPlaceHolder: "https://placehold.co/800x400?text=No+Image+Found",
};

export const getTableData = (dataKey, data) => {
  return {
    columns: CONSTANTS.DATA_TABLE_COLUMNS[dataKey],
    rows: data,
  };
};

export const ERROR = {
  "Data already exist for this email_deletedAt":
    "A user already exists with this email id.",
};

export default CONSTANTS;
