import React, { useState } from "react";
import img from "../../images/1.jpg";
import { Link } from "react-router-dom";
import classes from "../../css/topp.module.css";
import { Button } from "reactstrap";

const ImageWithPopup = (props) => {
  const { clickHandler, imgpath, data, btn, deletbtn } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [cur, setCur] = useState(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handlerClickOnEditIcon = () => {
    console.log(data);
    // setCur(promoter);
    //   setCurModal("edit");
  };
  const handleEditClick = () => {
    // Handle the edit action here
    console.log("Edit button clicked");
  };

  const handleDeleteClick = () => {
    // Handle the delete action here
    console.log("Delete button clicked");
  };

  return (
    <div
      className={`image-container ${classes.container}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        className={classes.image}
        src={imgpath}
        style={{
          borderRadius: "50%",
          border: "1px solid white",
          objectFit: "cover",
        }}
        alt="Your Image"
        height="70px"
        width="70px"
      />
      {isHovered && (
        <div
          className={`d-flex justify-content-center `}
          style={{
            position: "absolute",
            top: "50%",
            left: "40px",
            transform: "translate(-50%, -50%)",
            MsTransform: "translate(-50%, -50%)",
            color: "white",
            fontSize: "16px",
            padding: "12px 24px",
            border: "none",
            cursor: "pointer",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          <Button
            className="btn btn-info shadow btn-xs sharp mr-2"
            title="Edit"
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-pencil" style={{ cursor: "pointer" }}></i>
          </Button>
          <input
            style={{
              position: "absolute",
              top: "25%",
              zIndex: "1000",
              left: "20%",
              opacity: "0",
              cursor: "pointer",
            }}
            type="file"
            className="btn btn-info shadow btn-xs sharp mr-2"
            onChangeCapture={btn}
          />
          <Link
            className="btn btn-danger shadow btn-xs sharp mr-2"
            onClick={deletbtn}
            title="Delete"
          >
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ImageWithPopup;
