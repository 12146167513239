import { Row } from "react-bootstrap";
import ItemLoader from "../../pages/ItemLoader";
import NoItemsFound from "../../pages/NoItemsFound";
import VideoCard from "./VideoCard";

const Videos = (props) => {
  const { videos, isLoading, onClickOnDeleteIcon, onClickOnEditIcon } = props;

  return (
    <Row className="align-items-start">
      {!isLoading ? (
        <>
          {videos?.length ? (
            <>
              {videos.map((video, index) => (
                <VideoCard
                  key={video?.id}
                  index={index}
                  video={video}
                  onClickOnDeleteIcon={onClickOnDeleteIcon}
                  onClickOnEditIcon={onClickOnEditIcon}
                />
              ))}
            </>
          ) : (
            <NoItemsFound message="No videos found." />
          )}
        </>
      ) : (
        <>
          <ItemLoader />
        </>
      )}
    </Row>
  );
};

export default Videos;
