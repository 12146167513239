import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../utils/constants";
import DeleteModal from "../components/custom-modals/DeleteModal";
import FormModal from "../components/custom-modals/FormModal";
import FAQs from "../components/faqs/FAQs";
import Loader from "./Loader/Loader";
import PageTitle from "../layouts/PageTitle";
import MMAddButton from "../components/MMButtons/MMAddButton";

const getFormModalTitle = {
  add: "Add FAQ",
  edit: "Edit FAQ",
  delete: "Delete FAQ",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addFaq,
  edit: CONSTANTS.FORMS.editFaq,
};

const FAQPage = ({ props }) => {
  const getAllFaqs = useHttp();
  const addFaq = useHttp();
  const editFaq = useHttp();
  const deleteFaqs = useHttp();

  const [faqs, setFaqs] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'
  const [curFaq, setCurFaq] = useState(null);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerClickOnDeleteIcon = (faq) => {
    setCurFaq(faq);
    setCurModal("delete");
  };

  const handlerDeleteFaqRes = useCallback((responseData) => {
    setCurFaq(null);
    setCurModal(null);
    loadFaqs();
  }, []);

  const handlerDeleteFaq = (faqId) => {
    const deleteFaqURL = {
      type: "DELETE",
      endPoint: `/admin/faq/${faqId}`,
    };

    // make api call
    deleteFaqs.sendRequest(
      deleteFaqURL,
      handlerDeleteFaqRes,
      {},
      "Faq deleted successfully."
    );
  };

  const handlerGetAllFaqs = useCallback((responseData) => {
    if (responseData.status === "success") {
      setFaqs(responseData?.data?.rows);
    }
  }, []);

  const handlerAddFaqRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurFaq(null);
      setCurModal(null);
      loadFaqs();
    }
  }, []);

  const handlerSubmitAddFaq = (payload) => {
    addFaq.sendRequest(
      CONSTANTS.URLS.addFaq,
      handlerAddFaqRes,
      payload,
      "Faq added successfully."
    );
  };

  const handlerClickOnEditIcon = (faq) => {
    setCurFaq(faq);
    setCurModal("edit");
  };

  const handlerEditFaqRes = useCallback((responseData) => {
    setCurModal(null);
    setCurFaq(null);
    loadFaqs();
  }, []);

  const handlerEditFaq = (faqId, payload) => {
    const editFaqURL = {
      type: "PATCH",
      endPoint: `/admin/faq/${faqId}`,
    };

    // make api call
    editFaq.sendRequest(
      editFaqURL,
      handlerEditFaqRes,
      payload,
      "Faq updated successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    if (curModal === "add") {
      handlerSubmitAddFaq(payload);
    } else {
      handlerEditFaq(curFaq?.id, payload);
    }
  };

  const loadFaqs = () => {
    getAllFaqs.sendRequest(CONSTANTS.URLS.getAllFAQs, handlerGetAllFaqs, {});
  };

  const isLoading = () => {
    return (
      getAllFaqs.isLoading ||
      addFaq.isLoading ||
      editFaq.isLoading ||
      deleteFaqs.isLoading
    );
  };

  useEffect(() => {
    loadFaqs();
  }, []);

  return (
    <>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this FAQ?"}
        desc={<u>{curFaq?.title}</u>}
        onDelete={() => handlerDeleteFaq(curFaq?.id)}
      />
      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                title: curFaq?.title,
                body: curFaq?.body,
              }
            : {}
        }
      />

      <PageTitle
        pageHeading="FAQs"
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />
      <FAQs
        faqs={faqs}
        isLoading={getAllFaqs?.isLoading}
        onLoad={loadFaqs}
        onClickOnDeleteIcon={handlerClickOnDeleteIcon}
        onClickOnEditIcon={handlerClickOnEditIcon}
      />
    </>
  );
};

export default FAQPage;
