import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Card, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../utils/constants";
import DeleteModal from "../components/custom-modals/DeleteModal";
import FormModal from "../components/custom-modals/FormModal";
import MMAddButton from "../components/MMButtons/MMAddButton";
import MMTable from "../components/table/MMTable";
import Loader from "./Loader/Loader";
import PageTitle from "../layouts/PageTitle";

const getFormModalTitle = {
  add: "Add Banner",
  edit: "Edit Banner",
  delete: "Delete Banner",
};

const getPromoFormInputList = {
  add: CONSTANTS.FORMS.addPromoBanner,
  edit: CONSTANTS.FORMS.editPromoBanner,
};
const getHomeFormInputList = {
  add: CONSTANTS.FORMS.addHomeBanner,
  edit: CONSTANTS.FORMS.editHomeBanner,
};

// const tabData = [{ name: "Home" }, { name: "Promo" }, { name: "TEst" }];
const tabData = [
  {
    name: "Home",
    content:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
  },
  {
    name: "Promo",
    content:
      "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
  },
];

const BannersPage = () => {
  const getAllBanners = useHttp();
  const addBanner = useHttp();
  const editBanner = useHttp();
  const deleteBanners = useHttp();

  const isLoading = () => {
    return (
      getAllBanners.isLoading ||
      addBanner.isLoading ||
      editBanner.isLoading ||
      deleteBanners.isLoading
    );
  };

  const [banners, setBanners] = useState([]);
  const [homeBannersData, setHomeBannersData] = useState([]);
  const [promoBannersData, setPromoBannersData] = useState([]);
  const [activeTab, setActiveTab] = useState("home");
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curBanner, setCurBanner] = useState(null);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerClickOnEditIcon = (banner) => {
    setCurBanner(banner);
    setCurModal("edit");
  };

  const bannersDataHandler = (banners) => {
    const home = [];
    const promo = [];

    banners.map((banner, index) => {
      if (banner?.home && banner?.home !== "null") {
        let data = {
          // userData: userData,
          no: `${index + 1}`,
          profile: (
            <div className="w-100 text-center">
              <img
                src={banner?.home}
                style={{
                  height: "25%",
                  width: "25%",
                  objectFit: "cover",
                  aspectRatio: 1.5,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                // className="rounded-circle"
              />
            </div>
          ),
          action: (
            <div className="d-flex justify-content-center">
              {/* <Link
                to={`/image-viewer?file=${banner?.home}&fileName=${""}`}
                className="btn btn-warning shadow btn-xs sharp mr-2"
              >
                <i className="fa fa-eye"></i>
              </Link> */}
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnEditIcon(banner)}
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnDeleteIcon(banner)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
        home.push(data);
      } else {
        let data = {
          // userData: userData,
          no: `${index + 1}`,
          photo: (
            <div className="w-100 text-center">
              <img
                src={banner?.promoter}
                style={{
                  height: "25%",
                  width: "25%",
                  objectFit: "cover",
                  aspectRatio: 1.5,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                // className="rounded-circle"
              />
            </div>
          ),
          action: (
            <div className="d-flex justify-content-center">
              {/* <Link
                to={`/image-viewer?file=${banner?.promoter}&fileName=${""}`}
                className="btn btn-warning shadow btn-xs sharp mr-2"
              >
                <i className="fa fa-eye"></i>
              </Link> */}
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnEditIcon(banner)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnDeleteIcon(banner)}
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
        promo.push(data);
      }
    });

    setHomeBannersData(home);
    setPromoBannersData(promo);
  };

  const handlerGetAllbanners = useCallback((responseData) => {
    if (responseData.status === "success") {
      setBanners(responseData?.data?.rows);
      bannersDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadBanners = () => {
    getAllBanners.sendRequest(
      CONSTANTS.URLS.getAllBanners,
      handlerGetAllbanners,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (banner) => {
    setCurBanner(banner);
    setCurModal("delete");
  };

  const handlerDeleteBannersRes = useCallback((responseData) => {
    setCurBanner(null);
    setCurModal(null);
    loadBanners();
  }, []);

  const handlerDeleteBanner = (bannerId) => {
    const deleteBannerURL = {
      type: "DELETE",
      endPoint: `/admin/banner/${bannerId}`,
    };

    // make api call
    deleteBanners.sendRequest(
      deleteBannerURL,
      handlerDeleteBannersRes,
      {},
      "Banner deleted successfully."
    );
  };

  const handlerAddBannerRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurBanner(null);
      setCurModal(null);
      loadBanners();
    }
  }, []);

  const handlerSubmitAddBanner = (payload) => {
    console.log(payload);

    addBanner.sendRequest(
      CONSTANTS.URLS.addBanner,
      handlerAddBannerRes,
      payload,
      "Banner added successfully."
    );
  };

  const handlerEditBannerRes = useCallback((responseData) => {
    setCurModal(null);
    setCurBanner(null);
    loadBanners();
  }, []);

  const handlerEditBanner = (bannerId, payload) => {
    const editBannerURL = {
      type: "PATCH",
      endPoint: `/admin/banner/${bannerId}`,
    };

    // make api call
    editBanner.sendRequest(
      editBannerURL,
      handlerEditBannerRes,
      payload,
      "Banner updated successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    console.log(payload);
    payload.append(
      activeTab === "home" ? "home" : "promoter",
      payload.get("profile")
    );
    payload.append(activeTab === "home" ? "promoter" : "home", null);
    payload.delete("profile");
    const json = {};
    for (const [key, value] of payload.entries()) {
      json[key] = value;
    }
    console.log(json);
    if (curModal === "add") {
      handlerSubmitAddBanner(payload);
    } else {
      handlerEditBanner(curBanner?.id, payload);
    }
  };

  const tabData = [
    {
      name: "Home",
      content: (
        <MMTable
          title="Home Banners"
          noItemMsg="No banners found."
          tableData={{
            columns: getTableData("banners")["columns"],
            rows: homeBannersData,
          }}
        />
      ),
    },
    {
      name: "Promo",
      content: (
        <MMTable
          title="Promo Banners"
          noItemMsg="No banners found."
          tableData={{
            columns: getTableData("banners")["columns"],
            rows: promoBannersData,
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    loadBanners();
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this banner?"}
        desc={curBanner?.title}
        onDelete={() => handlerDeleteBanner(curBanner?.id)}
      />
      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={
          activeTab === "home"
            ? getHomeFormInputList[curModal]
            : getPromoFormInputList[curModal]
        }
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                title: curBanner?.title,
                photo: curBanner?.photo,
                discription: curBanner?.discription,
              }
            : {}
        }
      />

      <PageTitle
        pageHeading="Banners"
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />

      <Card>
        <Card.Body>
          <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
            <Nav as="ul" className="nav-pills mb-4 justify-content-end">
              {tabData.map((data, i) => (
                <Nav.Item
                  as="li"
                  key={i}
                  onClick={() => setActiveTab(data.name.toLowerCase())}
                >
                  <Nav.Link eventKey={data.name.toLowerCase()}>
                    {data?.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Tab.Content className="">
              {tabData.map((data, i) => (
                <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                  <p>{data.content}</p>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default BannersPage;
