import pic1 from "../../../images/profile/small/pic1.jpg";
import avatar1 from "../../../images/avatar/5.png";
import avatar2 from "../../../images/avatar/1.png";



const jobsTable = {
  data: [
    [
      "Paul Byrd",
      "Chief Financial Officer (CFO)",
      "New York",
      "3059",
      "2010/06/09",
      "$725,000",
    ],
    [
      "Gloria Little",
      "Systems Administrator",
      "New York",
      "1721",
      "2009/04/10",
      "$237,500",
    ],
    [
      "Bradley Greer",
      "Software Engineer",
      "London",
      "2558",
      "2012/10/13",
      "$132,000",
    ],
    [
      "Dai Rios",
      "Personnel Lead",
      "Edinburgh",
      "2290",
      "2012/09/26",
      "$217,500",
    ],
    [
      "Jenette Caldwell",
      "Development Lead",
      "New York",
      "1937",
      "2011/09/03",
      "$345,000",
    ],
    [
      "Yuri Berry",
      "Chief Marketing Officer (CMO)",
      "New York",
      "6154",
      "2009/06/25",
      "$675,000",
    ],
    [
      "Caesar Vance",
      "Pre-Sales Support",
      "New York",
      "8330",
      "2011/12/12",
      "$106,450",
    ],
    [
      "Doris Wilder",
      "Sales Assistant",
      "Sydney",
      "3023",
      "2010/09/20",
      "$85,600",
    ],
    [
      "Angelica Ramos",
      "Chief Executive Officer (CEO)",
      "London",
      "5797",
      "2009/10/09",
      "$1,200,000",
    ],
    ["Gavin Joyce", "Developer", "Edinburgh", "8822", "2010/12/22", "$92,575"],
  ],
  columns: ["Name", "Position", "Office", "Extn.", "Start date", "Salary"],
};
const profileTable = {
  data: [
    [
      pic1,
      "Paul Byrd",
      "Chief Financial Officer (CFO)",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
    [
      pic1,
      "Gloria Little",
      "Systems Administrator",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
    [
      pic1,
      "Bradley Greer",
      "Software Engineer",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
    [
      pic1,
      "Dai Rios",
      "Personnel Lead",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
    [
      pic1,
      "Jenette Caldwell",
      "Development Lead",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
    [
      pic1,
      "Yuri Berry",
      "Chief Marketing Officer (CMO)",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
    [
      pic1,
      "Caesar Vance",
      "Pre-Sales Support",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
    [
      pic1,
      "Doris Wilder",
      "Sales Assistant",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
    [
      pic1,
      "Angelica Ramos",
      "Chief Executive Officer (CEO)",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
    [
      pic1,
      "Gavin Joyce",
      "Developer",
      "Male",
      "M.COM., P.H.D.",
      "123 456 7890",
      "info@example.com",
      "2011/04/25",
      "",
    ],
  ],
  columns: [
    "",
    "Name",
    "Department",
    "Gender",
    "Education",
    "Mobile",
    "Email",
    "Joining Date",
    "Action",
  ],
};
const feeTable = {
  data: [
    [
      "01",
      "Paul Byrd",
      "#3059",
      "Library",
      "Cash",
      "Paid",
      "2010/06/09",
      "$725,000",
    ],
    [
      "02",
      "Gloria Little",
      "#1721",
      "Library",
      "Cash",
      "Paid",
      "2009/04/10",
      "$237,500",
    ],
    [
      "03",
      "Bradley Greer",
      "#2558",
      "Library",
      "Cash",
      "Paid",
      "2012/10/13",
      "$132,000",
    ],
    [
      "04",
      "Dai Rios",
      "#2290",
      "Library",
      "Cash",
      "Paid",
      "2012/09/26",
      "$217,500",
    ],
    [
      "05",
      "Jenette Caldwell",
      "#1937",
      "Library",
      "Cash",
      "Paid",
      "2011/09/03",
      "$345,000",
    ],
    [
      "06",
      "Yuri Berry",
      "#6154",
      "Library",
      "Cash",
      "Paid",
      "2009/06/25",
      "$675,000",
    ],
    [
      "07",
      "Caesar Vance",
      "#8330",
      "Library",
      "Cash",
      "Paid",
      "2011/12/12",
      "$106,450",
    ],
    [
      "08",
      "Doris Wilder",
      "#3023",
      "Tuition",
      "Cheque",
      "Unpaid",
      "2010/09/20",
      "$85,600",
    ],
    [
      "09",
      "Angelica Ramos",
      "#5797",
      "Library",
      "Cash",
      "Paid",
      "2009/10/09",
      "$1,200,000",
    ],
    [
      "10",
      "Gavin Joyce",
      "#8822",
      "Annual",
      "Credit Card",
      "Panding",
      "2010/12/22",
      "$92,575",
    ],
  ],
  columns: [
    "Roll No",
    "Student Name",
    "Invoice number",
    "Fees Type ",
    "Payment Type ",
    "Status",
    "Date",
    "Amount",
  ],
};

const patientTable = {
  data: [
    [
      "",
      "#P-00001",
      "26/02/2023, 12:42 AM",
      "Paul Byrd",
      "Dr. Cedric",
      "Cold & Flu",
      "New Patient",
      "AB-001",
      "",
    ],
    [
      "",
      "#P-00002",

      "26/02/2023, 12:42 AM",
      "Gloria Little",
      "Dr. Cedric",
      "Cold & Flu",
      "In Treatment",
      "AB-002",
      "",
    ],
    [
      "",
      "#P-00003",

      "26/02/2023, 12:42 AM",
      "Bradley Greer",
      "Dr. Cedric",
      "Cold & Flu",
      " Recovered",
      "AB-003",
      "",
    ],
    [
      "",
      "#P-00004",
      "26/02/2023, 12:42 AM",
      "Bradley Greer",
      "Dr. Cedric",
      "Cold & Flu",
      " Recovered",
      "AB-003",
      "",
    ],
    [
      "",
      "#P-00005",
      "26/02/2023, 12:42 AM",
      "Jenette Caldwell",
      "Dr. Cedric",
      "Cold & Flu",
      "New Patient",
      "AB-005",
      "",
    ],
    [
      "",
      "#P-00006",
      "26/02/2023, 12:42 AM",
      "Yuri Berry",
      "Dr. Cedric",
      "Cold & Flu",
      "New Patient",
      "AB-006",
      "",
    ],
    [
      "",
      "#P-00007",
      "26/02/2023, 12:42 AM",
      "Caesar Vance",
      "Dr. Cedric",
      "Cold & Flu",
      "Recovered",
      "AB-007",
      "",
    ],
    [
      "",
      "#P-00008",
      "26/02/2023, 12:42 AM",
      "Doris Wilder",
      "Dr. Cedric",
      "Cold & Flu",
      "New Patient",
      "AB-008",
      "",
    ],
    [
      "",
      "#P-00009",
      "26/02/2023, 12:42 AM",
      "Angelica Ramos",
      "Dr. Cedric",
      "Cold & Flu",
      "New Patient",
      "AB-009",
      "",
    ],
    [
      "",
      "#P-00010",
      "26/02/2023, 12:42 AM",
      "Gavin Joyce",
      "Dr. Cedric",
      "Cold & Flu",
      "New Patient",
      "AB-010",
      "",
    ],
  ],
  columns: [
    "",
    "Order ID",
    "Date Check in",
    "Name",
    "Assgined",
    "Disease",
    "Status",
    "Table no",
    "Action",
  ],
};

const productData = {
  data: [
    [
      "Completed",
      [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
      "20/04/2023",
      "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
      "Completed",
      "$99",
      "",
    ],
    [
      "Processing",
      [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
      "20/04/2023",
      "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
      "Processing",
      "$99",
      "",
    ],
    [
      "",
      [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
      "20/04/2023",
      "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
      "On Hold",
      "$99",
      "",
    ],
    [
      "Pending",
      [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
      "20/04/2023",
      "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
      "Pending",
      "$99",
      "",
    ],
    [
      "Completed",
      [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
      "20/04/2023",
      "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
      "Completed",
      "$99",
      "",
    ],
  ],
  columns: ["Order", "Date", "Ship To", "Status", "Amount"],
};

const customers = {
  data: [
    [
      { one: "" },
      { two: { img: avatar1, name: "Ricky Antony" } },
      { three: "info@example.com" },
      { four: "(201) 200-1851	" },
      { five: "2392 Main Avenue, Penasauka" },
      { six: "30/03/2018" },
      { seven: "" },
    ],
    [
      { one: "" },
      { two: { img: avatar2, name: "Ricky Antony" } },
      { three: "info@example.com" },
      { four: "(201) 200-1851	" },
      { five: "2392 Main Avenue, Penasauka" },
      { six: "30/03/2018" },
      { seven: "" },
    ],
    [
      { one: "" },
      { two: { img: avatar1, name: "Ricky Antony" } },
      { three: "info@example.com" },
      { four: "(201) 200-1851	" },
      { five: "2392 Main Avenue, Penasauka" },
      { six: "30/03/2018" },
      { seven: "" },
    ],
    [
      { one: "" },
      { two: { img: avatar2, name: "Ricky Antony" } },
      { three: "info@example.com" },
      { four: "(201) 200-1851	" },
      { five: "2392 Main Avenue, Penasauka" },
      { six: "30/03/2018" },
      { seven: "" },
    ],
  ],
  columns: ["Name", "Email", "Phone", "Billing Address", "Joined", ""],
};

const data = {
  jobsTable,
  profileTable,
  feeTable,
  patientTable,
  productData,
  customers,
};

export default data;
