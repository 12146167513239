import { toast } from "react-toastify";

var EmailValidationRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
/*
These formates are valid: 

(123) 456-7890
(123)456-7890
123-456-7890
1234567890 
*/
var PhoneValidationRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

export const notify = {
  success: (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  error: (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
};

export const isNonEmpty = (value) => value && value.trim() !== "";

export const isEmail = (value) => value && value.match(EmailValidationRegex);

export const isPhone = (value) => value && value.match(PhoneValidationRegex);

export const handlerCopyToClipBoard = (textToBeCopy_) => {
  // window.navigator.clipboard.writeText(textToBeCopy_);

  var textArea = document.createElement("textarea");
  textArea.value = textToBeCopy_;
  textArea.style.position = "fixed"; //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    if (successful) {
      notify.success("Copied!");
    }
  } catch (err) {
    console.log(err);
  }

  document.body.removeChild(textArea);
  return;
};

export const convertToChemicalFormula = (config) => {
  let elements = [];
  let currentElement = "";
  let currentCount = "";
  let result = "";

  // Loop through the configuration string
  for (let i = 0; i < config.length; i++) {
    let char = config.charAt(i);

    // If the character is a digit, it's part of the electron count
    if (char.match(/[0-9]/)) {
      currentCount += char;
    }
    // If the character is a letter, it's the start of a new element
    else if (char.match(/[A-Za-z]/)) {
      // Add the previous element to the list
      if (currentElement !== "") {
        elements.push([currentElement, currentCount || "1"]);
      }
      // Start the new element
      currentElement = char;
      currentCount = "";
    }
  }

  // Add the last element to the list
  if (currentElement !== "") {
    elements.push([currentElement, currentCount || "1"]);
  }

  // Convert the element list into a formatted string
  for (let i = 0; i < elements.length; i++) {
    let element = elements[i][0];
    let count = elements[i][1];

    // If the count is one, we don't need to show the subscript
    if (count === "1") {
      result += element;
    }
    // Otherwise, we need to show the subscript
    else {
      result += element + "<sub>" + count + "</sub>";
    }
  }

  return result;
};

export const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours ? hours + ":" : ""}${
    minutes < 10 ? "0" + minutes : minutes
  }:${parseInt(remainingSeconds)}`;
};

export const logFormData = (formData) => {
  // Display the key/value pairs
  for (const pair of formData.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
};
