import { Button, Modal, ProgressBar } from "react-bootstrap";

export default function BulkLoader(props) {
  return (
    <Modal className="fade mm-form-modal" show={true} id="addMoreTraining">
      <div className="modal-content">
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">Uploading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.progress ? (
            <div>
              <ProgressBar
                style={{
                  height: "5vh",
                }}
                animated
                now={props.progress}
                label={`${props.progress?.toFixed(0)}%`}
              />
            </div>
          ) : (
            <></>
          )}

          <p>{props.fileNames}</p>
        </Modal.Body>
      </div>
    </Modal>
  );
}
