import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../utils/constants";
import DeleteModal from "../components/custom-modals/DeleteModal";
import FormModal from "../components/custom-modals/FormModal";
import MMAddButton from "../components/MMButtons/MMAddButton";
import Videos from "../components/videos/Videos";
import Loader from "./Loader/Loader";
import PageTitle from "../layouts/PageTitle";
import { formatDuration } from "../../utils/funcs";
import { Services } from "../../services/service";
import e from "cors";
import { notify } from "../../utils/funcs";

const getFormModalTitle = {
  add: "Add Video",
  edit: "Edit Video",
  delete: "Delete Video",
  addInBulk: "Add Video Bulk",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addVideo,
  edit: CONSTANTS.FORMS.editVideo,
  addInBulk: CONSTANTS.FORMS.addBulkVideo,
};

const VideoPage = () => {
  const getAllVideos = useHttp();
  const addVideo = useHttp();
  const deleteVideo = useHttp();
  const editVideo = useHttp();

  const isLoading = () => {
    return (
      getAllVideos.isLoading ||
      addVideo.isLoading ||
      editVideo.isLoading ||
      deleteVideo.isLoading
    );
  };

  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'
  const [curVideo, setCurVideo] = useState(null);
  const [loader, setLoader] = useState(false);

  const [videos, setVideos] = useState([]);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerGetAllVideos = useCallback((responseData) => {
    if (responseData.status === "success") {
      setVideos(responseData?.data?.rows);
    }
  }, []);

  const handlerAddVideoRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurVideo(null);
      setCurModal(null);
      loadVideos();
    }
  }, []);

  const handlerClickOnDeleteIcon = (video) => {
    setCurVideo(video);
    setCurModal("delete");
  };

  const handlerClickOnEditIcon = (video) => {
    setCurVideo(video);
    setCurModal("edit");
  };

  const handlerEditVideoRes = useCallback((responseData) => {
    setCurModal(null);
    setCurVideo(null);
    loadVideos();
  }, []);

  const handlerDeleteVideoRes = useCallback((responseData) => {
    setCurVideo(null);
    setCurModal(null);
    loadVideos();
  }, []);

  const handlerDeleteVideo = (videoeId) => {
    const deleteVideoURL = {
      type: "DELETE",
      endPoint: `/admin/video/${videoeId}`,
    };

    // make api call
    deleteVideo.sendRequest(
      deleteVideoURL,
      handlerDeleteVideoRes,
      {},
      "Video deleted successfully."
    );
  };

  const handlerEditVideo = (videoeId, payload) => {
    const editVideoURL = {
      type: "PATCH",
      endPoint: `/admin/video/${videoeId}`,
    };

    // make api call
    editVideo.sendRequest(
      editVideoURL,
      handlerEditVideoRes,
      payload,
      "Video updated successfully."
    );
  };

  const handlerSubmitAddVideo = (payload) => {
    console.log(payload);
    addVideo.sendRequest(
      CONSTANTS.URLS.addVideo,
      handlerAddVideoRes,
      payload,
      "Video added successfully."
    );
  };

  //  const handlerSubmitAddBulkVideo = (payload) => {
  //    addVideo.sendRequest(
  //      CONSTANTS.URLS.addVideo,
  //      handlerAddVideoRes,
  //      payload,
  //      "Video added successfully."
  //    );
  //  };

  const handlerSubmitAddBulkVideo = async (formData) => {
    setLoader(true);

    const uploadFiles = async () => {
      let duration;
      let buclFile = Object.values(formData.video.files);

      const results = await Promise.all(
        Array.from(buclFile).map(async (file, index) => {
          try {
            if (file?.name) {
              let fName = file.name.split(".")[0];
              const filevideo = file;

              const payload = new FormData();
              payload.append("title", fName);

              const reader = new FileReader();

              const eventPromise = new Promise((resolve, reject) => {
                reader.onload = (event) => {
                  resolve(event);
                };
                reader.onerror = (error) => {
                  reject(error);
                };
              });

              reader.readAsDataURL(filevideo);

              const event = await eventPromise;

              const videoElement = document.createElement("video");
              videoElement.src = event.target.result;

              const metadataPromise = new Promise((resolve) => {
                videoElement.onloadedmetadata = () => {
                  duration = formatDuration(videoElement.duration);
                  payload.append("video", filevideo);
                  payload.append("duration", duration);
                  resolve();
                };
              });

              await metadataPromise;

              const response = await Services.post(
                CONSTANTS.URLS.addVideo.endPoint,
                payload
              );

              if (!response.statusText === "OK") {
                throw new Error("Failed to upload file");
              }

              return response;
            }
          } catch (error) {
            return { file: file.name, error: error.message };
          }
        })
      );
      const failedFiles = results.filter((result) => result.error);
      //  setFailedFiles(failedFiles);
      console.log(failedFiles.length);
      if (failedFiles.length > 0) {
        setCurModal("alert");
        loadVideos();
        notify.error("Failed to upload the files");
      } else {
        setCurModal(null);
        setCurVideo(null);
        loadVideos();
        notify.success("All Videos uploaded successfully!");
      }

      setLoader(false);
    };
    uploadFiles();
  };

  const handlerSubmitForm = async (target) => {
    let duration;
    const file = target?.video?.files[0];

    const payload = new FormData();
    if (curModal !== "addInBulk") {
      const thumbails = target?.thumbnail?.files[0];

      payload.append("title", target?.title?.value);
      payload.append("thumbnail", thumbails);
    }

    if (target.video.files.length) {
      const reader = new FileReader();

      const eventPromise = new Promise((resolve, reject) => {
        reader.onload = (event) => {
          resolve(event);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });

      reader.readAsDataURL(file);

      const event = await eventPromise;

      const videoElement = document.createElement("video");
      videoElement.src = event?.target?.result;

      const metadataPromise = new Promise((resolve) => {
        videoElement.onloadedmetadata = () => {
          duration = formatDuration(videoElement?.duration);
          payload.append("video", file);
          payload.append("duration", duration);

          resolve();
        };
      });
      console.log(metadataPromise);
      await metadataPromise;
    }

    if (curModal === "add") {
      handlerSubmitAddVideo(payload);
      setCurModal(null);
    } else if (curModal === "edit") {
      handlerEditVideo(curVideo?.id, payload);
      setCurModal(null);
    }
    // else {
    //   handlerSubmitAddBulkVideo(target);
    //   setCurModal(null);
    // }
  };

  const loadVideos = () => {
    getAllVideos.sendRequest(
      CONSTANTS.URLS.getAllVideos,
      handlerGetAllVideos,
      {}
    );
  };

  useEffect(() => {
    loadVideos();
  }, []);

  return (
    <>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this video?"}
        desc={<u>{curVideo?.title}</u>}
        onDelete={() => handlerDeleteVideo(curVideo?.id)}
      />

      <FormModal
        isOpen={
          curModal === "add" || curModal === "edit" || curModal === "addInBulk"
        }
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        targetForm
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                title: curVideo?.title,
                video: curVideo?.video,
                duration: curVideo?.duration,
                thumbnail: curVideo?.thumbnail,
              }
            : {}
        }
      />
      {loader ? <Loader /> : <></>}
      <PageTitle
        pageHeading="Videos"
        backNavPath="/"
        withBackButton
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
          // <MMAddButton
          //   key="addInBulk"
          //   clickHandler={() => setCurModal("addInBulk")}
          // >
          //   Bulk Upload
          // </MMAddButton>,
        ]}
      />
      <Videos
        videos={videos}
        isLoading={getAllVideos?.isLoading}
        onLoad={loadVideos}
        onClickOnDeleteIcon={handlerClickOnDeleteIcon}
        onClickOnEditIcon={handlerClickOnEditIcon}
      />
    </>
  );
};

export default VideoPage;
