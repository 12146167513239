import { useState } from "react";
import { Accordion, Row } from "react-bootstrap";
import ItemLoader from "../../pages/ItemLoader";
import NoItemsFound from "../../pages/NoItemsFound";
import FAQ from "./FAQ";

const FAQs = (props) => {
  const { faqs, isLoading, onClickOnDeleteIcon, onClickOnEditIcon } = props;

  const [activeBordered, setActiveBordered] = useState(0);
  useState(0);
  return (
    <Row className="m-0">
      {!isLoading ? (
        <>
          {faqs?.length ? (
            <Accordion
              className="accordion accordion-primary-solid w-100"
              defaultActiveKey="0"
            >
              {faqs?.map((faq) => (
                <FAQ
                  key={faq?.id}
                  faq={faq}
                  activeBordered={activeBordered}
                  setActiveBordered={setActiveBordered}
                  onClickOnDeleteIcon={onClickOnDeleteIcon}
                  onClickOnEditIcon={onClickOnEditIcon}
                />
              ))}
            </Accordion>
          ) : (
            <NoItemsFound message="No faqs found." />
          )}
        </>
      ) : (
        <>
          <ItemLoader />
        </>
      )}
    </Row>
  );
};

export default FAQs;
