import { Row, Pagination, Table } from "react-bootstrap";
import ItemLoader from "../../pages/ItemLoader";
import NoItemsFound from "../../pages/NoItemsFound";
import Magazine from "./Magazine";
import LinkP from "./Magazine";
import { Link, useNavigate } from "react-router-dom";

import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
const sort = 20;
const sorting = {};
const isNotShort = {
  action: true,
  isBlocked: true,
  profile: true,
  file: true,
};
const Magazines = (props) => {
  const { magazines, isLoading, onClickOnDeleteIcon, onClickOnEditIcon } =
    props;
  const { tableData, filter = "", noItemMsg, isShowFilter = false } = props;
  const [filterResult, setFilterResult] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [activePag, setActivePag] = useState(0);
  const [onClick, setOnClick] = useState(null);
  const [refreash, setRefreash] = useState(false);
  useEffect(() => {
    const alldata = magazines?.rows?.map((el) => el[filter]);
    setFilterResult([...new Set(alldata)]);
  }, [magazines]);

  useEffect(() => {
    setActivePag(0);
  }, [searchText]);
  const FilterData = magazines?.rows?.filter((el) => {
    console.log(el, "khj");
    if (searchText.trim() === "" || searchText === null) {
      return true;
    } else {
      const results = Object.values(el).map(
        (el) =>
          typeof el === "string" &&
          el?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())
      );

      return results.includes(true);
    }
  });

  if (onClick != null) {
    if (sorting[onClick]) {
      sorting[onClick] = false;
      FilterData.sort((a, b) =>
        a[onClick].localeCompare(b[onClick], "en", { numeric: true })
      );
    } else {
      sorting[onClick] = true;
      FilterData.sort((a, b) =>
        b[onClick].localeCompare(a[onClick], "en", { numeric: true })
      );
    }
  }

  // let jobPagination = Array(Math.ceil(FilterData?.length / sort))
  //   .fill()
  //   ?.map((_, i) => i + 1);

  const jobData = useRef(
    FilterData?.slice(activePag * sort, (activePag + 1) * sort)
  );

  const handlerClickOnPagination = (i) => {
    setActivePag(i);

    FilterData?.slice(activePag * sort, (activePag + 1) * sort);
  };

  jobData.current = FilterData?.slice(activePag * sort, (activePag + 1) * sort);

  return (
    // <Row>
    //   {!isLoading ? (
    //     <>
    //       {magazines?.length ? (
    //         <>
    //           {magazines.map((magazine, index) => {
    //             return (
    //               <Magazine
    //                 key={magazines?.id}
    //                 index={index}
    //                 magazine={magazine}
    //                 onClickOnDeleteIcon={onClickOnDeleteIcon}
    //                 onClickOnEditIcon={onClickOnEditIcon}
    //               />
    //             );
    //           })}
    //         </>
    //       ) : (
    //         <NoItemsFound message="No magazines found." />
    //       )}
    //     </>
    //   ) : (
    //     <>
    //       <ItemLoader />
    //     </>
    //   )}
    // </Row>

    <div className="col-12 px-0">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title" style={{ width: "400px" }}>
            {isShowFilter && (
              <Autocomplete
                id="filter"
                freeSolo
                disableCloseOnSelect={false}
                className="w-100"
                options={filterResult}
                renderInput={(params) => (
                  <TextField {...params} label="Search Filter" />
                )}
                onChange={(e, value) => {
                  // setSearchText(value);
                }}
                onInputChange={(event, value) => {
                  setSearchText(value);
                }}
                getOptionLabel={(option) => option?.filterResult ?? option}
              />
            )}
          </h4>
        </div>
        <div className="card-body">
          <Row>
            {!isLoading ? (
              <>
                {magazines?.length ? (
                  <>
                    {magazines.map((magazine, index) => {
                      return (
                        <Magazine
                          key={magazines?.id}
                          index={index}
                          magazine={magazine}
                          onClickOnDeleteIcon={onClickOnDeleteIcon}
                          onClickOnEditIcon={onClickOnEditIcon}
                        />
                      );
                    })}
                  </>
                ) : (
                  <NoItemsFound message="No magazines found." />
                )}
              </>
            ) : (
              <>
                <ItemLoader />
              </>
            )}
          </Row>{" "}
        </div>
      </div>
    </div>
  );
};

export default Magazines;
