class AuthStorage {
  getAuthToken = () => {
    return localStorage.getItem("authToken");
  };

  setAuthDetails = (accessToken) => {
    // setCookie("SAID", accessToken, 1);
    localStorage.setItem("authToken", accessToken);
  };

  deleteAuthDetails = () => {
    localStorage.removeItem("authToken");
  };
}
const authStorage = new AuthStorage();

export default authStorage;
