import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormView } from "tst-toolkit";

const FormModal = (props) => {
  const {
    isOpen,
    onClose,
    title,
    onSubmit,
    formInputList,
    defaultValue,
    targetForm,
    isFormPayload = false,
  } = props;

  return (
    <Modal className="fade mm-form-modal" show={isOpen} id="addMoreTraining">
      <div className="modal-content">
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">{title}</Modal.Title>
          <Button variant="" onClick={onClose} type="button" className="close">
            <span>×</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <FormView
            isFormPayload={isFormPayload}
            targetForm={targetForm}
            formInputList={formInputList}
            defaultValue={defaultValue || {}}
            onSubmit={(payload) => {
              onSubmit(payload);
            }}
          />
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default FormModal;
