import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../../utils/constants";
import DeleteModal from "../../components/custom-modals/DeleteModal";
import FormModal from "../../components/custom-modals/FormModal";
import MMAddButton from "../../components/MMButtons/MMAddButton";
import MMTable from "../../components/table/MMTable";
import Loader from "../Loader/Loader";
import PageTitle from "../../layouts/PageTitle";
import { Services } from "../../../services/service";
import { Input, Label, Row, Form } from "reactstrap";
import { Autocomplete, TextField } from "@mui/material";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { notify } from "../../../utils/funcs";
import BulkLoader from "../Loader/BulkLoader";

const getFormInputList = {
  add: CONSTANTS.FORMS.addMinGuideCircular,
  add1: CONSTANTS.FORMS.addMinGuideCircular1,
  edit: CONSTANTS.FORMS.editMinGuideCircular,
  edit1: CONSTANTS.FORMS.editMinGuideCircular1,
};

const MinGuideCircularPage = () => {
  const params = useParams();
  const location = useLocation();

  const getMinGuideCirculars = useHttp();
  const addMinGuideCircular = useHttp();
  const editMinGuideCircular = useHttp();
  const deleteMinGuideCircular = useHttp();
  const getministryguidlineType = useHttp();

  const isLoading = () => {
    return (
      getMinGuideCirculars.isLoading ||
      addMinGuideCircular.isLoading ||
      editMinGuideCircular.isLoading ||
      deleteMinGuideCircular.isLoading ||
      getministryguidlineType.isLoading
    );
  };

  const [minGuideCirculars, setMinGuideCirculars] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [minGuideCircularsData, setMinGuideCircularsData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curMinGuideCircular, setCurMinGuideCircular] = useState(null);
  const [state, setstate] = useState(location?.state);
  const [loader, setLoader] = useState(false);
  const [bulkloader, setBulkLoader] = useState(null);
  const [fileName, setFileName] = useState("");

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const getFormModalTitle = {
    add: state?.type == "2" ? "Add Circular" : "Add Folder",
    edit: state?.type == "2" ? "Edit Circular" : "Edit Folder",
    delete: state?.type == "2" ? "Delete Circular" : "Delete Folder",
    addInbulk: "Add Circular Bulk",
  };

  useEffect(() => {
    getministryguidlineType.sendRequest(
      {
        type: "GET",
        endPoint: `/admin/ministryguide/${params.guidelineId}`,
      },
      (res) => {
        setstate(res?.data);
      }
    );
  }, []);

  const handlerClickOnEditIcon = (minGuideCircular) => {
    setCurMinGuideCircular(minGuideCircular);
    setCurModal("edit");
  };

  const minGuideCircularsDataHandler = (minGuideCirculars) => {
    let indexCount = {};

    if (state?.type == "2") {
      minGuideCirculars.sort((a, b) => {
        if (!b?.date || !a?.date || a?.date == null || b?.date == null)
          return false;
        return a.date.localeCompare(b.date, "en", { numeric: true });
      });
    }

    const alldata = minGuideCirculars?.map((el) => el?.date);
    setFilterList([...new Set(alldata)]);

    setMinGuideCircularsData(
      minGuideCirculars.map((minGuideCircular, index) => {
        // console.log(minGuideCircular?.date);
        return state?.type == "2"
          ? {
              // userData: userData,
              no: `${index + 1}`,
              name: minGuideCircular?.name,
              date: minGuideCircular?.date,
              index: minGuideCircular?.index,

              file: (
                <div className="d-flex justify-content-center">
                  <Link
                    title="View"
                    to={`/pdf-viewer?file=${minGuideCircular?.file}&fileName=${minGuideCircular?.name}`}
                    state={{
                      title: minGuideCircular?.name,
                      lastPath: state?.name,
                    }}
                    className="btn btn-warning shadow btn-xs sharp mr-2"
                  >
                    <i className="fa fa-eye"></i>
                  </Link>
                </div>
              ),
              action: (
                <div className="d-flex justify-content-center">
                  <Link
                    className="btn btn-info shadow btn-xs sharp mr-2"
                    title="Edit"
                    onClick={() => handlerClickOnEditIcon(minGuideCircular)}
                  >
                    <i className="fa fa-pencil"></i>
                  </Link>
                  <Link
                    className="btn btn-danger shadow btn-xs sharp mr-2"
                    title="Delete"
                    onClick={() => handlerClickOnDeleteIcon(minGuideCircular)}
                  >
                    <i className="fa fa-trash"></i>
                  </Link>
                </div>
              ),
            }
          : {
              // userData: userData,
              no: `${index + 1}`,
              name: minGuideCircular?.name,
              file: (
                <div className="d-flex justify-content-center">
                  <Link
                    to={`/ministry-guideline/${params?.guidelineId}/circular/${minGuideCircular?.id}`}
                    state={{
                      title: minGuideCircular?.name,
                      lastPath: state,
                    }}
                    className="btn btn-warning shadow btn-xs sharp mr-2"
                    title="View"
                  >
                    <i className="fa fa-eye"></i>
                  </Link>
                </div>
              ),
              action: (
                <div className="d-flex justify-content-center">
                  <Link
                    className="btn btn-info shadow btn-xs sharp mr-2"
                    onClick={() => handlerClickOnEditIcon(minGuideCircular)}
                    title="Edit"
                  >
                    <i className="fa fa-pencil"></i>
                  </Link>
                  <Link
                    className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={() => handlerClickOnDeleteIcon(minGuideCircular)}
                    title="Delete"
                  >
                    <i className="fa fa-trash"></i>
                  </Link>
                </div>
              ),
            };
      })
    );
  };

  const handlerGetAllMinGuideCirculars = useCallback((responseData) => {
    if (responseData.status === "success") {
      setMinGuideCirculars(responseData?.data?.rows);
      minGuideCircularsDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadMinGuideCirculars = () => {
    const editMinGuideCircularURL = {
      type: "GET",
      endPoint: `/admin/ministryguidecircular?ministryGuideId=${params?.guidelineId}`,
    };
    getMinGuideCirculars.sendRequest(
      editMinGuideCircularURL,
      handlerGetAllMinGuideCirculars,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (minGuideCircular) => {
    setCurMinGuideCircular(minGuideCircular);
    setCurModal("delete");
  };

  const handlerDeleteMinGuideCircularRes = useCallback((responseData) => {
    setCurMinGuideCircular(null);
    setCurModal(null);
    loadMinGuideCirculars();
  }, []);

  const handlerDeleteMinGuideCircular = (minGuideCircularId) => {
    const deleteMinGuideCircularURL = {
      type: "DELETE",
      endPoint: `/admin/ministryguidecircular/${minGuideCircularId}`,
    };

    // make api call
    deleteMinGuideCircular.sendRequest(
      deleteMinGuideCircularURL,
      handlerDeleteMinGuideCircularRes,
      {},
      "Circular deleted successfully."
    );
  };

  const handlerAddMinGuideCircularRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurMinGuideCircular(null);
      setCurModal(null);
      loadMinGuideCirculars();
    }
  }, []);

  const handlerSubmitAddMinGuideCircular = (payload) => {
    payload.append("ministryGuideId", params?.guidelineId);
    payload.append("type", state?.type);

    addMinGuideCircular.sendRequest(
      CONSTANTS.URLS.addMinGuideCircular,
      handlerAddMinGuideCircularRes,
      payload,
      "Circular added successfully."
    );
  };

  const handlerEditMinGuideCircularRes = useCallback((responseData) => {
    setCurModal(null);
    setCurMinGuideCircular(null);
    loadMinGuideCirculars();
  }, []);

  const handlerEditMinGuideCircular = (minGuideCircularId, payload) => {
    const editMinGuideCircularURL = {
      type: "PATCH",
      endPoint: `/admin/ministryguidecircular/${minGuideCircularId}`,
    };

    payload.append("ministryGuideId", params?.guidelineId);

    // make api call
    editMinGuideCircular.sendRequest(
      editMinGuideCircularURL,
      handlerEditMinGuideCircularRes,
      payload,
      "Circular updated successfully."
    );
  };

  const handlerSubmitAddCourtOrderInfoItemInBulk = (formData) => {
    setLoader(true);
    setBulkLoader(1);

    const uploadFiles = async () => {
      let startIndex = 0;

      const sameArray = minGuideCircularsData?.filter(
        (el) => el.date == formData.filter.value
      );

      if (sameArray.length) {
        sameArray?.sort((a, b) => {
          return a.index - b.index;
        });
        startIndex = +sameArray[sameArray.length - 1].index;
      }

      let buclFile = Object.values(formData.file.files);

      if (
        moment(buclFile[0].name.substring(0, 10), "DD.MM.YYYY", true)._isValid
      ) {
        buclFile.sort(
          (a, b) =>
            new moment(a.name.substring(0, 10), "DD.MM.YYYY").format(
              "YYYYMMDD"
            ) -
            new moment(b.name.substring(0, 10), "DD.MM.YYYY").format("YYYYMMDD")
        );
      }

      const AllFiles = Array.from(buclFile);

      let isAllDataSubmited = true;
      setCurModal(null);
      for (let i = 0; i < AllFiles.length; i++) {
        {
          setBulkLoader(((i + 1) / AllFiles.length) * 100);
          setFileName(AllFiles[i].name);

          try {
            let fName = AllFiles[i].name;
            if (
              moment(AllFiles[i].name.substring(0, 10), "DD.MM.YYYY", true)
                ._isValid
            ) {
              fName = moment(
                AllFiles[i].name.substring(0, 10),
                "DD.MM.YYYY"
              ).format("MMM DD, YYYY");
            } else {
              fName = AllFiles[i].name.replace(".pdf", "");
            }
            console.log(fName);
            const payload = new FormData();
            payload.append("file", AllFiles[i]);
            payload.append("ministryGuideId", params?.guidelineId);
            payload.append("name", fName);
            if (formData.filter.value) {
              payload.append("date", formData.filter.value);
            }

            payload.append("type", state?.type);
            payload.append("index", startIndex + 1 + i);

            const response = await Services.post(
              CONSTANTS.URLS.addMinGuideCircular.endPoint,
              payload
            );

            if (!response.statusText === "OK") {
              throw new Error("Failed to upload file");
            }
          } catch (error) {
            setCurModal("alert");
            isAllDataSubmited = false;
            loadMinGuideCirculars();
            // console.log(error?.response?.data?.message);
            notify.error(error?.response?.data?.message);
            //  { file: AllFiles[i][1].name, error: error.message };
          }
        }
      }
      setCurModal(null);
      loadMinGuideCirculars();
      isAllDataSubmited && notify.success("All files uploaded successfully!");

      setBulkLoader(null);
      setLoader(false);

      // const results = await Promise.all(
      //   Array.from(buclFile).map(async (file, index) => {
      //     try {
      //       if (file?.name) {
      //         let fName = file.name.split(".")[0];
      //         if (moment(file.name.substring(0, 10), "DD.MM.YYYY")._isValid)
      //           fName = moment(file.name.substring(0, 10), "DD.MM.YYYY").format(
      //             "MMM DD,YYYY"
      //           );

      //         const payload = new FormData();
      //         payload.append("file", file);

      //         payload.append("ministryGuideId", params?.guidelineId);
      //         payload.append("name", fName);
      //         payload.append("date", formData.filter.value);
      //         payload.append("type", state?.type);
      //         payload.append("index", startIndex + 1 + index);

      //         const response = null;
      //         await Services.post(
      //           CONSTANTS.URLS.addMinGuideCircular.endPoint,
      //           payload
      //         );

      //         if (!response.statusText === "OK") {
      //           throw new Error("Failed to upload file");
      //         }

      //         return response;
      //       }
      //     } catch (error) {
      //       return { file: file.name, error: error.message };
      //     }
      //   })
      // );
      // loadMinGuideCirculars();
      // setCurModal(null);
      // setLoader(false);
    };
    uploadFiles();
  };

  const handlerSubmitForm = (payload) => {
    if (curModal === "add") {
      handlerSubmitAddMinGuideCircular(payload);
    } else {
      handlerEditMinGuideCircular(curMinGuideCircular?.id, payload);
    }
  };

  useEffect(() => {
    loadMinGuideCirculars();
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={
          state?.type == "2"
            ? "Are you sure to delete this circular?"
            : "Are you sure to delete this folder?"
        }
        desc={curMinGuideCircular?.name}
        onDelete={() => handlerDeleteMinGuideCircular(curMinGuideCircular?.id)}
      />
      {bulkloader ? (
        <BulkLoader progress={bulkloader} fileNames={fileName} />
      ) : (
        <></>
      )}
      <FormModal
        isFormPayload
        isOpen={curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={
          state?.type === "2"
            ? getFormInputList[curModal]
            : getFormInputList["add1"]
        }
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                name: curMinGuideCircular?.name,
                file: curMinGuideCircular?.file,
                type: curMinGuideCircular?.type,
                index: curMinGuideCircular?.index,
              }
            : {}
        }
      />

      <PageTitle
        pageHeading={`${state?.name}`}
        withBackButton
        buttons={[
          state?.type == "2" ? (
            <MMAddButton
              key="addInBulk"
              clickHandler={() => setCurModal("addInBulk")}
            >
              Bulk Upload
            </MMAddButton>
          ) : (
            <MMAddButton key="add" clickHandler={() => setCurModal("add")} />
          ),
        ]}
      />
      {/* {curMinGuideCircular?.type == 2
        ? getTableData("minGuideCirculars")["columns"]
        : getTableData("minGuideCirculars")["columns"]} */}

      <MMTable
        title={state?.title}
        toggle={() => {
          setCurModal(null);
        }}
        filter={state?.type === "2" ? "date" : "name"}
        isShowFilter={true}
        tableData={{
          columns:
            state?.type == "2"
              ? getTableData("minGuideCirculars1")["columns"]
              : getTableData("minGuideCirculars")["columns"],
          rows: minGuideCircularsData,
        }}
      />
      <Modal
        className="fade mm-form-modal"
        show={curModal === "add" || curModal === "addInBulk"}
      >
        <div className="modal-content">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handlerSubmitAddCourtOrderInfoItemInBulk(e.target);
            }}
          >
            <Modal.Header className="modal-header">
              <Modal.Title className="modal-title">
                Add Circular In Bulk
              </Modal.Title>
              <Button
                variant=""
                onClick={() => {
                  setCurModal(null);
                }}
                type="button"
                className="close"
              >
                <span>×</span>
              </Button>
            </Modal.Header>

            <Modal.Body>
              <Row className="mt-4">
                <Label>File</Label>

                <Input
                  type="file"
                  id="file"
                  accept="application/pdf"
                  required={curModal === "edit" ? false : true}
                  multiple={curModal === "addInBulk" ? true : false}
                />
              </Row>

              <Row className="mt-4">
                <Label>Filter</Label>
                <Autocomplete
                  id="filter"
                  freeSolo
                  className="w-100"
                  options={filterList}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Filters" />
                  )}
                  getOptionLabel={(option) => option?.filter ?? option}
                />
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" disabled={loader} outline>
                {loader && <Loader />}Save
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
};

export default MinGuideCircularPage;
