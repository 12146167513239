import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import Loader from "../Loader/Loader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

let pdfUrl = "https://mine-magma.s3.ap-south-1.amazonaws.com/1679484879127.pdf";

const CourtOrderInfoViewPage = () => {
  const { courtOrderInfoItemId } = useParams();

  const getCourtOrderInfoItemById = useHttp();

  const isLoading = () => {
    return getCourtOrderInfoItemById.isLoading;
  };

  const [courtOrderInfoItem, setCourtOrderInfoItem] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handlerCourtOrderInfoItemById = useCallback((responseData) => {
    setCourtOrderInfoItem(responseData?.data);
  }, []);

  const loadInfoItem = () => {
    const getCourtOrderInfoItemByIdURL = {
      type: "GET",
      endPoint: `/admin/courtordersinfo/${courtOrderInfoItemId}`,
    };

    getCourtOrderInfoItemById.sendRequest(
      getCourtOrderInfoItemByIdURL,
      handlerCourtOrderInfoItemById,
      {}
    );
  };

  useEffect(() => {
    loadInfoItem();
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <Document
        file={courtOrderInfoItem?.file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        className="pdf-document"
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => (
            <Page pageNumber={page} />
          ))}
      </Document>
    </Fragment>
  );
};

export default CourtOrderInfoViewPage;
