import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../../utils/constants";
import DeleteModal from "../../components/custom-modals/DeleteModal";
import FormModal from "../../components/custom-modals/FormModal";
import MMAddButton from "../../components/MMButtons/MMAddButton";
import MMTable from "../../components/table/MMTable";
import Loader from "../Loader/Loader";
import PageTitle from "../../layouts/PageTitle";
import { Services } from "../../../services/service";
import { Input, Label, Row, Form } from "reactstrap";
import { Autocomplete, TextField } from "@mui/material";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { notify } from "../../../utils/funcs";
import BulkLoader from "../Loader/BulkLoader";

const getFormInputList = {
  add: CONSTANTS.FORMS.addTechQuestion,
  // add1: CONSTANTS.FORMS.addMinGuideCircular1,
  edit: CONSTANTS.FORMS.editTechQuestion,
  // edit1: CONSTANTS.FORMS.editMinGuideCircular1,
};

const TechWindowInfo = () => {
  const params = useParams();
  const location = useLocation();
  // console.log(params?.tecWindowId);
  const getTechQuestions = useHttp();
  const addTechQuestion = useHttp();
  const editTechQuestion = useHttp();
  const deleteTechQuestion = useHttp();
  const getAllTechWindows = useHttp();

  const isLoading = () => {
    return (
      getTechQuestions.isLoading ||
      addTechQuestion.isLoading ||
      editTechQuestion.isLoading ||
      deleteTechQuestion.isLoading ||
      getAllTechWindows.isLoading
      // getTechQuestionType.isLoading
    );
  };

  const [TechQuestions, setTechQuestions] = useState([]);
  const [TechQuestionsData, setTechQuestionsData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curTechQuestions, setCurTechQuestion] = useState(null);
  const [state, setstate] = useState(location?.state);
  const [loader, setLoader] = useState(false);
  const [optionTech, setOptionTech] = useState([]);

  // const [bulkloader, setBulkLoader] = useState(null);
  // const [fileName, setFileName] = useState("");
  console.log(state);
  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const getFormModalTitle = {
    add: "Add Tech Questions",
    edit: "Edit Tech Questions",
    delete: "Delete Tech Questions",
  };
  // useEffect(() => {
  //   const alldata = magazines?.rows?.map((el) => el[filter]);
  //   setFilterResult([...new Set(alldata)]);
  // }, [magazines]);
  // console.log(optionTech);
  useEffect(() => {
    getAllTechWindows.sendRequest(
      CONSTANTS.URLS.getAllTechWindows,
      (res) => {
        // console.log(res);
        res?.data?.rows?.map((item) => {
          setOptionTech([
            ...optionTech,
            { label: item?.name, value: item?.id },
          ]);
        });
      },
      {}
    );
  }, []);

  const handlerClickOnEditIcon = (techQuestion) => {
    setCurTechQuestion(techQuestion);
    setCurModal("edit");
  };

  const TechQuestionsDataHandler = (techQuestions) => {
    const alldata = techQuestions?.map((el) => el?.date);

    setTechQuestionsData(
      techQuestions.map((techQuestion, index) => {
        // console.log(techQuestion?.date);
        return {
          // userData: userData,
          no: `${index + 1}`,
          question: techQuestion?.question,
          // date: techQuestion?.date,
          // index: techQuestion?.index,

          // file: (
          //   <div className="d-flex justify-content-center">
          //     <Link
          //       title="View"
          //       to={`/pdf-viewer?file=${techQuestion?.file}&fileName=${techQuestion?.name}`}
          //       // state={{
          //       //   title: techQuestion?.name,
          //       //   lastPath: state?.name,
          //       // }}
          //       className="btn btn-warning shadow btn-xs sharp mr-2"
          //     >
          //       <i className="fa fa-eye"></i>
          //     </Link>
          //   </div>
          // ),
          action: (
            <div className="d-flex justify-content-center">
              <Link
                to={`/tech-window/${params?.tecWindowId}/answer/${techQuestion?.id}`}
                state={{
                  title: techQuestion?.name,
                }}
                className="btn btn-warning shadow btn-xs sharp mr-2"
                title="View"
              >
                <i className="fa fa-eye"></i>
              </Link>
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                title="Edit"
                onClick={() => handlerClickOnEditIcon(techQuestion)}
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                title="Delete"
                onClick={() => handlerClickOnDeleteIcon(techQuestion)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
      })
    );
  };

  const handlerGetAllTechQuestions = useCallback((responseData) => {
    if (responseData.status === "success") {
      setTechQuestions(responseData?.data?.rows);
      TechQuestionsDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadTechQuestions = () => {
    const getTechQuestionURL = {
      type: CONSTANTS.URLS.getAllTechQuestion.type,
      endPoint: `/admin/techquestions?techWindowId=${params?.tecWindowId}`,
    };
    getTechQuestions.sendRequest(
      getTechQuestionURL,
      handlerGetAllTechQuestions,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (techQuestion) => {
    setCurTechQuestion(techQuestion);
    setCurModal("delete");
  };

  const handlerDeleteTechQuestionRes = useCallback((responseData) => {
    setCurTechQuestion(null);
    setCurModal(null);
    loadTechQuestions();
  }, []);

  const handlerDeleteTechQuestion = (techWindowId) => {
    const deleteTechQuestionURL = {
      type: "DELETE",
      endPoint: `/admin/techquestions/${techWindowId}`,
    };

    // make api call
    deleteTechQuestion.sendRequest(
      deleteTechQuestionURL,
      handlerDeleteTechQuestionRes,
      {},
      "TechQuestion deleted successfully."
    );
  };

  const handlerAddTechQuestionRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurTechQuestion(null);
      setCurModal(null);
      loadTechQuestions();
    }
  }, []);

  const handlerSubmitAddTechQuestion = (payload) => {
    // payload.append("ministryGuideId", params?.guidelineId);
    // payload.append("type", state?.type);
    // console.log(payload);
    // debugger;
    addTechQuestion.sendRequest(
      CONSTANTS.URLS.addTechQuestion,
      handlerAddTechQuestionRes,
      payload,
      "TechQuestion added successfully."
    );
  };

  const handlerEditTechQuestionRes = useCallback((responseData) => {
    setCurModal(null);
    setCurTechQuestion(null);
    loadTechQuestions();
  }, []);

  const handlerEditTechQuestion = (techWindowId, payload) => {
    const editTechQuestionURL = {
      type: "PATCH",
      endPoint: `/admin/techquestions/${techWindowId}`,
    };

    // payload.append("ministryGuideId", params?.guidelineId);

    // make api call
    editTechQuestion.sendRequest(
      editTechQuestionURL,
      handlerEditTechQuestionRes,
      payload,
      "TechQuestion updated successfully."
    );
  };

  const handlerSubmitForm = (e) => {
    // debugger;
    e.preventDefault();
    console.log(e.target);
    // const type = e.target.type.value;
    const question = e.target.question.value;

    const obj = {
      techWindowId: params?.tecWindowId,
      question: question,
    };
    // console.log(obj);

    if (curModal === "add") {
      handlerSubmitAddTechQuestion(obj);
    } else {
      handlerEditTechQuestion(curTechQuestions?.id, obj);
    }
  };

  useEffect(() => {
    loadTechQuestions();
  }, []);

  useEffect(() => {
    if (curModal !== "delete") {
      const selectElements = document.getElementById("type");

      if (selectElements) {
        selectElements.classList.add("form-control");
      }
    }
  }, [curModal]);
  console.log(curTechQuestions);
  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this Tech-Question?"}
        desc={curTechQuestions?.question}
        onDelete={() => handlerDeleteTechQuestion(curTechQuestions?.id)}
      />
      {/* 
      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                name: curTechQuestions?.name,
                file: curTechQuestions?.file,
                type: curTechQuestions?.type,
                index: curTechQuestions?.index,
              }
            : {}
        }
      /> */}

      <PageTitle
        pageHeading={`${state?.title}`}
        withBackButton
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />

      <MMTable
        title="SMP/OSH"
        toggle={() => {
          setCurModal(null);
        }}
        filter={"question"}
        isShowFilter={true}
        tableData={{
          columns: getTableData("techQuestions")["columns"],
          rows: TechQuestionsData,
        }}
      />

      <Modal
        show={curModal === "add" || curModal === "edit"}
        onExit={() => {
          // setmodalOpen(false);
          handlerCloseModal();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Form
          onSubmit={handlerSubmitForm}
          defaultValue={
            curModal === "edit"
              ? {
                  // type: curTechQuestions?.techWindowId,
                  question: curTechQuestions?.question,
                  // file: curTechQuestions?.file,
                  // index: curTechQuestions?.index,
                }
              : {}
          }
        >
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">
              {getFormModalTitle[curModal]}
            </Modal.Title>
            <Button
              variant=""
              onClick={() => {
                // setmodalOpen(false);
                handlerCloseModal();
              }}
              type="button"
              className="close"
            >
              <span>×</span>
            </Button>
          </Modal.Header>

          <Modal.Body>
            {/* <Row className="mt-4">
              <Label>Select Type</Label>
              <Input
                type="select"
                id="type"
                required
                defaultValue={
                  curModal === "edit" ? curTechQuestions?.techWindowId : ""
                }
              >
                {optionTech?.map((tech) => (
                  <option key={tech?.value} value={tech?.value}>
                    {tech?.label}
                  </option>
                ))}
              </Input>
            </Row> */}

            <Row className="mt-4">
              <Label>Question</Label>
              <Input
                required
                style={{ padding: "18px 10px", fontSize: "17px" }}
                placeholder="Enter Question"
                type="text"
                id="question"
                defaultValue={
                  curModal === "edit" ? curTechQuestions?.question : ""
                }
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={loader} outline>
              {loader && <Loader />}Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default TechWindowInfo;
