import { Link } from "react-router-dom";

const CountCard = (props) => {
  const { icon, title, count, amount, bgColor, link } = props;

  return (
    <div className="col-xl-3 col-lg-6 col-sm-6">
      <Link to={link}>
        <div className={`widget-stat card bg-${bgColor} h-auto`}>
          <div className="card-body  p-4">
            <div className="media">
              <span className="mr-1">
                <i className={icon}></i>
              </span>
              <div className="media-body text-white text-right">
                <p className="mb-1">{title}</p>
                {(count || count == 0) && (
                  <h3 className="text-white">{count}</h3>
                )}
                {(amount || amount == 0) && (
                  <h3 className="text-white">${amount}</h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CountCard;
