import { Row } from "react-bootstrap";
import ItemLoader from "../../pages/ItemLoader";
import NoItemsFound from "../../pages/NoItemsFound";
import Link from "./Link";

const Links = (props) => {
  const { links, isLoading, onClickOnDeleteIcon, onClickOnEditIcon } = props;

  return (
    <Row className="align-items-start">
      {!isLoading ? (
        <>
          {links?.length ? (
            <>
              {links.map((link, index) => {
                return (
                  <Link
                    key={link?.id}
                    index={index}
                    link={link}
                    onClickOnDeleteIcon={onClickOnDeleteIcon}
                    onClickOnEditIcon={onClickOnEditIcon}
                  />
                );
              })}
            </>
          ) : (
            <NoItemsFound message="No links found." />
          )}
        </>
      ) : (
        <>
          <ItemLoader />
        </>
      )}
    </Row>
  );
};

export default Links;
