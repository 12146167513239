import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import useHttp from "../../hooks/use-http";
import useInput from "../../hooks/use-input";
import { getTableData } from "../../utils/constants";
import { isNonEmpty } from "../../utils/funcs";
import MMTable from "../components/table/MMTable";
import Loader from "./Loader/Loader";
import PageTitle from "../layouts/PageTitle";

const SubscriptionsPage = () => {
  const getAllSubscriptions = useHttp();

  const isLoading = () => {
    return getAllSubscriptions.isLoading;
  };

  const inpStartDate = useInput(isNonEmpty);
  const inpEndDate = useInput(isNonEmpty);

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const subscriptionDataHandler = (subscriptions) => {
    setSubscriptionData(
      subscriptions.map((subscriptionData, index) => {
        return {
          // subscriptionData: subscriptionData,
          no: `${index + 1}`,
          planname: `${subscriptionData?.plan?.name}`,

          username: `${subscriptionData?.user?.name}`,

          payId: `${subscriptionData?.payId}`,

          createdAt: moment(subscriptionData?.createdAt)
            .local()
            .format("MMM DD, yyyy"),

          expireDate: moment(subscriptionData?.expireDate)
            .local()
            .format("MMM DD, yyyy"),
        };
      })
    );
  };

  const handlerGetAllSubscriptions = useCallback((responseData) => {
    if (responseData.status === "success") {
      setSubscriptions(responseData?.data?.subscriptions);
      subscriptionDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadSubscriptions = (params = null) => {
    const getAllSubscriptionsURL = {
      type: "GET",
      endPoint: "/admin/subscriptions",
    };

    if (params) {
      // Convert the params object into a query string
      const queryParams = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&");

      getAllSubscriptionsURL.endPoint = `${getAllSubscriptionsURL.endPoint}?${queryParams}`;
    }

    getAllSubscriptions.sendRequest(
      getAllSubscriptionsURL,
      handlerGetAllSubscriptions,
      {}
    );
  };

  const handlerFormReset = (event) => {
    inpStartDate.reset();
    inpEndDate.reset();
    loadSubscriptions();
  };

  const handlerFormSubmission = (event) => {
    event.preventDefault();

    if (!inpStartDate.isValid || !inpEndDate.isValid) {
      return;
    }

    const params = {
      startDate: inpStartDate.value,
      endDate: inpEndDate.value,
    };

    loadSubscriptions(params);
  };

  useEffect(() => {
    loadSubscriptions();
  }, []);
  return (
    <Fragment>
      {isLoading() && <Loader />}

      <PageTitle pageHeading="Subscriptions" />

      <div className="card p-4">
        <form onSubmit={handlerFormSubmission}>
          <div className="row">
            <div className="form-group col-12 col-lg-6">
              <label className="mb-2 ">
                <strong>Start Date</strong>
              </label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Start Date"
                autoComplete="off"
                onChange={inpStartDate.valueChangeHandler}
                onBlur={inpStartDate.inputBlurHandler}
                value={inpStartDate.value}
              />
              {inpStartDate.hasError && (
                <p className="text-danger fs-6">Required</p>
              )}
            </div>
            <div className="form-group col-12 col-lg-6">
              <label className="mb-2 ">
                <strong>End Date</strong>
              </label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter End Date"
                autoComplete="off"
                onChange={inpEndDate.valueChangeHandler}
                onBlur={inpEndDate.inputBlurHandler}
                value={inpEndDate.value}
              />
              {inpEndDate.hasError && (
                <p className="text-danger fs-6">Required</p>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <button
              type="submit"
              className="btn btn-xs btn-primary mr-2"
              onClick={handlerFormSubmission}
              title="Submit"
            >
              Submit
            </button>
            <button
              type="reset"
              className="btn btn-xs btn-primary "
              onClick={handlerFormReset}
              title="Reset"
            >
              Reset
            </button>
          </div>
        </form>
      </div>

      <MMTable
        title="Subscriptions"
        tableData={{
          columns: getTableData("subscriptions")["columns"],
          rows: subscriptionData,
        }}
      />
    </Fragment>
  );
};

export default SubscriptionsPage;
