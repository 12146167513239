import { Button } from "react-bootstrap";

const MMAddButton = (props) => {
  const { clickHandler, children } = props;

  return (
    <div className="d-flex justify-content-space-between">
      <Button
        variant={`primary btn-rounded btn-xs d-flex align-items-center p-1 pr-3`}
        style={{ gap: "1rem" }}
        onClick={() => clickHandler("add")}
        title="Add"
      >
        <span className="btn-icon-left btn-xs text-primary m-auto ">
          <i className="fa fa-plus color-primary" />
        </span>

        {children || "Add"}
      </Button>
    </div>
  );
};

export default MMAddButton;
