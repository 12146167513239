import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../utils/constants";
import DeleteModal from "../components/custom-modals/DeleteModal";
import FormModal from "../components/custom-modals/FormModal";
import MMAddButton from "../components/MMButtons/MMAddButton";
import Links from "./../components/links/Links";
import Loader from "./Loader/Loader";
import PageTitle from "../layouts/PageTitle";

const getFormModalTitle = {
  add: "Add Link",
  edit: "Edit Link",
  delete: "Delete Link",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addLink,
  edit: CONSTANTS.FORMS.editLink,
};

const LinksPage = () => {
  const getAllLinks = useHttp();
  const addLink = useHttp();
  const deleteLink = useHttp();
  const editLink = useHttp();

  const isLoading = () => {
    return (
      getAllLinks.isLoading ||
      addLink.isLoading ||
      editLink.isLoading ||
      deleteLink.isLoading
    );
  };

  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'
  const [curLink, setCurLink] = useState(null);

  const [links, setLinks] = useState([]);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerGetAllLinks = useCallback((responseData) => {
    if (responseData.status === "success") {
      setLinks(responseData?.data?.rows);
    }
  }, []);

  const handlerAddLinkRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurLink(null);
      setCurModal(null);
      loadLinks();
    }
  }, []);

  const handlerClickOnDeleteIcon = (link) => {
    setCurLink(link);
    setCurModal("delete");
  };

  const handlerClickOnEditIcon = (link) => {
    setCurLink(link);
    setCurModal("edit");
  };

  const handlerEditLinkRes = useCallback((responseData) => {
    setCurModal(null);
    setCurLink(null);
    loadLinks();
  }, []);

  const handlerDeleteLinkRes = useCallback((responseData) => {
    setCurLink(null);
    setCurModal(null);
    loadLinks();
  }, []);

  const handlerDeleteLink = (linkId) => {
    const deleteLinkURL = {
      type: "DELETE",
      endPoint: `/admin/links/${linkId}`,
    };

    // make api call
    deleteLink.sendRequest(
      deleteLinkURL,
      handlerDeleteLinkRes,
      {},
      "Link deleted successfully."
    );
  };

  const handlerEditLink = (linkId, payload) => {
    const editLinkURL = {
      type: "PATCH",
      endPoint: `/admin/links/${linkId}`,
    };

    // make api call
    editLink.sendRequest(
      editLinkURL,
      handlerEditLinkRes,
      payload,
      "Link updated successfully."
    );
  };

  const handlerSubmitAddLink = (payload) => {
    addLink.sendRequest(
      CONSTANTS.URLS.addLink,
      handlerAddLinkRes,
      payload,
      "Link added successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    if (curModal === "add") {
      handlerSubmitAddLink(payload);
    } else {
      handlerEditLink(curLink?.id, payload);
    }
  };

  const loadLinks = () => {
    getAllLinks.sendRequest(CONSTANTS.URLS.getAllLinks, handlerGetAllLinks, {});
  };

  useEffect(() => {
    loadLinks();
  }, []);

  return (
    <>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this link?"}
        desc={<u>{curLink?.link}</u>}
        onDelete={() => handlerDeleteLink(curLink?.id)}
      />
      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                title: curLink?.title,
                link: curLink?.link,
                photo: curLink?.photo,
              }
            : {}
        }
      />
      <PageTitle
        pageHeading="Links"
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />
      <Links
        links={links}
        isLoading={getAllLinks?.isLoading}
        onClickOnDeleteIcon={handlerClickOnDeleteIcon}
        onClickOnEditIcon={handlerClickOnEditIcon}
      />
    </>
  );
};

export default LinksPage;
