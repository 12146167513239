import { ProgressBar } from "react-bootstrap";

export default function Loader() {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>
      </div>
    </div>
  );
}
