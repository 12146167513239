import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import img from "../../images/noimg.jpg";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../utils/constants";
import DeleteModal from "../components/custom-modals/DeleteModal";
import FormModal from "../components/custom-modals/FormModal";
import MMAddButton from "../components/MMButtons/MMAddButton";
import MMTable from "../components/table/MMTable";
import Loader from "./Loader/Loader";
import PageTitle from "../layouts/PageTitle";
import classes from "../../../src/css/topp.module.css";
import ImageWithPopup from "../layouts/imagepopup";

const getFormModalTitle = {
  add: "Add Promoter",
  edit: "Edit Promoter",
  delete: "Delete Promoter",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addPromoter,
  edit: CONSTANTS.FORMS.editPromoter,
};

const PromotersPage = () => {
  const getAllPromoters = useHttp();
  const addPromoter = useHttp();
  const editPromoter = useHttp();
  const deletePromoters = useHttp();

  const isLoading = () => {
    return (
      getAllPromoters.isLoading ||
      addPromoter.isLoading ||
      editPromoter.isLoading ||
      deletePromoters.isLoading
    );
  };

  const [promoters, setPromoters] = useState([]);
  const [promotersData, setPromotersData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curPromoter, setCurPromoter] = useState(null);
  const [onmodal, setOnmodal] = useState(null);
  const [keyValue, setKeyValue] = useState(null);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerDeleteimage = (promoterId) => {
    const payload = new FormData();
    payload.append(`${keyValue[onmodal]}`, null);
    const deletePromoterURL = {
      type: "PATCH",
      endPoint: `/admin/promoters/${promoterId}`,
    };

    // make api call
    editPromoter.sendRequest(
      deletePromoterURL,
      handlerDeletePromotersRes,
      payload,
      "Promoter deleted successfully."
    );
  };

  const handleChange = (e, prom, index, key) => {
    (async () => {
      const payload = new FormData();

      if (e.target?.files[0]) {
        payload.append(`${key[index]}`, e.target.files[0]);

        const editPromoterURL = {
          type: "PATCH",
          endPoint: `/admin/promoters/${prom?.id}`,
        };

        // make api call
        editPromoter.sendRequest(
          editPromoterURL,
          handlerEditPromoterRes,
          payload,
          "Promoters updated successfully."
        );
      }

      if (e.target?.files[0] === null) {
        payload.append(`image${index}`, img);

        const editPromoterURL = {
          type: "PATCH",
          endPoint: `/admin/promoters/${prom?.id}`,
        };

        // make api call
        editPromoter.sendRequest(
          editPromoterURL,
          handlerEditPromoterRes,
          payload,
          "Promoters updated successfully."
        );
      }
    })();
  };

  const handlerClickOnEditIcon = (promoter) => {
    setCurPromoter(promoter);
    setCurModal("edit");
  };

  const promotersDataHandler = (promoters) => {
    setPromotersData(
      promoters.map((promoter, index) => {
        // console.log(
        //   promoter.logo,
        //   promoter.logo != "null" ? promoter.logo : img
        // );
        return {
          no: `${index + 1}`,

          logo: (
            <ImageWithPopup
              imgpath={
                promoter.logo !== "null" && promoter.logo !== null
                  ? promoter.logo
                  : img
              }
              data={promoter}
              btn={(e) => {
                handleChange(e, promoter, 18, Object.keys(promoter));
              }}
              deletbtn={() => {
                handlerClickOnDeleteIconImg(
                  promoter,
                  18,
                  Object.keys(promoter)
                );
              }}
            />
          ),
          name: promoter?.name,
          tagline: promoter?.tagLine,

          coverpage: (
            <ImageWithPopup
              imgpath={
                promoter.cover !== "null" && promoter.cover !== null
                  ? promoter.cover
                  : img
              }
              data={promoter}
              btn={(e) => {
                handleChange(e, promoter, 18, Object.keys(promoter));
              }}
              deletbtn={() => {
                handlerClickOnDeleteIconImg(
                  promoter,
                  18,
                  Object.keys(promoter)
                );
              }}
            />
          ),
          service1: promoter?.service1,
          service2: promoter?.service2,
          service3: promoter?.service3,
          service4: promoter?.service4,
          service5: promoter?.service5,

          intro1: promoter?.intro1,
          intro2: promoter?.intro2,
          intro3: promoter?.intro3,
          introParagraphImage: (
            <ImageWithPopup
              imgpath={
                promoter.introImg !== "null" && promoter.introImg !== null
                  ? promoter.introImg
                  : img
              }
              data={promoter}
              btn={(e) => {
                handleChange(e, promoter, 18, Object.keys(promoter));
              }}
              deletbtn={() => {
                handlerClickOnDeleteIconImg(
                  promoter,
                  18,
                  Object.keys(promoter)
                );
              }}
            />
          ),
          quote1: promoter?.quote1,
          quote2: promoter?.quote2,
          quote3: promoter?.quote3,
          quoteMessage: promoter?.quoteDesc,
          image1: (
            <ImageWithPopup
              imgpath={
                promoter.image1 !== "null" && promoter.image1 !== null
                  ? promoter.image1
                  : img
              }
              data={promoter}
              btn={(e) => {
                handleChange(e, promoter, 16, Object.keys(promoter));
              }}
              deletbtn={() => {
                handlerClickOnDeleteIconImg(
                  promoter,
                  16,
                  Object.keys(promoter)
                );
              }}
            />
          ),
          image2: (
            <ImageWithPopup
              imgpath={
                promoter.image2 !== "null" && promoter.image2 !== null
                  ? promoter.image2
                  : img
              }
              data={promoter}
              btn={(e) => {
                handleChange(e, promoter, 17, Object.keys(promoter));
              }}
              deletbtn={() => {
                handlerClickOnDeleteIconImg(
                  promoter,
                  17,
                  Object.keys(promoter)
                );
              }}
            />
          ),
          image3: (
            <ImageWithPopup
              imgpath={
                promoter.image3 !== "null" && promoter.image3 !== null
                  ? promoter.image3
                  : img
              }
              data={promoter}
              btn={(e) => {
                handleChange(e, promoter, 18, Object.keys(promoter));
              }}
              deletbtn={() => {
                handlerClickOnDeleteIconImg(
                  promoter,
                  18,
                  Object.keys(promoter)
                );
              }}
            />
          ),
          image4: (
            <ImageWithPopup
              imgpath={
                promoter.image4 !== "null" && promoter.image4 !== null
                  ? promoter.image4
                  : img
              }
              data={promoter}
              btn={(e) => {
                handleChange(e, promoter, 19, Object.keys(promoter));
              }}
              deletbtn={() => {
                handlerClickOnDeleteIconImg(
                  promoter,
                  19,
                  Object.keys(promoter)
                );
              }}
            />
          ),
          image5: (
            <ImageWithPopup
              imgpath={
                promoter.image5 !== "null" && promoter.image5 !== null
                  ? promoter.image5
                  : img
              }
              data={promoter}
              btn={(e) => {
                handleChange(e, promoter, 20, Object.keys(promoter));
              }}
              deletbtn={() => {
                handlerClickOnDeleteIconImg(
                  promoter,
                  20,
                  Object.keys(promoter)
                );
              }}
            />
          ),
          ranking: promoter?.index,

          action: (
            <div className="d-flex justify-content-center">
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnEditIcon(promoter)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnDeleteIcon(promoter)}
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
      })
    );
  };

  const handlerGetAllpromoters = useCallback((responseData) => {
    if (responseData.status === "success") {
      setPromoters(responseData?.data?.rows);
      promotersDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadPromoters = () => {
    getAllPromoters.sendRequest(
      CONSTANTS.URLS.getAllPromoters,
      handlerGetAllpromoters,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (promoter) => {
    setCurPromoter(promoter);
    setCurModal("delete");
  };
  const handlerClickOnDeleteIconImg = (promoter, index, key) => {
    setCurPromoter(promoter);
    setCurModal("deleteimg");
    setOnmodal(index);
    setKeyValue(key);
  };

  const handlerDeletePromotersRes = useCallback((responseData) => {
    setCurPromoter(null);
    setCurModal(null);

    loadPromoters();
  }, []);

  const handlerDeletePromoter = (promoterId) => {
    const deletePromoterURL = {
      type: "DELETE",
      endPoint: `/admin/promoters/${promoterId}`,
    };

    // make api call
    deletePromoters.sendRequest(
      deletePromoterURL,
      handlerDeletePromotersRes,
      {},
      "Promoter deleted successfully."
    );
  };

  const handlerAddPromoterRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurPromoter(null);

      loadPromoters();
    }
  }, []);

  const handlerSubmitAddPromoter = (payload) => {
    setCurModal(null);
    addPromoter.sendRequest(
      CONSTANTS.URLS.addPromoter,
      handlerAddPromoterRes,
      payload,
      "Promoters added successfully."
    );
  };

  const handlerEditPromoterRes = useCallback((responseData) => {
    setCurModal(null);
    setCurPromoter(null);
    loadPromoters();
  }, []);

  const handlerEditPromoter = (promoterId, payload) => {
    const editPromoterURL = {
      type: "PATCH",
      endPoint: `/admin/promoters/${promoterId}`,
    };

    // make api call
    editPromoter.sendRequest(
      editPromoterURL,
      handlerEditPromoterRes,
      payload,
      "Promoters updated successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    if (curModal === "add") {
      handlerSubmitAddPromoter(payload);
    } else {
      handlerEditPromoter(curPromoter?.id, payload);
    }
  };

  useEffect(() => {
    loadPromoters();
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this promoter?"}
        desc={curPromoter?.name}
        onDelete={() => handlerDeletePromoter(curPromoter?.id)}
      />
      <DeleteModal
        isOpen={curModal === "deleteimg"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this promoter?"}
        desc={curPromoter?.name}
        onDelete={() => {
          handlerDeleteimage(curPromoter?.id);
        }}
      />
      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                logo: curPromoter?.logo,
                name: curPromoter?.name,
                tagLine: curPromoter?.tagLine,
                cover: curPromoter?.cover,
                service1: curPromoter?.service1,
                service2: curPromoter?.service2,
                service3: curPromoter?.service3,
                service4: curPromoter?.service4,
                service5: curPromoter?.service5,
                intro1: curPromoter?.intro1,
                intro2: curPromoter?.intro2,
                intro3: curPromoter?.intro3,
                introImg: curPromoter?.introImg,
                quote1: curPromoter?.quote1,
                quote2: curPromoter?.quote2,
                quote3: curPromoter?.quote3,
                quoteDesc: curPromoter?.quoteDesc,
                image1: curPromoter?.image1,
                image2: curPromoter?.image2,
                image3: curPromoter?.image3,
                image4: curPromoter?.image4,
                image5: curPromoter?.image5,
                index: curPromoter?.index,
              }
            : {}
        }
      />

      <PageTitle
        pageHeading="Promoters"
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />
      <MMTable
        title="Promoters"
        noItemMsg="No promoters found."
        tableData={{
          columns: getTableData("promoters")["columns"],
          rows: promotersData,
        }}
        isShowFilter={true}
        filter="name"
      />
    </Fragment>
  );
};

export default PromotersPage;
