import axios from "axios";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { getTableData } from "../../../utils/constants";
import MMAddButton from "../../components/MMButtons/MMAddButton";
import DeleteModal from "../../components/custom-modals/DeleteModal";
import FormModal from "../../components/custom-modals/FormModal";
import MMTable from "../../components/table/MMTable";
import PageTitle from "../../layouts/PageTitle";
import Loader from "../Loader/Loader";

const getFormModalTitle = {
  add: "Add Order",
  edit: "Edit Order",
  delete: "Delete Order",
};

const getFormInputList = {
  add: CONSTANTS.FORMS.addCourtOrder,
  edit: CONSTANTS.FORMS.editCourtOrder,
};

const CourtOrdersJudgmentsPage = () => {
  const getAllCourtOrders = useHttp();
  const addCourtOrder = useHttp();
  const editCourtOrder = useHttp();
  const deleteCourtOrders = useHttp();

  const isLoading = () => {
    return (
      getAllCourtOrders.isLoading ||
      addCourtOrder.isLoading ||
      editCourtOrder.isLoading ||
      deleteCourtOrders.isLoading
    );
  };

  const [courtOrders, setCourtOrders] = useState([]);
  const [courtOrdersData, setCourtOrdersData] = useState([]);
  const [curModal, setCurModal] = useState(null); //can_be: 'add', 'edit', 'delete'  const [curModal, setCurModal] = useState(null); //can_be: 'edit', 'delete'
  const [curCourtOrder, setCurCourtOrder] = useState(null);

  const handlerCloseModal = () => {
    setCurModal(null);
  };

  const handlerClickOnEditIcon = (courtOrder) => {
    setCurCourtOrder(courtOrder);
    setCurModal("edit");
  };

  const courtOrdersDataHandler = (courtOrders) => {
    setCourtOrdersData(
      courtOrders.map((courtOrder, index) => {
        return {
          // userData: userData,
          no: `${index + 1}`,
          title: courtOrder?.title,
          desc: courtOrder?.desc || "-",
          action: (
            <div className="d-flex justify-content-center">
              <Link
                to={`/court-orders-and-judgments/${courtOrder?.id}`}
                state={{ title: courtOrder?.title }}
                className="btn btn-warning shadow btn-xs sharp mr-2"
                title="View"
              >
                <i className="fa fa-eye"></i>
              </Link>
              <Link
                className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnEditIcon(courtOrder)}
                title="Edit"
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                className="btn btn-danger shadow btn-xs sharp mr-2"
                onClick={() => handlerClickOnDeleteIcon(courtOrder)}
                title="Delete"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        };
      })
    );
  };

  const handlerGetAllcourtOrders = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCourtOrders(responseData?.data?.rows);
      courtOrdersDataHandler(responseData?.data?.rows);
    }
  }, []);

  const loadCourtOrders = () => {
    getAllCourtOrders.sendRequest(
      CONSTANTS.URLS.getAllCourtOrders,
      handlerGetAllcourtOrders,
      {}
    );
  };

  const handlerClickOnDeleteIcon = (courtOrder) => {
    setCurCourtOrder(courtOrder);
    setCurModal("delete");
  };

  const handlerDeleteCourtOrdersRes = useCallback((responseData) => {
    setCurCourtOrder(null);
    setCurModal(null);
    loadCourtOrders();
  }, []);

  const handlerDeleteCourtOrder = (courtOrderId) => {
    const deleteCourtOrderURL = {
      type: "DELETE",
      endPoint: `/admin/courtorders/${courtOrderId}`,
    };

    // make api call
    deleteCourtOrders.sendRequest(
      deleteCourtOrderURL,
      handlerDeleteCourtOrdersRes,
      {},
      "Court Order deleted successfully."
    );
  };

  const handlerAddCourtOrderRes = useCallback((responseData) => {
    if (responseData.status === "success") {
      setCurCourtOrder(null);
      setCurModal(null);
      loadCourtOrders();
    }
  }, []);

  const handlerSubmitAddCourtOrder = (payload) => {
    addCourtOrder.sendRequest(
      CONSTANTS.URLS.addCourtOrder,
      handlerAddCourtOrderRes,
      payload,
      "Court order added successfully."
    );
  };

  const handlerEditCourtOrderRes = useCallback((responseData) => {
    setCurModal(null);
    setCurCourtOrder(null);
    loadCourtOrders();
  }, []);

  const handlerEditCourtOrder = (courtOrderId, payload) => {
    const editCourtOrderURL = {
      type: "PATCH",
      endPoint: `/admin/courtorders/${courtOrderId}`,
    };

    // make api call
    editCourtOrder.sendRequest(
      editCourtOrderURL,
      handlerEditCourtOrderRes,
      payload,
      "Court order updated successfully."
    );
  };

  const handlerSubmitForm = (payload) => {
    if (curModal === "add") {
      handlerSubmitAddCourtOrder(payload);
    } else {
      handlerEditCourtOrder(curCourtOrder?.id, payload);
    }
  };

  useEffect(() => {
    loadCourtOrders();
  }, []);

  return (
    <Fragment>
      {isLoading() && <Loader />}
      <DeleteModal
        isOpen={curModal === "delete"}
        onClose={handlerCloseModal}
        title={"Are you sure to delete this court order?"}
        desc={curCourtOrder?.title}
        onDelete={() => handlerDeleteCourtOrder(curCourtOrder?.id)}
      />
      <FormModal
        isOpen={curModal === "add" || curModal === "edit"}
        onClose={handlerCloseModal}
        title={getFormModalTitle[curModal]}
        formInputList={getFormInputList[curModal]}
        onSubmit={handlerSubmitForm}
        defaultValue={
          curModal === "edit"
            ? {
                title: curCourtOrder?.title,
                desc: curCourtOrder?.desc,
              }
            : {}
        }
      />

      <PageTitle
        pageHeading="Court Orders & Judgments"
        buttons={[
          <MMAddButton key="add" clickHandler={() => setCurModal("add")} />,
        ]}
      />

      <MMTable
        title="Court Orders & Judgments"
        noItemMsg="No orders found."
        tableData={{
          columns: getTableData("courtOrders")["columns"],
          rows: courtOrdersData,
        }}
        isShowFilter={true}
        filter="title"
      />
    </Fragment>
  );
};

export default CourtOrdersJudgmentsPage;
